import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit"
import axios from "axios"
import { getLocales } from "expo-localization"
import { ErrorResponseData } from "../../@types/axios/error"
import { Address, CreateMedicalProfileData, MedicalProfile, Physician } from "../../@types/redux/medicalProfile"
import { showToast } from "../../toast"
import { filterObject, removeFalseyValues } from "../../util"
import { backendAPIClient } from "../axios/axios"
import { AppState } from "./store"
import { checkForInternetAccess, createAddressData, createPhysicianData, createProfileData, defaultInitialErrorState, sendErrorsToState } from "./util"


const blankAddress = {
	address_line1: "",
	address_line2: "",
	postal_code: "",
	state_province: "",
	city: "",
	country: ""
}

const getNewOfficeAddress = () => JSON.parse(JSON.stringify(blankAddress))

const blankPhysician = {
	first_name: "",
	last_name: "",
	specialty: "",
	phone_number: "",
	office_address: getNewOfficeAddress()
}

const getNewPhysician = () => JSON.parse(JSON.stringify(blankPhysician))


const internalInitialState = {
	isLoading: false,
	errors: defaultInitialErrorState,
	profile: {
		id: 0,
		first_name: "",
		last_name: "",
		user: 0,
		gender: "male",
		date_of_birth: new Date(),
		ethnicity: "hispanic",
		race: "american_indian_or_alaska_native",
		employment_status: "homemaker",
		occupation_title: "",
		heard_about: "",
		medical_release_signature: "",
		phone_number: "",
		physicians: [
			getNewPhysician()
		]
	}	
}

export const medicalProfileSlice = createSlice({
	name: "medicalProfileSlice",
	initialState: internalInitialState,
	reducers: {
		resetMedicalProfileState: (state, action) => {
			return internalInitialState
		},
		resetErrors: (state, action: PayloadAction<undefined>) => {
			state.errors = defaultInitialErrorState
		},
		setMedicalProfile: (state, action: PayloadAction<{ medical_profile: MedicalProfile }>) => {
			const { medical_profile } = action.payload
			state.profile = medical_profile
		},
		setLoadingState: (state, action: PayloadAction<boolean>) => {
			state.isLoading = action.payload
		},
		addPhysician: (state, action: PayloadAction<undefined>) => {
			state.profile.physicians.push(getNewPhysician())
		},
		removePhysicianAtIndex: (state, action: PayloadAction<number>) => {
			state.profile.physicians = state.profile.physicians.filter((_, i) => i != action.payload)
		},
		setFirstName: (state, action: PayloadAction<string>) => {
			state.profile.first_name = action.payload
		},
		setLastName: (state, action: PayloadAction<string>) => {
			state.profile.last_name = action.payload
		},
		setGender: (state, action: PayloadAction<GenderOptions>) => {
			state.profile.gender = action.payload
		},
		setDateOfBirth: (state, action: PayloadAction<Date>) => {
			state.profile.date_of_birth = action.payload instanceof Date ? action.payload : new Date(action.payload)
		},
		setEthnicity: (state, action: PayloadAction<EthnicityOptions>) => {
			state.profile.ethnicity = action.payload
		},
		setRace: (state, action: PayloadAction<RaceOptions>) => {
			state.profile.race = action.payload
		},
		setEmploymentStatus: (state, action: PayloadAction<EmploymentStatusOptions>) => {
			state.profile.employment_status = action.payload
		},
		setOccupationTitle: (state, action: PayloadAction<string>) => {
			state.profile.occupation_title = action.payload
		},
		setHeardAbout: (state, action: PayloadAction<string>) => {
			state.profile.heard_about = action.payload
		},
		setMedicalReleaseSignature: (state, action: PayloadAction<string>) => {
			state.profile.medical_release_signature = action.payload
		},
		setPhoneNumber: (state, action: PayloadAction<string>) => {
			state.profile.phone_number = action.payload
		},
		setPhysicianFirstName: (state, action: PayloadAction<{index: number, name: string}>) => {
			const { index, name } = action.payload
			state.profile.physicians[index].first_name = name
		},
		setPhysicianLastName: (state, action: PayloadAction<{index: number, name: string}>) => {
			const { index, name } = action.payload
			state.profile.physicians[index].last_name = name
		},
		setPhysicianSpecialty: (state, action: PayloadAction<{index: number, specialty: string}>) => {
			const { index, specialty } = action.payload
			state.profile.physicians[index].specialty = specialty
		},
		setPhysicianFaxNumber: (state, action: PayloadAction<{index: number, fax: string}>) => {
			const { index, fax } = action.payload
			state.profile.physicians[index].fax_number = fax
		},
		setPhysicianPhoneNumber: (state, action: PayloadAction<{index: number, phone: string}>) => {
			const { index, phone } = action.payload
			state.profile.physicians[index].phone_number = phone
		},
		setPhysicianOfficeAddressLine1: (state, action: PayloadAction<{index: number, address: string}>) => {
			const { index, address } = action.payload
			state.profile.physicians[index].office_address.address_line1 = address
		},
		setPhysicianOfficeAddressLine2: (state, action: PayloadAction<{index: number, address: string}>) => {
			const { index, address } = action.payload
			state.profile.physicians[index].office_address.address_line2 = address
		},
		setPhysicianOfficeAddressPostalCode: (state, action: PayloadAction<{index: number, code: string}>) => {
			const { index, code } = action.payload
			state.profile.physicians[index].office_address.postal_code = code
		},
		setPhysicianOfficeCity: (state, action: PayloadAction<{index: number, city: string}>) => {
			const { index, city } = action.payload
			state.profile.physicians[index].office_address.city = city
		},
		setPhysicianOfficeStateProvince: (state, action: PayloadAction<{index: number, state_province: string}>) => {
			const { index, state_province } = action.payload
			state.profile.physicians[index].office_address.state_province = state_province
		},
		setPhysicianOfficeCountry: (state, action: PayloadAction<{index: number, country: string}>) => {
			const { index, country } = action.payload
			state.profile.physicians[index].office_address.country = country
		},
	},
	extraReducers: (builder) => {
		builder.addCase(getMedicalProfile.pending, (state, action) => {
			state.isLoading = true
		}) 
		builder.addCase(getMedicalProfile.fulfilled, (state, action) => {
			state.isLoading = false
			state.profile = action.payload
		})
		builder.addCase(getMedicalProfile.rejected, (state, action) => {
			state.isLoading = false
			sendErrorsToState(state, action)
		})
		builder.addCase(createMedicalProfile.pending, (state, action) => {
			state.isLoading = true
			// const pendingMessage = t("createMedicalProfilePending", {ns: ["translations"]})
			const pendingMessage = "Creating medical Profile..."
			showToast({ message: pendingMessage, kind: "information" })
		}),
		builder.addCase(createMedicalProfile.fulfilled, (state, action) => {
			state.isLoading = false
			if (action.payload.id) {
				state.profile.id = action.payload.id
			}
			action.payload.physicians.forEach((physician, i) => {
				state.profile.physicians[i].id = physician.id
				state.profile.physicians[i].office_address.id = physician.office_address.id
			})
			// const successMessage = t("createMedicalProfileFulfilled", {ns: ["translations"]})
			const successMessage = "Successfully created medical profile"
			showToast({ message: successMessage, kind: "success" })
		}),
		builder.addCase(createMedicalProfile.rejected, (state, action) => {
			state.isLoading = false
			console.log("error creating medical profile: ", action.error)
			// const failureMessage = t("createMedicalProfileRejected", {ns: ["translations"]})
			const failureMessage = "Failed to create medical profile. Please try again"
			showToast({ message: failureMessage, kind: "failure" })
			sendErrorsToState(state, action)
		})
		builder.addCase(updateMedicalProfile.pending, (state, action) => {
			state.isLoading = true
		}),
		builder.addCase(updateMedicalProfile.fulfilled, (state, action) => {
			state.isLoading = false
			// state.profile = action.payload
			const successMessage = "Medical profile updated successfully"
			showToast({ message: successMessage, kind: "success" })
		}),
		builder.addCase(updateMedicalProfile.rejected, (state, action) => {
			state.isLoading = false
			console.log("error updating medical profile: ", action.error)
			const failureMessage = "Failed to update medical profile. Please try again"
			showToast({ message: failureMessage, kind: "failure" })
			sendErrorsToState(state, action)
		})
	},
})

export const getMedicalProfile = createAsyncThunk<
MedicalProfile,
undefined,
{
	state: AppState,
	rejectValue: ErrorResponseData
}
>(
	"getMedicalProfile",
	async (_, { dispatch, rejectWithValue }) => {
		await checkForInternetAccess()
		dispatch(resetErrors())

		try {
			const url = "api/v1/medical_profiles"
			const response = await backendAPIClient.get(url)
			console.log("get medical profile: ", response)
			return response.data[0]  
		} catch (error) {
			if (axios.isAxiosError(error)) {
				throw rejectWithValue(error.response?.data)
			} else {
				throw error
			}
		}
	}
)

export const updateMedicalProfile = createAsyncThunk<
MedicalProfile,
undefined,
{
  state: AppState,
  rejectValue: ErrorResponseData,
}
>(
	"updateMedicalProfile",
	async (_, {dispatch, getState, rejectWithValue}) => {
		await checkForInternetAccess()
		dispatch(resetErrors())

		const locales = getLocales()

		const preferredLanguageCode = locales[0].languageCode
		const medProfile = getState().medicalProfileState.profile

		const updateProfile = async (profile: MedicalProfile) => {
			const formattedData = createProfileData(profile, preferredLanguageCode)
			const finalData = filterObject(formattedData, removeFalseyValues)
			console.log("final data: ", finalData)
			const url = `api/v1/medical_profiles/${profile.id}/`
			// backendAPIClient.defaults.headers.put["Content-Type"] = "application/json"
			// return await backendAPIClient.put(url, finalData, {
			// 	headers: {"Content-Type": "application/json"}
			// })
			return await backendAPIClient({
				method: "put",
				url: url,
				data: finalData,
				headers: {
					"Content-Type": "application/json"
				}
			})
			// return await axios({
			// 	method: "put",
			// 	url: `http://localhost:8000/api/v1/medical_profiles/${profile.id}`,
			// 	data: finalData,
			// 	withCredentials: true
			// })
		}

		try {

			const response = await updateProfile(medProfile)
			return response.data
		} catch (error) {
			console.log("err: ", error)
			if (axios.isAxiosError(error)) {
				return rejectWithValue(error.response?.data)
			}
		}
	}
)


export const createMedicalProfile = createAsyncThunk<
MedicalProfile,
number,
{
	state: AppState,
	rejectValue: ErrorResponseData | undefined
}
>(
	"createMedicalProfile",
	async (userId, { getState, dispatch, rejectWithValue }) => {

		await checkForInternetAccess()
		dispatch(resetErrors())

		const locales = getLocales()
		const preferredLanguageCode = locales[0].languageCode

		const medProfile = getState().medicalProfileState.profile

		const medProfileData = createProfileData(medProfile, preferredLanguageCode, userId)

		const finalDataForSubmission = filterObject(medProfileData, removeFalseyValues)

		try {

			const response = await backendAPIClient.post("api/v1/medical_profiles/", finalDataForSubmission)

			return response.data
		} catch (error) {
			if (axios.isAxiosError(error)) {
				throw rejectWithValue(error.response?.data)
			}
			else {
				throw error
			}
		}
	}
)


export const {
	resetMedicalProfileState,
	resetErrors,
	setLoadingState,
	setMedicalProfile,
	addPhysician,
	removePhysicianAtIndex,
	setFirstName,
	setLastName,
	setGender,
	setDateOfBirth,
	setEthnicity,
	setRace,
	setEmploymentStatus,
	setOccupationTitle,
	setHeardAbout,
	setMedicalReleaseSignature,
	setPhoneNumber,
	setPhysicianFirstName,
	setPhysicianLastName,
	setPhysicianPhoneNumber,
	setPhysicianFaxNumber,
	setPhysicianSpecialty,
	setPhysicianOfficeAddressLine1,
	setPhysicianOfficeAddressLine2,
	setPhysicianOfficeCountry,
	setPhysicianOfficeAddressPostalCode,
	setPhysicianOfficeStateProvince,
	setPhysicianOfficeCity,
} = medicalProfileSlice.actions

export default medicalProfileSlice.reducer
