import en from "../translations/en"
import de from "../translations/de"
import fr from "../translations/fr"
import es from "../translations/es"
import ja from "../translations/ja"
import pt from "../translations/pt"

export const LANGUAGES = {
	en,
	de,
	fr,
	es,
	ja,
	pt
}
