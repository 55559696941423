import { Text } from "@rneui/themed"
import { createElement, useRef, useState } from "react"
import { Platform, View } from "react-native"
import MobileDatePicker from "@react-native-community/datetimepicker"
import { QuestionSubcomponentProps } from "../../../../../@types/components/question"
import { AnswerType, SetAnswerOfflineParams } from "../../../../../@types/redux/Answer"
import { setAnswerOffline } from "../../../../redux/answerState"
import { useAppDispatch } from "../../../../redux/hooks"
import { styles } from "../../styles"
import { setQuestionaireIsDirty } from "../../../../redux/assessmentState"



export const DateComponent = (props: QuestionSubcomponentProps) => {

	const {
		assessmentId,
		assessmentIndex,
		questionaireId,
		questionaireIndex,
		questionaireIsDirty,
		id,
		prompt,
		savedAnswer,
		isExtra,
		outerIndexOfExtra,
		innerIndexOfExtra,
		questionPath
	} = props

	const dispatch = useAppDispatch()

	const dateAnswer = useRef<Date>()

	const handleSetAnswerContent = () => {
		// here is where we update redux state with the response text
		console.log("date answer in submit: ", dateAnswer.current)
		const answerData: SetAnswerOfflineParams = {
			answer: {
				question: id,
				assessment: assessmentId,
				questionaire: questionaireId,
				date_content: dateAnswer?.current?.toISOString().split("T")[0]  //.toLocaleDateString().replaceAll("/", "-")
			},
			questionPath,
			assessmentIndex,
			questionaireIndex
		}

		if (isExtra && !(typeof outerIndexOfExtra === "undefined") && !(typeof innerIndexOfExtra === "undefined")) {
			answerData.answer["outer_index_of_extra"] = outerIndexOfExtra
			answerData.answer["inner_index_of_extra"] = innerIndexOfExtra
		}

		if (savedAnswer?.id) {
			answerData.answer.id = savedAnswer.id
		}
		dispatch(setAnswerOffline(answerData))

		if ((!questionaireIsDirty) && !(typeof assessmentIndex === "undefined")) {
			dispatch(setQuestionaireIsDirty({ assessmentIndex, questionaireIndex, isDirty: true }))
		}
	}
	const initialValue = savedAnswer?.date_content ? new Date(savedAnswer.date_content) : dateAnswer.current

	return (
		<View style={styles.questionContainer}>
			{
				Platform.OS == "web" ?
					createElement("input", {
						type: "date",
						value: initialValue?.toISOString().split("T")[0],
						onInput: (event: any) => {
							const date = new Date(event.target.value)
							console.log("date: ", date)
							dateAnswer.current = date
							handleSetAnswerContent()
						},
					})
					:
					<MobileDatePicker
						style={{ alignSelf: "flex-start", padding: 5 }}
						value={initialValue || new Date()}
						mode="date"
						onChange={(event) => {
							const date = new Date(event.nativeEvent.timestamp || 0)
							dateAnswer.current = date
							handleSetAnswerContent()
						}}
					/>
			}
		</View>
	)
}