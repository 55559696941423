import { Button } from "@rneui/themed"
import { useEffect, useState } from "react"
import { View } from "react-native"
import { QuestionSubcomponentProps } from "../../../../../@types/components/question"
import { AnswerType } from "../../../../../@types/redux/Answer"
import { removeExtraAssessmentQuestionaireAnswer, setAnswerOffline } from "../../../../redux/answerState"
import { addExtraAssessmentQuestionaireQuestion, removeExtraAssessmentQuestionaireQuestion } from "../../../../redux/assessmentState"
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks"
import { AppState } from "../../../../redux/store"
import { QuestionComponent } from "../../Question"


export const ExtraQuestions = (props: QuestionSubcomponentProps) => {

	const {
		assessmentId,
		assessmentIndex,
		extra_questions,
		questionPath,
		follow_up_questions,
		questionaireId,
		questionaireIndex,
		is_other,
		savedAnswer
	} = props

	const dispatch = useAppDispatch()

	const [shouldRenderExtraQuestions, setShouldRenderExtraQuestions] = useState<boolean>(false)

	useEffect(() => {
		const shouldShow = checkChildQuestions(savedAnswer)
		setShouldRenderExtraQuestions(shouldShow)
	}, [savedAnswer, extra_questions])

	const checkChildQuestions = (foundAnswer: AnswerType | undefined) => {
		const extraToRender = extra_questions?.filter(questionArr => {
			if (
				questionArr[0].triggering_choice?.includes(foundAnswer?.select_one_content || -1) 
					|| 
				questionArr[0].triggering_choice?.some(choice => foundAnswer?.select_multiple_content?.includes(choice))
			) {
				return true
			}
		})

		return extraToRender?.length ? true : false
	}
    
	const handleAddAnotherQuestion = () => {
		const data ={
			newExtraQuestions: follow_up_questions, 
			pathToSet: `${questionPath}.extra_questions`, 
			assessmentIndex: assessmentIndex || 0, 
			questionaireIndex
		}
		dispatch(addExtraAssessmentQuestionaireQuestion(data))
	}

	const handleRemoveExtraQuestion = (indexToRemove: number) => {
		const data = {
			pathToRemove: `${questionPath}.extra_questions`,
			assessmentIndex: assessmentIndex || 0,
			questionaireIndex,
			indexToRemove
		}
		dispatch(removeExtraAssessmentQuestionaireQuestion(data))
		data.pathToRemove = data.pathToRemove.replaceAll("questions", "Answers")
		dispatch(removeExtraAssessmentQuestionaireAnswer(data))
	}

	return (
		<View>
			{
				shouldRenderExtraQuestions ? extra_questions?.map((questions, outerIndex) => {
					return (
						<View key={outerIndex}>
							<View key={outerIndex}>
								{
									questions.map((question, innerIndex) => {
										return (
											<View key={innerIndex}>
												<QuestionComponent
													questionaireIsDirty
													should_show_all_questions={false}
													assessmentId={assessmentId}
													assessmentIndex={assessmentIndex}
													questionPath={`${questionPath}.extra_questions[${outerIndex}][${innerIndex}]`}
													questionaireId={questionaireId}
													questionaireIndex={questionaireIndex}
													{...question.child_question}
													isExtra={true}
													outerIndexOfExtra={outerIndex}
													innerIndexOfExtra={innerIndex}
													unansweredQuestions={props.unansweredQuestions}
													removeUnansweredQuestion={props.removeUnansweredQuestion}												/>
											</View>
										)
									})
								}
								<Button
									key={outerIndex}
									title="Remove"
									onPress={() => handleRemoveExtraQuestion(outerIndex)}
								/>
							</View>
						</View>
					)
				}) : null
			}

			{
				is_other ? <Button 
					title={"Add another"}
					onPress={handleAddAnotherQuestion}/>
					: null
			}
		</View>
	)
}