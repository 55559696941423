import { StyleSheet } from "react-native"

export const styles = StyleSheet.create({
	container: {
		flex: 1,
	},
	cardOuterContainer: {
		marginTop: 56,
		marginBottom: 24,
	},
	cardInnerContainer: {
		paddingVertical: 16,
	},
	cardSubtitle: {
		fontWeight: "bold",
		color: "black",
		textAlign: "center",
	},
	button: {
		marginHorizontal: 24,
		marginBottom: 16,
	},
	emailVerificationText: {
		textAlign: "center",
	},
	inputInnerContainer: {
		paddingHorizontal: 8,
	},
})
