import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit"
import axios from "axios"
import { ErrorResponseData } from "../../@types/axios/error"
import { GetQuestionaireReturnType, QuestionaireState, QuestionaireType, QuestionaireTypeChoices, SyncQuestionaireParams, ValidateQuestionaireParams, ValidateQuestionaireReturn } from "../../@types/redux/questionaire"
import { backendAPIClient } from "../axios/axios"
import { AppState } from "./store"
import { checkForInternetAccess, defaultInitialErrorState, sendErrorsToState } from "./util"
import { AnswerType, SetAnswerOfflineParams } from "../../@types/redux/Answer"
import { SubmitAnswerReturn } from "../../@types/screens/util"


const internalInitialState: QuestionaireState = {
	isLoading: false,
	errors: defaultInitialErrorState
}


export const questionaireSlice = createSlice({
	name: "questionaireState",
	initialState: internalInitialState,
	reducers: {
		setSelectedQuestionaire: (state, action: PayloadAction<QuestionaireType>) => {
			state.selected = action.payload
		},
		resetQuestionaire: () => internalInitialState,
		resetErrors: (state, action: PayloadAction<undefined>) => {
			state.errors = defaultInitialErrorState
		}
	},
	extraReducers: (builder) => {
		builder.addCase(getQuestionaire.pending, (state, action) => {
			state.isLoading = true
		}),
		builder.addCase(getQuestionaire.fulfilled, (state, action) => {
			const questionaireType = action.payload.questionaireType
			state.selected = action.payload.questionaires[0]
			switch (questionaireType) {
			case "eligibility":
				state.eligibility = action.payload.questionaires[0]
				break
			case "comprehension":
				state.comprehension = action.payload.questionaires[0]
				break
			case "registration":
				state.registration = action.payload.questionaires[0]
				break
			}
			state.isLoading = false
		}),
		builder.addCase(getQuestionaire.rejected, (state, action) => {
			state.isLoading = false
			sendErrorsToState(state, action)
		}),
		builder.addCase(validateQuestionaire.pending, (state, action) => {
			state.isLoading = true
		}),
		builder.addCase(validateQuestionaire.fulfilled, (state, action) => {
			state.isLoading = false
			if (state.selected) {
				state.selected.isValid = action.payload.is_valid
				state.selected.whyFailed = action.payload.why_failed
			}
		}),
		builder.addCase(validateQuestionaire.rejected, (state, action) => {
			state.isLoading = false
			sendErrorsToState(state, action)
		}),
		builder.addCase(submitEligibilityAnswerRedux.pending, (state, action) => {
			state.isLoading = true
		}),
		builder.addCase(submitEligibilityAnswerRedux.fulfilled, (state, action) => {
			state.isLoading = false
		}),
		builder.addCase(submitEligibilityAnswerRedux.rejected, (state, action) => {
			state.isLoading = false
			console.log("errors: ", action.payload)
			sendErrorsToState(state, action)
		})
	}
})

export const getQuestionaire = createAsyncThunk<
	GetQuestionaireReturnType,
	QuestionaireTypeChoices, 
	{
		state: AppState, 
		rejectValue: ErrorResponseData | undefined
	}>(
		"getQuestionaire",
		async (questionaireType, { getState, rejectWithValue }) => {

			await checkForInternetAccess()

			const selectedDisease = getState().diseaseState.selected

			try {
				const questionairesUrl = `api/v1/questionaires/?disease=${selectedDisease?.id}&questionaire_type=${questionaireType}`
				const response = await backendAPIClient.get(questionairesUrl)
				// console.log("resposne from get questionaire: ", response)
				return {questionaires: response.data, questionaireType: questionaireType}
			} catch (error) {
				if (axios.isAxiosError(error)) {
					throw rejectWithValue(error.response?.data)
				} else {
					throw error
				}
			}
		}
	)

export const validateQuestionaire = createAsyncThunk<
	ValidateQuestionaireReturn, 
	ValidateQuestionaireParams, 
	{
		state: AppState,
		rejectValue: ErrorResponseData | undefined
	}>(
		"validateQuestionaire",
		async ({ questionaireId, questionaireIndex }, { getState, rejectWithValue, dispatch }) => {

			await checkForInternetAccess()
			dispatch(resetErrors())

			const answerState = getState().answerState
		
			try {
				const validateQuestionaireUrl = `api/v1/questionaires/${questionaireId}/validate/`

				const answers = answerState.questionaireAnswerState[questionaireIndex]

				const answerData: {questions: Array<AnswerType>} = {
					questions: []
				}

				const unpackAnswers = (answer: AnswerType) => {
					if (answer && (answer !== null)) {
						answerData.questions.push(answer)
					}
					if (answer?.follow_up_answers) {
						answer?.follow_up_answers?.forEach(foa => unpackAnswers(foa))
					}
				}

				answers.forEach(rootAnswer => unpackAnswers(rootAnswer))

				const response = await backendAPIClient.post(validateQuestionaireUrl, answerData)
				return response.data
			} catch (error) {
				if (axios.isAxiosError(error)) {
					throw rejectWithValue(error.response?.data)
				} else {
					throw error
				}
			}
		
		}
	)

export const submitEligibilityAnswerRedux = createAsyncThunk<
	SubmitAnswerReturn,
	SetAnswerOfflineParams, 
	{
		state: AppState, 
		rejectValue: ErrorResponseData | undefined
	}>(
		"submitEligibilityAnswers",
		async ({ answer, questionPath, questionaireIndex }, { getState, rejectWithValue }) => {

			await checkForInternetAccess()

			// const answers = getState().answerState.questionaireAnswerState[questionaireIndex]
			// console.log("answers: ", answers)

			try {

				// const answersUrl = "api/v1/answers/"
				// const answerForSubmission = {...answer}
				// delete answerForSubmission.follow_up_answers
				// const response = await backendAPIClient.post(answersUrl, answerForSubmission, {headers: {"Content-Type": "application/json"}})
				// answer.id = response.data.id
				// answer.parent_answer = response.data.parent_answer

				const submitEligibilityAnswersUrl = "api/v1/answers/eligibility_answers/"
				const answerForSubmission = {...answer}
				delete answerForSubmission.follow_up_answers
				const response = await backendAPIClient.post(submitEligibilityAnswersUrl, answerForSubmission, {headers: {"Content-Type": "application/json"}})

				console.log("resposne from submit answers for eligibility: ", response)
				return response.data
			} catch (error) {
				if (axios.isAxiosError(error)) {
					throw rejectWithValue(error.response?.data)
				} else {
					throw error
				}
			}
		}
	)
// actions are generated by the createSlice function
export const { setSelectedQuestionaire, resetQuestionaire, resetErrors } = questionaireSlice.actions

export default questionaireSlice.reducer
