import { StyleSheet } from "react-native"

export const styles = StyleSheet.create({
	diseasePickerContainer: {
		flex: 1,
		justifyContent: "center",
		alignContent: "center",
		alignItems: "center"
	},
	checkboxContainer: {
		flex: 1,
		alignItems: "flex-start",
		justifyContent: "center"
	}
})
