import { StyleSheet } from "react-native"

export const styles = StyleSheet.create({
	container: {
		flex: 1,
	},
	intructionText: {
		fontSize: 16,
		padding: 8,
		textAlign: "center",
	},
	questionsContainer: {
		flex: 1,
	},
})
