import { StyleSheet } from "react-native"

export const styles = StyleSheet.create({
	container: {
		// flex: 1,
		// backgroundColor: "#f5f5f5",
		// padding: 15,
	},
	card: {
		// borderRadius: 10,
		// backgroundColor: "#fff",
		// marginBottom: 15,
		// shadowColor: "#000",
		// shadowOffset: {
		// 	width: 0,
		// 	height: 2,
		// },
		// shadowOpacity: 0.23,
		// shadowRadius: 2.62,
		// elevation: 4,
	},
	cardTitle: {
		// color: "#303030",
		// fontSize: 18,
		// fontWeight: "600",
		// margin: 15,
	},
	input: {
		marginTop: 15,
		// height: 50,
		// borderColor: "#d3d3d3",
		// borderBottomWidth: 1,
		padding: 8,
	},
	picker: {
		margin: 16,
		// height: 50,
		// width: "95%",
		// borderColor: "#d3d3d3",
		borderBottomWidth: 1,
		padding: 10,
		fontSize: 14,
		borderRadius: 10,
	},
	labelText: {
		// color: "#303030",
		// fontSize: 14,
		// fontWeight: "600",
		// margin: 15,
	},
	dateInput: {
		margin: 16,
		padding: 8,
		fontSize: 14,
		borderRadius: 10,
		borderBottomWidth: 1,
		// alignSelf: "flex-start",
		// padding: 5,
		// borderWidth: 1,
		// borderColor: "#d3d3d3",
		// borderRadius: 5,
		// margin: 15,
		// height: 50,
	},
	button: {
		marginTop: 10,
		// backgroundColor: "#6C65FF",
		// padding: 10,
		// borderRadius: 5,
		// alignItems: "center",
		// marginVertical: 20,
	},
	buttonText: {
		// color: "#fff",
		// fontSize: 16,
	},
})
