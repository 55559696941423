import { Alert, Platform } from "react-native"
import { AnswerState, AnswerType, SetAnswerOfflineParams } from "../../@types/redux/Answer"
import { FollowUpQuestion, QuestionType, QuestionTypeChoices } from "../../@types/redux/question"
import { CheckFullyAnswered, SubmitAnswerProps, SubmitEligibilityAnswerProps, SubmitAnswerReturn } from "../../@types/screens/util"
import * as _ from "lodash"
import { filterObject, removeFalseyValues } from "../../util"
import store from "../redux/store"
import { submitSingleAnswer } from "../redux/answerState"
import { showToast } from "../../toast"
import { submitEligibilityAnswerRedux } from "../redux/questionaireState"
import { makeRootPath } from "../components/util"

/**
 * Check if all questions in a questionnaire have answers.
 * 
 * @param {object} params - The parameters object.
 * @param {QuestionaireType} params.questionaire - The questionaire to check.
 * @param {AnswerState} params.answerState - The state of answers.
 * @param {number} params.assessmentIndex - The index of the assessment.
 * @param {number} params.questionaireIndex - The index of the questionnaire.
 * @return {object} An object containing a boolean indicating whether all questions are answered,
 *                  and an array of paths to unanswered questions.
 */
export const checkAllQuestionsHaveAnswers = ({
	questionaire,
	answerState,
	assessmentIndex,
	questionaireIndex
}: CheckFullyAnswered): { allAnswered: boolean, unansweredQuestionPaths: string[] } => {

	const checkAll = questionaire.should_show_all_questions
	let unansweredQuestionPaths: string[] = []

	try {
		// Fetching the answers for the given questionnaire.
		const questionaireAnswers = getQuestionaireAnswers(answerState, assessmentIndex, questionaireIndex)
		if (questionaireAnswers) {
			// Processing each question to check if it has a valid answer.
			unansweredQuestionPaths = processQuestions(
				questionaire.questions,
				questionaireAnswers,
				checkAll,
				"",
				assessmentIndex,
				questionaireIndex
			)
		} else {
			// If there are no answers, generate paths for all parent questions as they are all unanswered.
			unansweredQuestionPaths = generateUnansweredPathsForAllParentQuestions(
				questionaire.questions,
				assessmentIndex,
				questionaireIndex
			)
		}
	} catch (error) {
		console.error("Error checking question answers:", error)
		throw error  // re-throw the error after logging it
	}

	return {
		allAnswered: unansweredQuestionPaths.length === 0,
		unansweredQuestionPaths
	}
}

/**
 * Get the answers for a questionnaire from the answer state.
 * 
 * @param {AnswerState} answerState - The state of answers.
 * @param {number} assessmentIndex - The index of the assessment.
 * @param {number} questionaireIndex - The index of the questionnaire.
 * @return {AnswerType[]} The answers for the questionnaire.
 */
const getQuestionaireAnswers = (answerState: AnswerState, assessmentIndex: number | undefined, questionaireIndex: number) => {
	// Fetching the answers based on the availability of the assessmentIndex.
	return assessmentIndex !== undefined
		? answerState.assessmentAnswerState?.[assessmentIndex]?.[questionaireIndex]
		: answerState.questionaireAnswerState?.[questionaireIndex]
}

/**
 * Generate paths to all parent questions in a questionnaire.
 * 
 * @param {QuestionType[]} questions - The questions in the questionnaire.
 * @param {number} assessmentIndex - The index of the assessment.
 * @param {number} questionaireIndex - The index of the questionnaire.
 * @return {string[]} An array of paths to all parent questions.
 */
const generateUnansweredPathsForAllParentQuestions = (questions: QuestionType[], assessmentIndex: number | undefined, questionaireIndex: number) => {
	// Generating paths for all parent questions to mark them as unanswered.
	const rootPath = makeRootPath(assessmentIndex, questionaireIndex)
	return questions.map((_, i) => `${rootPath}[${i}]`)
}


/**
 * Check if a question has an answer.
 * 
 * @param {QuestionType} question - The question to check.
 * @param {AnswerType} questionAnswer - The answer for the question.
 * @param {boolean} checkAll - Whether to check all questions.
 * @param {string} currentPath - The current path in the question tree.
 * @return {object} An object containing a boolean indicating whether the question is answered,
 *                  and an array of paths to unanswered questions.
 */
export const checkQuestionHasAnswer = (
	question: QuestionType,
	questionAnswer: AnswerType | undefined,
	checkAll: boolean,
	currentPath: string // Represents the path to the current question in the question structure.
): { isAnswered: boolean, unansweredQuestionPaths: string[] } => {
	let unansweredQuestionPaths: string[] = []

	// Validating the answer for the current question.
	const answerIsValid = checkAnswerIsValid(question.question_type, questionAnswer)
	if (!answerIsValid) {
		// If the answer is not valid, adding the current question path to the unanswered list.
		unansweredQuestionPaths.push(currentPath)
	}

	if (question.follow_up_questions.length) {
		// Iterate through follow-up questions and check if any of them are unanswered.
		const followUpUnanswered = question.follow_up_questions.flatMap((q, i) => {
			const answ = questionAnswer?.follow_up_answers?.[i]
			const shouldCheckChild = q.triggering_choice.includes(questionAnswer?.select_one_content || -1)
							|| q.triggering_choice.some(choice => questionAnswer?.select_multiple_content?.includes(choice))
			const childPath = `${currentPath}.follow_up_questions[${i}]`

			return (shouldCheckChild || checkAll)
				? checkQuestionHasAnswer(q.child_question, answ, checkAll, childPath).unansweredQuestionPaths
				: []
		})

		unansweredQuestionPaths = unansweredQuestionPaths.concat(followUpUnanswered)
	}

	if (question.extra_questions) {
		// Iterate through extra questions and check if any of them are unanswered.
		const extraUnanswered = question.extra_questions.flatMap((questionsArr, outerIndex) => {
			return questionsArr.flatMap((extraQuestion, innerIndex) => {
				const answ = questionAnswer?.extra_answers?.[outerIndex]?.[innerIndex]
				const extraPath = `${currentPath}.extra_questions[${outerIndex}][${innerIndex}]`

				if (
					extraQuestion.triggering_choice.includes(questionAnswer?.select_one_content || -1)
									|| extraQuestion.triggering_choice.some(choice => questionAnswer?.select_multiple_content?.includes(choice))
				) {
					return checkQuestionHasAnswer(extraQuestion.child_question, answ, checkAll, extraPath).unansweredQuestionPaths
				}
				return []
			})
		})

		unansweredQuestionPaths = unansweredQuestionPaths.concat(extraUnanswered)
	}

	return {
		isAnswered: unansweredQuestionPaths.length === 0,
		unansweredQuestionPaths
	}
}




export const checkAnswerIsValid = (questionType: QuestionTypeChoices, answer: AnswerType | undefined): boolean => {
	switch (questionType) {
	case "radio":
		return answer?.select_one_content ? true : false
	case "select_multiple":
		return answer?.select_multiple_content?.length ? true : false
	case "date":
		return answer?.date_content ? true : false
	case "float":
		return answer?.float_content ? true : false
	case "integer":
		return (typeof answer?.integer_content === "number") ? true : false
	case "text":
		return Object.keys(answer?.translations || {}).length ? true : false
	default:
		return true
	}
}


export const checkHasChildrenToSubmit = (props: SubmitAnswerProps | SubmitEligibilityAnswerProps): boolean => {

	const {
		questionPath,
		answer,
		questionState
	} = props

	const question = _.get(questionState, questionPath.replaceAll("answer", "question"))

	if (
		question?.follow_up_questions?.map((followUp: FollowUpQuestion) => followUp.triggering_choice.includes(answer.select_one_content || -1)).some((res: boolean) => res === true)
		||
		question?.follow_up_questions?.map((followUp: FollowUpQuestion) => followUp.triggering_choice.some((choice: number) => answer.select_multiple_content?.includes(choice))).some((res: boolean) => res === true)
	) {
		return true
	} else {
		return false
	}
}


export const submitAnswer = async (props: SubmitAnswerProps): SubmitAnswerReturn => {

	const {
		answer,
		parentAnswerId=undefined,
		questionPath,
		assessmentIndex,
		questionaireIndex,
		questionState
	} = props
	
	const filteredAnswer = filterObject(answer, removeFalseyValues) as AnswerType
	// only root level answers will not have parent id's
	if (parentAnswerId) { // parent id's will be passed to children after the parent has been created in the database
		filteredAnswer.parent_answer = parentAnswerId
	}

	let response: AnswerType
	try { // first submit yourself
		response = await store.dispatch(submitSingleAnswer({ answer: filteredAnswer, questionPath, assessmentIndex, questionaireIndex })).unwrap()

		const hasChildrenToSubmit = checkHasChildrenToSubmit(props)

		if (hasChildrenToSubmit) {
			// then submit your children
			const childResponses: any = answer?.follow_up_answers?.flatMap((childAnswer: AnswerType, index: number) => {
				const data: SubmitAnswerProps = {
					answer: childAnswer,
					parentAnswerId: response.id,
					questionPath: `${questionPath}.follow_up_answers[${index}]`,
					assessmentIndex,
					questionaireIndex,
					questionState
				}
				return submitAnswer(data)
			})

			const extraChildResponses: any = answer.extra_answers?.flatMap((extraAnswerArr: AnswerType[], outerIndex: number) => {
				return extraAnswerArr.flatMap((extraAnswer: AnswerType, innerIndex: number) => {
					const data: SubmitAnswerProps = {
						answer: extraAnswer,
						parentAnswerId: response.id,
						questionPath: `${questionPath}.extra_answers[${outerIndex}][${innerIndex}]`,
						assessmentIndex,
						questionaireIndex,
						questionState
					}
					return submitAnswer(data)
				})
			})
			const kids = childResponses ? await Promise.allSettled(childResponses) : []
			const extraKids = extraChildResponses ? await Promise.allSettled(extraChildResponses) : []
			return [...kids, ...extraKids]
		}

	} catch (error) { console.log("error submitting answer: ", error) }
}

export const submitEligibilityAnswer = async (props: SubmitEligibilityAnswerProps) => {

	const {
		answer,
		parentAnswerId=undefined,
		questionPath,
		questionaireIndex,
		questionState
	} = props
	
	const filteredAnswer = filterObject(answer, removeFalseyValues) as AnswerType
	// only root level answers will not have parent id's
	if (parentAnswerId) { // parent id's will be passed to children after the parent has been created in the database
		filteredAnswer.parent_answer = parentAnswerId
	}

	let response: AnswerType
	try { // first submit yourself
		const data: SetAnswerOfflineParams = { answer: filteredAnswer, questionPath, questionaireIndex }
		response = await store.dispatch(submitEligibilityAnswerRedux({ answer: filteredAnswer, questionPath, questionaireIndex })).unwrap()

		const hasChildrenToSubmit = checkHasChildrenToSubmit(props)

		if (hasChildrenToSubmit) {
			// then submit your children
			const childResponses: any = answer?.follow_up_answers?.flatMap((childAnswer: AnswerType, index: number) => {
				const data: SubmitEligibilityAnswerProps = {
					answer: childAnswer,
					parentAnswerId: response.id,
					questionPath: `${questionPath}.follow_up_answers[${index}]`,
					questionaireIndex,
					questionState
				}
				return submitEligibilityAnswer(data)
			})

			return childResponses ? await Promise.allSettled(childResponses) : []
		}

	} catch (error) { console.log("error submitting answer: ", error) }
}


export const alertForUnansweredQuestions = () => {
	// showAlert("You have unanswered questions! All questions must be answered before continuing.")s
	showToast({ message: "You have unanswered questions! All questions must be answered before continuing.", kind: "failure" })
}

export const alertForUnsignedConsentForm = () => {
	// showAlert("Please sign the consent form before confirming")
	showToast({ message: "Please sign the consent form before confirming", kind: "failure" })
}

export const alertForUncheckedConsentSummary = () => {
	// showAlert("Please read and accept the consent summary")
	showToast({ message: "Please read and accept the consent summary", kind: "failure" })
}

export const showAlert = (message: string) => {
	if (Platform.OS == "web") {
		alert(message)
	} else {
		Alert.alert(
			message,
			undefined,
			[{text: "Okay", style: "cancel"}]
		)
	}
}

/**
 * Process a list of questions to find which ones are unanswered.
 * 
 * @param {QuestionType[]} questions - The questions to process.
 * @param {AnswerType[]} answers - The answers for the questions.
 * @param {boolean} checkAll - Whether to check all questions.
 * @param {string} currentPath - The current path in the question tree.
 * @param {number} assessmentIndex - The index of the assessment.
 * @param {number} questionaireIndex - The index of the questionnaire.
 * @return {string[]} An array of paths to unanswered questions.
 */
const processQuestions = (
	questions: QuestionType[], 
	answers: AnswerType[], 
	checkAll: boolean, 
	currentPath = "", // Represents the current path in the question structure.
	assessmentIndex: number | undefined, 
	questionaireIndex: number): string[] => {
	// Generating the root path based on the assessment and questionnaire indices.
	const rootPath = makeRootPath(assessmentIndex, questionaireIndex)
	let unansweredQuestionPaths: string[] = []
	for (let i = 0; i < questions.length; i++) {
		// Generating the path for the current question.
		const questionPath = `${rootPath}${currentPath}[${i}]`
		// Checking if the current question has a valid answer.
		const result = checkQuestionHasAnswer(questions[i], answers[i], checkAll, questionPath)
		// Concatenating any unanswered question paths found.
		unansweredQuestionPaths = unansweredQuestionPaths.concat(result.unansweredQuestionPaths)
	}
	return unansweredQuestionPaths
}