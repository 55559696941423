import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit"
import axios from "axios"
import { ErrorResponseData } from "../../@types/axios/error"
import { AnswerFromServerType, AnswerState, AnswerType, QuestionAnswer, SetAnswerOfflineParams, SyncAnswersParams } from "../../@types/redux/Answer"
import { backendAPIClient } from "../axios/axios"
import { AppState } from "./store"
import { checkForInternetAccess, defaultInitialErrorState, sendErrorsToState } from "./util"
import * as _ from "lodash"
import { makeRootPath } from "../components/util"

const internalInitialState: AnswerState = {
	assessmentAnswerState: [],
	questionaireAnswerState: [],
	isLoading: false,
	errors: { general: [] }
}


export const answerSlice = createSlice({
	name: "answerState",
	initialState: internalInitialState,
	reducers: { 
		resetAnswer: () => internalInitialState,
		resetErrors: (state, action: PayloadAction<undefined>) => {
			state.errors = defaultInitialErrorState
		},
		newQuestionaireAnswers: (state, action: PayloadAction<{ assessmentIndex: number, questionaireIndex: number, answers: AnswerType[] }>) => {
			const { assessmentIndex, questionaireIndex, answers } = action.payload
			console.log("doing new questionaire answers")
			state.assessmentAnswerState[assessmentIndex][questionaireIndex] = answers
		},
		setAnswerOffline: (state, action: PayloadAction<SetAnswerOfflineParams>) => {
			const { answer, assessmentIndex, questionaireIndex } = action.payload
			const pathExtension = action.payload.questionPath
			const path = `${makeRootPath(assessmentIndex, questionaireIndex)}${pathExtension}`.replaceAll("questions", "answers").replaceAll(".child_question", "")
			
			console.log("setting answer offline: ", action.payload)

			if (!(typeof assessmentIndex === "undefined")) {
				const newState = JSON.parse(JSON.stringify(state.assessmentAnswerState))
				const prevState = _.get(newState, path)
				if (prevState?.id) {
					answer.id = prevState.id
				}
				_.set(newState, path, answer)
				state.assessmentAnswerState = newState
			} else {
				const newState = JSON.parse(JSON.stringify(state.questionaireAnswerState))
				console.log("path: ", path)
				const prevState = _.get(newState, path)
				if (prevState?.id) {
					answer.id = prevState.id
				}
				_.set(newState, path, answer)
				console.log("new state: ", newState)
				state.questionaireAnswerState = newState
			}
		},
		removeExtraAssessmentQuestionaireAnswer: (state, action: PayloadAction<{ pathToRemove: string, assessmentIndex: number, questionaireIndex: number, indexToRemove: number }>) => {
			const { pathToRemove, assessmentIndex, questionaireIndex, indexToRemove } = action.payload
			const newState = JSON.parse(JSON.stringify(state.assessmentAnswerState))
			const path = `${makeRootPath(assessmentIndex, questionaireIndex)}${pathToRemove}`
			const extraQuestions = _.get(newState, path)
			extraQuestions?.splice(indexToRemove, 1)
			state.assessmentAnswerState = newState
		},
	},
	extraReducers: (builder) => {
		builder.addCase(submitSingleAnswer.pending, (state, action) => {
			state.isLoading = true
		}),
		builder.addCase(submitSingleAnswer.fulfilled, (state, action) => {
			state.isLoading = false
		}),
		builder.addCase(submitSingleAnswer.rejected, (state, action) => {
			state.isLoading = false
			sendErrorsToState(state, action)
		}),
		builder.addCase(updateSingleAnswer.pending, (state, action) => {
			state.isLoading = true
		}),
		builder.addCase(updateSingleAnswer.fulfilled, (state, action) => {
			state.isLoading = false
		}),
		builder.addCase(updateSingleAnswer.rejected, (state, action) => {
			state.isLoading = false
			sendErrorsToState(state, action)
		}),
		builder.addCase(syncAnswersForAssessmentQuestionaire.pending, (state, action) => {
			state.isLoading = true
		}),
		builder.addCase(syncAnswersForAssessmentQuestionaire.fulfilled, (state, action) => {
			state.isLoading = false
		}),
		builder.addCase(syncAnswersForAssessmentQuestionaire.rejected, (state, action) => {
			state.isLoading = false
			sendErrorsToState(state, action)
		}),
		builder.addCase(deletePreviousAssessmentQuestionaireAnswers.pending, (state, action) => {
			state.isLoading = true
		}),
		builder.addCase(deletePreviousAssessmentQuestionaireAnswers.fulfilled, (state, action) => {
			state.isLoading = false
		}),
		builder.addCase(deletePreviousAssessmentQuestionaireAnswers.rejected, (state, action) => {
			state.isLoading = false
			sendErrorsToState(state, action)
		})
	}
})

export const submitSingleAnswer = createAsyncThunk<
AnswerType,
SetAnswerOfflineParams,
{
	state: AppState,
	rejectValue: ErrorResponseData | undefined
}
>(
	"submitSingleAnswer",
	async ({ answer, questionPath, assessmentIndex, questionaireIndex }: SetAnswerOfflineParams, { dispatch, rejectWithValue }): Promise<AnswerType> => {
		
		await checkForInternetAccess()
		dispatch(resetErrors())

		try {
			const answersUrl = "api/v1/answers/"
			const answerForSubmission = {...answer}
			delete answerForSubmission.follow_up_answers
			const response = await backendAPIClient.post(answersUrl, answerForSubmission, {headers: {"Content-Type": "application/json"}})
			answer.id = response.data.id
			answer.parent_answer = response.data.parent_answer
			
			// dispatch(setAnswerOffline({ answer, questionPath, assessmentIndex, questionaireIndex }))

			return response.data
		} catch (error) {
			if (axios.isAxiosError(error)) {
				throw rejectWithValue(error.response?.data)
			} else {
				throw error
			}
		}
	}
)

export const updateSingleAnswer = createAsyncThunk<
AnswerType,
SetAnswerOfflineParams,
{
	state: AppState,
	rejectValue: ErrorResponseData | undefined
}
>(
	"updateSingleAnswer",
	async ({ answer, questionPath, assessmentIndex, questionaireIndex }: SetAnswerOfflineParams, { dispatch, rejectWithValue }): Promise<AnswerType> => {

		await checkForInternetAccess()
		dispatch(resetErrors())

		try {
			if (answer.id) {
				const answersUrl = `api/v1/answers/${answer.id}/`
				const answerForSubmission = {...answer}
				delete answerForSubmission.follow_up_answers
				const response = await backendAPIClient.patch(answersUrl, answerForSubmission, {headers: {"Content-Type": "application/json"}})
				answer.id = response.data.id
				answer.parent_answer = response.data.parent_answer
				
				dispatch(setAnswerOffline({answer, questionPath, assessmentIndex, questionaireIndex}))

				return response.data
			} else {
				throw Error("answer did not have id. Answer must have id to make patch request")
			}
		} catch (error) {
			if (axios.isAxiosError(error)) {
				console.log("axios error from submitting: ", error)
				throw rejectWithValue(error.response?.data)
			} else {
				throw error
			}
		}
	}
)

export const syncAnswersForAssessmentQuestionaire = createAsyncThunk<
AnswerType[],
SyncAnswersParams,
{
	state: AppState,
	rejectValue: ErrorResponseData | undefined
}
>(
	"syncAnswersForAssessmentQuestionaire",
	async ({ assessmentId, questionaireId, assessmentIndex, questionaireIndex }, { dispatch, rejectWithValue, getState }): Promise<AnswerType[]> => {

		await checkForInternetAccess()
		dispatch(resetErrors())

		const answerState = getState().answerState

		try {
			const answersUrl = `api/v1/answers/?assessment_pk=${assessmentId}&questionaire_pk=${questionaireId}&parent_answer_null=true`
			const response = await backendAPIClient.get(answersUrl)			

			// answers are ordered by question pk in response, so index will be the same for questionIndex
			// response.data.forEach((answerFromServer: AnswerFromServerType, questionIndex: number) => {
			// 	console.log("here is the path: ", answerFromServer)
			// 	const path = `[${assessmentIndex}][${questionaireIndex}][${questionIndex}]`
			// 	if (!_.has(answerState.assessmentAnswerState, path)) { // if there is an answer in state, do not overwrite it
			// 		const data = {
			// 			answer: answerFromServer,
			// 			questionPath: path,
			// 			assessmentIndex,
			// 			questionaireIndex
			// 		}
			// 		dispatch(setAnswerOffline(data))
			// 	}
			// })
			
			console.log("sync assessments response: ", response.data)
			return getState().answerState.assessmentAnswerState[assessmentIndex][questionaireIndex]
		} catch (error) {
			if (axios.isAxiosError(error)) {
				throw rejectWithValue(error.response?.data)
			} else {
				throw error
			}
		}

	}
)


export const deletePreviousAssessmentQuestionaireAnswers = createAsyncThunk<
void,
SyncAnswersParams,
{
	state: AppState,
	rejectValue: ErrorResponseData | undefined
}
>(
	"deletePreviousAssessmentQuestionaireAnswers",
	async ({ assessmentId, questionaireId }, { dispatch, rejectWithValue }): Promise<void> => {

		await checkForInternetAccess()
		dispatch(resetErrors())

		try {
			const answersUrl = `api/v1/answers/bulk_delete/?assessment_pk=${assessmentId}&questionaire_pk=${questionaireId}`
			const response = await backendAPIClient.delete(answersUrl)			
			
			console.log("sync assessments response: ", response)
			
		} catch (error) {
			if (axios.isAxiosError(error)) {
				throw rejectWithValue(error.response?.data)
			} else {
				throw error
			}
		}

	}
)


// actions are generated by the createSlice function
export const { resetAnswer, setAnswerOffline, resetErrors, newQuestionaireAnswers, removeExtraAssessmentQuestionaireAnswer } = answerSlice.actions

export default answerSlice.reducer