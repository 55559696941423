import { useNavigation } from "@react-navigation/native"
import { ComprehensionQuestionScreenNavigationProp } from "../../../../@types/navigation/AuthNavigator"
import { QuestionaireComponent } from "../../../components/Questionare/Questionaire"
import { useAppDispatch, useAppSelector } from "../../../redux/hooks"
import { AppState } from "../../../redux/store"
import { checkAllQuestionsHaveAnswers, alertForUnansweredQuestions  } from "../../util"
import { View } from "react-native"
import { LoadingSpinner } from "../../../components/Loading/LoadingSpinner"
import { resetErrors as clearAnswerErrors } from "../../../redux/answerState"
import { resetErrors as clearQuestionaireErrors, validateQuestionaire} from "../../../redux/questionaireState"
import { QuestionaireResult } from "../../../components/QuestionaireResult/QuestionaireResult"
import { useState } from "react"
import { COMPREHENSION_QUESTIONAIRE_INDEX } from "../../../../constants/general"




export const ComprehensionQuestionScreen = () => {

	const navigation = useNavigation<ComprehensionQuestionScreenNavigationProp>()

	const dispatch = useAppDispatch()

	const questionaireState = useAppSelector((state: AppState) => state.questionaireState)
	const answerState = useAppSelector((state: AppState) => state.answerState)

	const [showUserNotEligible, setShowUserNotEligible] = useState(false)
	const [unansweredQuestions, setUnansweredQuestions] = useState<string[]>([])


	const handleComprehensionQuestionSubmit = async () => {		
		if (questionaireState.comprehension) {
			const questionaire = questionaireState.comprehension
			const { allAnswered, unansweredQuestionPaths } = checkAllQuestionsHaveAnswers({ questionaire, answerState, questionaireIndex: COMPREHENSION_QUESTIONAIRE_INDEX })
			setUnansweredQuestions(unansweredQuestionPaths)

			if (!allAnswered) {
				alertForUnansweredQuestions()
			} else {
				await dispatch(validateQuestionaire({ questionaireId: questionaireState.comprehension.id, questionaireIndex: COMPREHENSION_QUESTIONAIRE_INDEX}))
				switch (questionaireState.selected?.isValid) {
				case false:
				case undefined:
					setShowUserNotEligible(true)
					return
						
				case true:
					setShowUserNotEligible(false)
					break
						
				default:
					console.log("in default comprehension submit, we shouldnt get here")
					return
				}
			}
		}
	}

	const removeUnansweredQuestion = (answeredQuestionPath: string) => {
		setUnansweredQuestions(prevQuestions => prevQuestions.filter(questionPath => questionPath !== answeredQuestionPath))
	}

	const handleAnswerErrorDismiss = () => {
		dispatch(clearAnswerErrors())
	}

	const handleQuestionaireErrorDismiss = () => {
		dispatch(clearQuestionaireErrors())
	}

	const handleQuestionaireResult = () => {
		if (questionaireState.selected) {
			questionaireState.selected?.isValid ? navigation.navigate("Consent") : navigation.navigate("ConsentSummary")
		}
	}

	if (showUserNotEligible) {
		return (
			<>
				<QuestionaireResult shouldShowSelectDisease={false} onPressCallback={handleQuestionaireResult} isValid={questionaireState.selected?.isValid || false}/>
			</>
		)
	}

	return (
		<View testID="comprehensionScreen" style={{flex: 1}}>
			<LoadingSpinner isLoading={questionaireState.isLoading} message="Loading..." />
			<QuestionaireComponent 
				questionaire={
					questionaireState.comprehension ?
						questionaireState.comprehension :
						questionaireState.selected
				} 
				onSubmitCallback={handleComprehensionQuestionSubmit}
				isDirty={false}
				questionaireIndex={COMPREHENSION_QUESTIONAIRE_INDEX}
				assessmentIndex={undefined}
				unansweredQuestions={unansweredQuestions}
				removeUnansweredQuestion={removeUnansweredQuestion}
			/>

		</View>
	)
}
