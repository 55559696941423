import { useState } from "react"
import { useTranslation } from "react-i18next"
import { MedicalProfileScreenNavigationProp, MedicalProfileScreenProps } from "../../../../@types/navigation/AuthNavigator"
import { useAppDispatch, useAppSelector } from "../../../redux/hooks"
import { styles } from "./styles"
import { Button, Card, Text } from "@rneui/themed"
import { AppState } from "../../../redux/store"
import { View, ScrollView, SafeAreaView } from "react-native"
import { useNavigation } from "@react-navigation/native"
import { AuthFooterComponent } from "../../../components/AuthFooter/AuthFooter"
import { ConsentForm } from "../../../components/Consent/Consent"
import { showToast } from "../../../../toast"
import { createMedicalProfile, setLoadingState, setMedicalReleaseSignature } from "../../../redux/medicalProfileState"
import { MedicalProfile } from "../../../components/MedicalProfile/MedicalProfile"
import { downloadDocument, getDiseaseDocuments } from "../../../redux/documentState"
import { LoadingSpinner } from "../../../components/Loading/LoadingSpinner"


export const MedicalProfileScreen = ({ route }: MedicalProfileScreenProps) => {

	const { userId } = route.params

	const navigation = useNavigation<MedicalProfileScreenNavigationProp>()
	const { t } = useTranslation(["translation", "screens"])
	const [hasViewedDocument, setHasViewedDocument] = useState(false)

	const dispatch = useAppDispatch()
	const medProfile = useAppSelector((state: AppState) => state.medicalProfileState.profile)
	const medProfileLoading = useAppSelector((state: AppState) => state.medicalProfileState.isLoading)
	const documentStateLoading = useAppSelector((state: AppState) => state.documentState.isLoading)
	const disease = useAppSelector((state: AppState) => state.diseaseState.selected?.id)?.toString()

	const handleCreateMedicalProfile = async () => {
		try {
			if (!medProfile.medical_release_signature) {
				console.log("dont have signature")
				showToast({ message: t("signMedForm"), kind: "failure" })
				return
			}
			const medProfileResponse = await dispatch(createMedicalProfile(userId)).unwrap()
			console.log("medical profile create response: ", medProfileResponse)
			navigation.navigate("Login")
		} catch (error) {
			console.log("Error submitting medical profile: ", error)
		}
	}

	const handleDownloadDocument = async () => {
		console.log("starting to download the consent form")

		try {
			const res = await dispatch(getDiseaseDocuments({ kind: "consent_form", disease })).unwrap()
			console.log(res)
			// TODO might need to handle downloading multiple documents in the future
			const { file, filename } = res[0]
			await dispatch(downloadDocument({ filename, fileUrl: file }))
			setHasViewedDocument(true)
		} catch (error) {
			console.log("error downloading document: ", error)
		}

		console.log("downloading consent form")
	}
	console.log("med profile loading: ", medProfileLoading)
	console.log("document loading: ", documentStateLoading)
	dispatch(setLoadingState(false))

	return (
		<SafeAreaView>
			<ScrollView style={styles.container}>
				<LoadingSpinner
					isLoading={medProfileLoading || documentStateLoading} 
					message={"Loading..."} 
				/>
				<MedicalProfile handleSubmit={handleCreateMedicalProfile} userId={userId} shouldShowSubmitButton={false}/>
				<View style={ styles.textContainer }>
					<Text style={styles.title}>{`${t("iAuthorize")} ${medProfile.physicians.map(p => `${p.first_name} ${p.last_name}`).join(", ")} ${t("releaseInfo")}`}</Text>
					<Text style={styles.subTitle}>{`${medProfile.first_name} ${medProfile.last_name}`}</Text>
					<Text style={styles.text}>{`${t("bornOn")} ${medProfile.date_of_birth}`}</Text>
					<Text style={styles.text}>{`${t("to")} Rohit Aggarwal, MD, MS`}</Text>
					<Text style={styles.text}>{"BST S726"}</Text>
					<Text style={styles.text}>200 Lothrop St</Text>
					<Text style={styles.text}>Pittsburgh, PA, 15261</Text>
					<Text style={styles.text}>Phone: </Text>
					<Text style={styles.text}>Fax: </Text>
					<Text style={styles.subTitle}>{`${t("forPurposeOf")}`}</Text>
					<Text style={styles.text}>{`${t("myositisStudy")}`}</Text>
					<Text style={styles.subTitle}>{`${t("kindsOfRecords")}`}</Text>
					<Text style={styles.text}>{`${t("outpatient")}, ${t("physician")}`}</Text>
					<Text style={styles.text}>{t("datesOfService")}</Text>
					<Text style={styles.text}>{t("infoToBeReleased")}</Text>
					<Text style={styles.text}>{t("consults")}</Text>
					<Text style={styles.text}>{`${t("labReports")} (CPK, Adolase, CBC, Myositis antibodies, LFT, BMP)`}</Text>
					<Text style={styles.text}>{`${t("medHistory")} & ${t("physicalExam")}`}</Text>
				</View>
				<ConsentForm 
					handleSignatureConfirmCallback={(signature: string) => dispatch(setMedicalReleaseSignature(signature))}
					handleDownloadDocument={handleDownloadDocument}
					existingSignature={medProfile.medical_release_signature}
					hasViewedConsentDocument={hasViewedDocument}
				/>

				{/* <AuthFooterComponent/> */}
			</ScrollView>
			<Button
				title={t("Submit") || "Submit"}
				onPress={handleCreateMedicalProfile}
				buttonStyle={styles.button}
				titleStyle={styles.buttonText}
			/>
		</SafeAreaView>
	)
}
