import { Platform, View } from "react-native"
import Spinner from "react-native-loading-spinner-overlay"
import ReactLoading from "react-loading"
import { LoadingSpinnerProps } from "../../../@types/components/loadingSpinner"
import { useEffect, useState } from "react"
import { Text } from "@rneui/themed"
import WebSpinnerComponent from "./WebSpinnerComponent"


export const LoadingSpinner = ({ isLoading, message }: LoadingSpinnerProps) => {

	const [shouldShowSpinner, setShouldShowSpinner] = useState(false)

	useEffect(() => {
		setShouldShowSpinner(isLoading)
	}, [isLoading])

	if (Platform.OS == "web") {
		return (
			<WebSpinnerComponent shouldShowSpinner={shouldShowSpinner}/>
		)
	} else {
		return (
			<Spinner
				visible={shouldShowSpinner}
				textContent={message}
				textStyle={{color: "white"}}
			/>
		)
	}
}