import * as Linking from "expo-linking"
import { Text } from "@rneui/themed"
import { DiseaseIntroductionText } from "../../../@types/redux/disease"
import { GestureResponderEvent, View, Platform, Dimensions } from "react-native"
import { WebView } from "react-native-webview"
import { VideoComponentProps } from "../../../@types/components/util"
import {styles} from "./styles"

export enum TextTypes {
    HEADER = "header",
    SUBHEADER = "subheader",
    BODY = "body",
    LINK = "link",
    YOUTUBE_VIDEO = "youtube_video"
}
const deviceWidth = Dimensions.get("window").width

// h2 === sections TEST
// vertical scroll view inside horizontal scroll view TEST
// split out making pagination dots into its own component (will use this component for questionnaire paging)

export const createPageSections = (introElements: JSX.Element[] | undefined) => {
	const sections: JSX.Element[][] = []
	let section: JSX.Element[] = []

	introElements?.map((nativeElement, index, introElementsArray) => {
		if (nativeElement.props.h2 || (index + 1 === introElementsArray.length)) {
			if (section.length > 0) {
				if (index + 1 === introElementsArray.length) {
					section.push(nativeElement)
				}
				sections.push(section)
			}
			section = []
			section.push(nativeElement)
		} else {
			section.push(nativeElement)
		}
	})
	return sections
}


export const makeNativeElement = (textObj: DiseaseIntroductionText) => {
	const link = textObj.link ? textObj.link : ""
	// const { height, width } = useWindowDimensions()
	const num = textObj.order_in_introduction

	const handleLinkClick = (event: GestureResponderEvent, link: string) => {
		Linking.openURL(link)
	}

	switch (textObj.text_type) {
	case TextTypes.HEADER:
		return <Text key={num} h2 h2Style={{fontSize: 24}} style={styles.header}>{textObj.text}</Text>

	case TextTypes.SUBHEADER:
		return <Text key={num} h3 bold h3Style={{fontSize: 16, color: "#5a5a5a"}} style={styles.subheader}>{textObj.text}</Text>

	case TextTypes.BODY:
		if (textObj.text === "A Global Registry of Patients with Myositis" || textObj.text === "A study by the University of Pittsburgh, USA") {
			return <Text style={{textAlign: "center", marginTop: 50, color: "gray"}}>{textObj.text}</Text>
		}
		return <Text key={num} style={styles.bodyText}>{textObj.text}</Text>

	case TextTypes.LINK:
		return <Text key={num} style={styles.link} hyperlink onPress={(event) => handleLinkClick(event, link)}>{textObj.text}</Text>

	case TextTypes.YOUTUBE_VIDEO:
		return (
		// style={{width: width-20, height: height/2, alignSelf: "center"}}
			<View key={textObj.order_in_introduction} style={styles.video}>
				<VideoComponent link={link}/>
			</View>
		)
	default:
		return <Text>THIS IS DEFAULT</Text>
	}
}

const VideoComponent = ({ link }: VideoComponentProps) => {

	if (Platform.OS == "web") {
		return (
			<iframe 
				width="560" 
				height="315" 
				src={link} 
				title="YouTube video player" 
				allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share; allowfullscreen">
			</iframe>
		)
	} else {
		return (
			<WebView
				style={{flex: 1, width: deviceWidth}}
				javaScriptEnabled
				source={{uri: link}}
			/>
		)
	}
}


