import { Button } from "@rneui/themed"
import {
	Text,
	View,
	StyleSheet,
	TouchableOpacity,
	Modal,
	ScrollView,
} from "react-native"
import { Entypo } from "@expo/vector-icons"
import { QuestionaireResultProps } from "../../../@types/components/questionaireResult"
import { styles } from "./styles"
import { useTheme } from "@rneui/themed"
import { useState } from "react"
import { useNavigation } from "@react-navigation/native"
import { DiseasePicker } from "../DiseasePicker/DiseasePicker"

export const QuestionaireResult = (props: QuestionaireResultProps) => {
	const { onPressCallback, isValid, whyFailed, shouldShowSelectDisease } = props
	const { theme } = useTheme()
	const navigation = useNavigation()

	if (isValid) {
		return (
			<ScrollView contentContainerStyle={[styles.container, { backgroundColor: theme.colors.white }]}>
				<Entypo
					style={styles.emoji}
					name="emoji-happy"
					size={150}
					color={theme.colors.primary}
				/>
				<Text style={styles.isValidText}>
          Great, you have passed the initial screening!
				</Text>
				{shouldShowSelectDisease ? <DiseasePicker/> : null}
				<Button 
					title="Continue" 
					onPress={onPressCallback} 
					style={{marginBottom: 2}}
				/>
			</ScrollView>
		)
	} else {
		const [modalVisible, setModalVisible] = useState(false)

		const handleFeedback = () => {
			setModalVisible(true)
		}

		return (
			<View style={styles1.container}>
				<Text style={styles1.appName}>My Pacer</Text>
				<Text style={styles1.primaryText}>
          Sorry, you&apos;re not eligible.
				</Text>
				<Text style={styles1.secondaryText}>
          We reviewed your details and based on the criteria, you do not qualify
          for this study. We appreciate your interest and hope you&apos;ll
          consider future opportunities.
				</Text>
				<TouchableOpacity onPress={handleFeedback}>
					<Text style={[styles1.feedbackLink, { color: theme.colors.secondary }]}>
            Why Was I Ineligible?
					</Text>
				</TouchableOpacity>
				<TouchableOpacity onPress={() => navigation.goBack()}>
					<Text
						style={[
							styles1.goBackButton,
							{ backgroundColor: theme.colors.primary },
						]}
					>
            Go Back
					</Text>
				</TouchableOpacity>

				<Modal
					animationType="slide"
					transparent={true}
					visible={modalVisible}
					onRequestClose={() => setModalVisible(false)}
				>
					<View style={styles1.overlay}>
						<View style={styles1.modalView}>
							<ScrollView>
								<Text style={styles1.modalTitle}>
                  Reasons for Ineligibility:
								</Text>
								{whyFailed?.map((fullMessage, index) => {
									const question = fullMessage["question"]
									const reasons = fullMessage["reasons"] // Reconstructing the action with "You selected"

									return (
										<View key={index} style={styles1.reasonCard}>
											<Text style={styles1.reasonText}>{question}</Text>
											{reasons.map((reason, index) => {
												return (
													<Text
														key={index}
														style={[
															styles1.actionText,
															{
																color: theme.colors.primary,
																marginBottom:
                                  reasons.length !== reasons.length - 1 ? 5 : 0, // Apply margin between text, not for the last reason
															},
														]}
													>
														{reason}
													</Text>
												)
											})}
										</View>
									)
								})}
							</ScrollView>
							<Button
								title="Understood"
								onPress={() => setModalVisible(false)}
							/>
						</View>
					</View>
				</Modal>
			</View>
		)
	}
}
const styles1 = StyleSheet.create({
	container: {
		flex: 1,
		padding: 20,
		justifyContent: "center",
		alignItems: "center",
	},
	appName: {
		fontSize: 24,
		fontWeight: "bold",
		marginBottom: 20,
	},
	image: {
		width: 100,
		height: 100,
		marginBottom: 20,
	},
	primaryText: {
		fontSize: 20,
		fontWeight: "bold",
		textAlign: "center",
		marginBottom: 15,
	},
	secondaryText: {
		fontSize: 16,
		textAlign: "center",
		marginBottom: 20,
	},
	feedbackLink: {
		marginBottom: 20,
	},
	goBackButton: {
		fontSize: 18,
		color: "white",
		padding: 10,
		borderRadius: 5,
	},
	overlay: {
		flex: 1,
		backgroundColor: "rgba(0,0,0,0.7)",
		justifyContent: "center",
		alignItems: "center",
	},
	modalView: {
		width: "85%",
		maxHeight: "90%",
		backgroundColor: "white",
		borderRadius: 15,
		padding: 25,
		alignItems: "center",
		justifyContent: "space-between",
		shadowColor: "#000",
		shadowOffset: {
			width: 0,
			height: 2,
		},
		shadowOpacity: 0.3,
		shadowRadius: 5,
		elevation: 5,
	},
	modalTitle: {
		fontSize: 20,
		fontWeight: "bold",
		color: "#2C3E50", // Dark blue for emphasis
		marginBottom: 25,
	},
	reasonText: {
		fontSize: 16,
		flexWrap: "wrap", // Allow text wrapping if it's too long
		flex: 1, // Allow the text to take the available width
	},
	reasonContainer: {
		flexDirection: "row",
		alignItems: "center",
		marginBottom: 12,
	},
	reasonCard: {
		backgroundColor: "#F7F9FC", // A light grayish-blue background for the card
		padding: 15,
		borderRadius: 10,
		marginBottom: 10, // Space between each card
		width: "100%",
		alignItems: "flex-start", // Align the content to the start
	},
	actionText: {
		fontSize: 16,
		fontWeight: "bold", // Make it bold to stand out
		flexWrap: "wrap",
		flex: 1,
	},
})
