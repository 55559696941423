import { AnswerType, AssessmentAnswerState, QuestionaireAnswerState } from "../../@types/redux/Answer"
import { FollowUpQuestion, QuestionType } from "../../@types/redux/question"
import { checkAnswerIsValid, checkQuestionHasAnswer } from "../screens/util"
import * as _ from "lodash"
import { QuestionaireType } from "../../@types/redux/questionaire"
import { BasePath } from "../../@types/general"
import { Assessment } from "../../@types/redux/assessment"


export const getQuestionAnswerCount = (question: QuestionType, questionAnswer: AnswerType | undefined): number => {
	if (question && !questionAnswer) return 0
	
	const answerIsValid = checkAnswerIsValid(question.question_type, questionAnswer)
	if (!answerIsValid) return 0

	if (question.follow_up_questions) { // if there are folow up questions we need to check follow up questions answers
		// if current answer is an accepted answer, then check the follow up questions answers
		const acceptedChoices = question.accepted_choices.map(choice => choice?.choice?.id)
		const isInMulti = _.intersection(acceptedChoices, questionAnswer?.select_multiple_content).length > 0
		const isSelectSingle = acceptedChoices.includes(questionAnswer?.select_one_content || -1)
		const wasAnsweredCorrectly = isInMulti || isSelectSingle

		if (wasAnsweredCorrectly) { // this means the follow up questions were displayed, and we need to make sure the user submitted answers for them
			const followUpQestionsAnswered = question.follow_up_questions.map(q => {
				return checkQuestionHasAnswer(q.child_question, questionAnswer?.follow_up_answers?.[q.child_question.id])
			})
			return followUpQestionsAnswered.map(item => item == true ? 1 : 0).reduce<number>((total, current) => {
				return total + current
			}, 0)
		}
	}
	// if there are no follow up questions, then base case has already handled the false case and we can return 1
	return 1
}

export const makeRootPath = (assessmentIndex: number | undefined, questionaireIndex: number): string => {
	
	if (!(typeof assessmentIndex === "undefined")) {
		return `[${assessmentIndex}][${questionaireIndex}]`
	} else {
		return `[${questionaireIndex}]`
	}
}


const getVisibleFollowUpQuestionPaths = (question: QuestionType, answers: AnswerType[], path: string): string[] => {
	/**
	 * follow up questions are visible when the parent questions answer is the triggering choice for the follow up questions.
	 * returns a string of paths, one path for each visible question found
	 */
	const paths: string[] = [path]

	const answer: AnswerType | undefined = _.get(answers, path)
	
	const visibleFollowUpQuestions: FollowUpQuestion[] = question.follow_up_questions?.filter(followUp => {
		if (
			followUp.triggering_choice.includes(answer?.select_one_content || -1)
			||
			followUp.triggering_choice.some(choice => answer?.select_multiple_content?.includes(choice))
		) {
			return true
		}
	})
	const visibleExtraQuestions: FollowUpQuestion[][] | undefined = question.extra_questions?.filter(extraQuestionsArr => {
		
		return extraQuestionsArr.filter(extraQuestion => {
			if (
				extraQuestion.triggering_choice.includes(answer?.select_one_content || -1)
				||
				extraQuestion.triggering_choice.some(choice => answer?.select_multiple_content?.includes(choice))
			) {
				return true
			}
		}).length
	})
	// console.log("visible extra questions: ", visibleExtraQuestions)
	const extraQuestionPaths = visibleExtraQuestions?.flatMap((extraQuestionsArr, outerIndex) => {
		return extraQuestionsArr.flatMap((extraQuestion, innerIndex) => {
			return getVisibleFollowUpQuestionPaths(extraQuestion.child_question, answers, `${path}.extra_answers[${outerIndex}][${innerIndex}]`)
		})
	})

	const followUpPaths = visibleFollowUpQuestions?.flatMap((followUp, index) => getVisibleFollowUpQuestionPaths(followUp.child_question, answers, `${path}.follow_up_answers[${index}]`))
	const visibleChildrenPaths = [...followUpPaths, ...extraQuestionPaths || []]
	paths.push(...visibleChildrenPaths) 
	
	return paths
}


export const getVisibleQuestions = (questionaire: QuestionaireType, answers: AnswerType[]): string[] => {
	return questionaire.questions.flatMap((question, index) => getVisibleFollowUpQuestionPaths(question, answers, `[${index}]`))
}


export const sleep = (ms: number) => new Promise( res => setTimeout(res, ms))


export const getQuestionaireCompletionStatus = (questionaire: QuestionaireType, answers: AnswerType[] | undefined): [number, number] => {	
		
	if (!answers) return [0, questionaire.questions.length]
	
	try {
		const visibleQuestions = getVisibleQuestions(questionaire, answers)
		// console.log("number of visible questions: ", visibleQuestions)
		const currentNumberOfAnswers = visibleQuestions.map(path => {
			const val = _.get(answers, path)
			if (val === null || val === undefined) {
				return 0
			} else {
				return 1
			}
		}).reduce((sumSoFar: number, curr: number) => sumSoFar + curr, 0)

		return [currentNumberOfAnswers || 0, visibleQuestions.length]
	} catch (error) {
		console.log("error: ", error)
		return [0, questionaire.questions.length]
	}	
}


export const getAssessmentCompletionStatus = (assessment: Assessment, assessmentAnswers: AnswerType[][]) => {

	const totalQuestionaires = assessment.questionaires.length

	const questionairesFullyComplete = assessment.questionaires.map((questionaire, index) => {
		try {
			const status = getQuestionaireCompletionStatus(questionaire, assessmentAnswers[index])
			return status[0] === status[1] ? 1 : 0
		} catch (error) {return 0}
	}).reduce((sumSoFar: number, curr: number) => sumSoFar + curr, 0)
		
	return [questionairesFullyComplete, totalQuestionaires]
}