import { Picker } from "@react-native-picker/picker"
import { Button, Card, Input, Text } from "@rneui/themed"
import { useTranslation } from "react-i18next"
import { createElement, Platform } from "react-native"
import { MedicalProfileProps } from "../../../@types/components/medicalProfile"
import MobileDatePicker from "@react-native-community/datetimepicker"
import { Physician } from "../../../@types/redux/medicalProfile"
import { EMPLOYMENT_STATUS_OPTIONS, ETHNICITY_OPTIONS, GENDER_OPTIONS, RACE_OPTIONS } from "../../../constants/general"
import { useAppDispatch, useAppSelector } from "../../redux/hooks"
import { addPhysician, setDateOfBirth, setEmploymentStatus, setEthnicity, setFirstName, setGender, setHeardAbout, setLastName, setOccupationTitle, setPhoneNumber, setRace } from "../../redux/medicalProfileState"
import { AppState } from "../../redux/store"
import { PhysicianComponent } from "../Physician/Physician"
import { styles } from "./styles"
import { formatDate } from "../../../util"
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import { useState } from "react"


export const MedicalProfile = (props: MedicalProfileProps) => {
	const { handleSubmit, shouldShowSubmitButton } = props

	const { t } = useTranslation(["translation", "screens"])


	const dispatch = useAppDispatch()
	const medProfile = useAppSelector((state: AppState) => state.medicalProfileState.profile)
	const medErrors = useAppSelector((state: AppState) => state.medicalProfileState.errors)

	const genderOptions = t("genderOptions") as unknown as string[]
	const raceOptions = t("raceOptions") as unknown as string[]
	const ethnicityOptions = t("ethnicityOptions") as unknown as string[]
	const employmentStatusOptions = t("employmentStatusOptions") as unknown as string[]

	const [selectedDate, setSelectedDate] = useState(new Date(medProfile.date_of_birth))

	let yearDigits = 0

	const handleDateChange = (date: any) => {
		setSelectedDate(date)
		dispatch(setDateOfBirth(date))
	}

	const handleKeyDown = (event: any) => {
		const value = event.target.value

		if (/\d{1,2}\/\d{1,2}\/\d{1,2}$/.test(value) && !isNaN(event.key)) {
			yearDigits++  // Increment year digit count
		}

		if (yearDigits === 4) {
			const convertedValue = convertToYYYYMMDD(value)
			event.target.value = convertedValue
			yearDigits = 0 // Reset year digit count
		}
	}

	const isMMDDYYYYFormat = (value: any) => {
		console.log("DO I ENTER HERE?")
		return /^\d{1,2}\/\d{1,2}\/\d{4}$/.test(value)
	}

	const convertToYYYYMMDD = (value: any) => {
		const [month, day, year] = value.split("/")
		return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`
	}

	return (
		<Card containerStyle={styles.card}>
			<Card.Title style={styles.cardTitle} h3>{t("medicalInformation")}</Card.Title>
			<Card.Divider/>
			<Input
				style={styles.input}
				label={t("firstName")}
				labelStyle={{ marginBottom: 8, marginTop: 6}}
				value={medProfile.first_name}
				onChangeText={(val) => dispatch(setFirstName(val))}
				errorMessage={medErrors.first_name?.join("\n")}/>
			<Input
				style={styles.input}
				label={t("lastName")}
				labelStyle={{ marginBottom: 8, marginTop: 6}}
				value={medProfile.last_name}
				onChangeText={(val) => dispatch(setLastName(val))}
				errorMessage={medErrors.last_name?.join("\n")}/>
			<Text style={styles.labelText}>{t("gender")}</Text>	
			<Picker
				style={styles.picker}
				prompt={t("gender") || undefined}
				selectedValue={medProfile.gender}
				onValueChange={(val) => dispatch(setGender(val))}>
				{genderOptions.map((option, i) => <Picker.Item key={i} label={option} value={GENDER_OPTIONS[i]}/>)}
			</Picker>
			<Text style={styles.labelText}>{t("dateOfBirth")}</Text>
			{
				Platform.OS == "web" ?
					<DatePicker
						selected={selectedDate}
						onChange={handleDateChange}
						dateFormat="MM/dd/yyyy"
					/>
					// createElement("input", {
					// 	type: "date",
					// 	style: styles.dateInput,
					// 	value: formatDate(medProfile.date_of_birth),
					// 	onInput: (event: any) => {
					// 		console.log(event.target.value)
					// 		const date = new Date(event.target.value)
					// 		// console.log("date: ", date)
					// 		dispatch(setDateOfBirth(date))
					// 	},
					// 	onkeydown: handleKeyDown
					// 	onBlur: (event: any) => {
					// 		console.log(event.target.value)
					// 		const value = event.target.value;
					// 		if (isMMDDYYYYFormat(value)) {
					// 				const correctedValue = convertToYYYYMMDD(value);
					// 				event.target.value = correctedValue; // Set the corrected value
					// 				const date = new Date(correctedValue);
					// 				dispatch(setDateOfBirth(date));
					// 		}
					// },
					// 	onChange: (event: any) => {
					// 		if (event.target.validity.valid) {
					// 			console.log(event.target.value)
					// 			const date = new Date(event.target.value)
					// 			dispatch(setDateOfBirth(date))
					// 		}
					// 	}
					// })
					:
					<MobileDatePicker
						style={styles.dateInput}
						value={new Date(medProfile.date_of_birth) || new Date()}
						mode="date"
						onChange={(event) => {
							const date = new Date(event.nativeEvent.timestamp || 0)
							dispatch(setDateOfBirth(date))
						}}
					/>
			}
			<Text style={styles.labelText}>{t("ethnicity")}</Text>
			<Picker
				style={styles.picker}
				selectedValue={medProfile.ethnicity}
				onValueChange={(val) => dispatch(setEthnicity(val))}>
				{ethnicityOptions.map((option, i) => <Picker.Item key={i} label={option} value={ETHNICITY_OPTIONS[i]}/>)}
			</Picker>

			<Text style={styles.labelText}>{t("race")}</Text>
			<Picker
				style={styles.picker}
				selectedValue={medProfile.race}
				onValueChange={(val) => dispatch(setRace(val))}>
				{raceOptions.map((option, i) => <Picker.Item key={i} label={option} value={RACE_OPTIONS[i]}/>)}
			</Picker>
				
			<Text style={styles.labelText}>{t("employmentStatus")}</Text>
			<Picker 
				style={styles.picker}
				selectedValue={medProfile.employment_status}
				onValueChange={(val) => dispatch(setEmploymentStatus(val))}>
				{employmentStatusOptions.map((option, i) => <Picker.Item key={i} label={option} value={EMPLOYMENT_STATUS_OPTIONS[i]}/>)}
			</Picker>

			<Input
				style={styles.input}
				label={t("phoneNumber")}
				labelStyle={{ marginBottom: 8, marginTop: 6}}
				value={medProfile.phone_number}
				onChangeText={(val) => dispatch(setPhoneNumber(val))}
				errorMessage={medErrors.phone_number?.join("\n")}/>

			<Input
				style={styles.input}
				label={t("occupationTitle")}
				labelStyle={{ marginBottom: 8, marginTop: 6}}
				value={medProfile.occupation_title}
				onChangeText={(val) => dispatch(setOccupationTitle(val))}
				errorMessage={medErrors.occupation_title?.join("\n")}/>
			<Input
				style={styles.input}
				label={t("whereHearAboutStudy")}
				labelStyle={{ marginBottom: 8, marginTop: 6}}
				value={medProfile.heard_about}
				onChangeText={(val) => dispatch(setHeardAbout(val))}
				errorMessage={medErrors.heard_about?.join("\n")}/>
			<Card.Divider/>
			{medProfile.physicians?.map((physician: Physician, i: number) => <PhysicianComponent key={i} physician={physician} index={i}/>)}
			<Button
				title={t("addPhysician") || "Add Another Physician"}
				onPress={() => dispatch(addPhysician())}
				buttonStyle={styles.button}
				titleStyle={styles.buttonText}
			/>
			{shouldShowSubmitButton ?
				<Button
					title={t("Submit") || "Submit"}
					onPress={handleSubmit}
					buttonStyle={styles.button}
					titleStyle={styles.buttonText}
				/> : null}
		</Card>
	)
}
