import { Button } from "@rneui/themed"
import { SafeAreaView } from "react-native"
import { AuthFooterComponent } from "../../../components/AuthFooter/AuthFooter"
import { DiseasePicker } from "../../../components/DiseasePicker/DiseasePicker"
import { LoadingSpinner } from "../../../components/Loading/LoadingSpinner"
import { useAppDispatch, useAppSelector } from "../../../redux/hooks"
import { AppState } from "../../../redux/store"
import {
	getDiseases,
} from "../../../redux/diseaseState"
import { useFocusEffect, useNavigation } from "@react-navigation/native"
import { ChooseDiseaseScreenNavigationProp } from "../../../../@types/navigation/AuthNavigator"
import { useCallback } from "react"
import { styles } from "./styles"
import { useTranslation } from "react-i18next"
import { useTheme } from "@rneui/themed"
import { showToast } from "../../../../toast"

export const ChooseDiseaseScreen = () => {

	const { theme } = useTheme()
	const navigation = useNavigation<ChooseDiseaseScreenNavigationProp>()

	const dispatch = useAppDispatch()

	const selectedDisease = useAppSelector((state: AppState) => state.diseaseState.selected)
	const diseaseLoading = useAppSelector((state: AppState) => state.diseaseState.isLoading)

	const { t } = useTranslation()

	useFocusEffect(
		useCallback(() => {
			let isActive = true

			const fetchDiseaseOptions = async () => {
				await dispatch(getDiseases())
			}

			if (isActive) fetchDiseaseOptions()

			return () => {
				isActive = false
			}
		}, [])
	)

	const handleGoToDiseaseIntroduction = async () => {
		if (selectedDisease) {
			navigation.navigate("DiseaseIntroduction")
		} else {
			const message = t("selectDiseaseToContinue")
			showToast({ message, kind: "failure" })
		}
	}

	return (
		<SafeAreaView style={[styles.chooseDiseaseContainer, {backgroundColor: theme.colors.white}]}>
			<LoadingSpinner isLoading={diseaseLoading} message="Loading"/>
			<DiseasePicker />
			<Button
				title={t("continue") || ""}
				onPress={handleGoToDiseaseIntroduction}
			/>
			<AuthFooterComponent />
		</SafeAreaView>
	)
}
