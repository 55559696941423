import { Text } from "@rneui/themed"
import { View } from "react-native"
import { DocumentList } from "../../components/DocumentComponents/DocumentList/DocumentList"
import { DocumentPicker } from "../../components/DocumentComponents/DocumentPicker/DocumentPicker"
import { useTheme } from "@rneui/themed"



export const OtherScreen = () => {
	const { theme } = useTheme()
	return (
		<View style={{flex: 1, padding: 20, backgroundColor: theme.colors.white}}>
			<DocumentPicker/>
			<DocumentList/>
		</View>
	)
}