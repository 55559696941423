import { useNavigation } from "@react-navigation/native"
import { Button, Text, CheckBox } from "@rneui/themed"
import { SafeAreaView, View } from "react-native"
import { ConsentSummaryScreenNavigationProp } from "../../../../@types/navigation/AuthNavigator"
import { useAppDispatch } from "../../../redux/hooks"
import { getQuestionaire } from "../../../redux/questionaireState"
import { styles } from "./styles"
import { useState } from "react"
import { alertForUncheckedConsentSummary } from "../../util"
import { ScrollView } from "react-native-gesture-handler"
import { useTheme } from "@rneui/themed"
import { Platform } from "react-native"
import { Feather } from "@expo/vector-icons"

export const ConsentSummaryScreen = () => {
	const { theme } = useTheme()
	const [checked, setChecked] = useState(false)
	const toggleCheckbox = () => setChecked(!checked)

	const navigation = useNavigation<ConsentSummaryScreenNavigationProp>()

	const dispatch = useAppDispatch()

	const handleConsentSummarySubmit = () => {
		if (!checked) {
			alertForUncheckedConsentSummary()
		} else {
			dispatch(getQuestionaire("comprehension"))
			navigation.navigate("ComprehensionQuestion")
		}
	}

	return (
		<SafeAreaView
			style={[
				styles.consentSummaryContainer,
				{ backgroundColor: theme.colors.white },
			]}
		>
			<Text
				style={[
					styles.intructionText,
					{
						color: theme.colors.gray,
						backgroundColor: theme.colors.lightPurple,
					},
				]}
			>
        Please carefully read the provided consent summary for our study. Take
        your time to understand it fully and discuss with family or a trusted
        doctor if desired. For any questions, email us at
        mypacerregistry@gmail.com.
			</Text>
			<ScrollView>
				<View style={styles.contentContainer}>
					<Text style={[styles.sectionHeader, { color: theme.colors.header }]}>
            Why is this research being done?
					</Text>
					<Text style={[styles.sectionContent]}>
            Myositis, a rare disease affecting multiple organs and causing
            significant debility, currently has limited treatment options due to
            a scarcity of extensive global research and patient data. Often,
            healthcare providers lack comprehensive understanding from the
            patient&apos;s viewpoint, highlighting a need for improved tools,
            like Patient-Reported Outcome measures, to assess patient
            experiences and daily function. To enhance myositis research and
            disease understanding, we are developing a patient-focused, global
            registry named &quot;Myositis Patient Center Tele-Research&quot; (My
            Pacer Registry). Note: this will be a REMOTE study, requiring no
            in-person or clinic visits from participants.
					</Text>
					<Text style={[styles.sectionHeader, { color: theme.colors.header }]}>
            Who is being asked to join the study?
					</Text>
					<Text style={styles.sectionContent}>
            Adult individuals with diagnosis of any type of myositis. The
            partcipant must be willing to be contacted in the future for
            potential clinical trials or related studies.
					</Text>
					<Text style={[styles.sectionHeader, { color: theme.colors.header }]}>
            What actvities will the partcipants be asked to do?
					</Text>
					<Text style={styles.sectionContent}>
            Upon joining the study, you&apos;ll be asked to complete
            questionnaires about your disease history, demographics, and
            medications, and to sign a release for your medical records. Every 6
            months, you&apos;ll fill out questionnaires about your symptoms,
            functionality, work ability, and medications. You&apos;ll also
            perform a home muscle strength test, like repeatedly standing from a
            chair without using your hands. Initially, using the study app or
            website may take 30-60 minutes, with future sessions taking 15-30
            minutes. If needed, a study doctor might request a secure video call
            to discuss your disease further.{" "}
					</Text>
					<Text style={[styles.sectionHeader, { color: theme.colors.header }]}>
            What are the possible risks, and discomforts of this study?
					</Text>
					<Text style={styles.sectionContent}>
            While online data collection can pose a risk of unintended
            disclosure, a robust data security plan has been designed and
            reviewed by the University of Pittsburgh Information Technology
            department to minimize risks to participants. This involves secure
            data collection and sharing, password protection, separate storage
            of identifiable and research data, and utilizing a secured server
            with comprehensive safety measures.
					</Text>
					<Text style={[styles.sectionHeader, { color: theme.colors.header }]}>
            What are the potential benefits from partcipating in this study?
					</Text>
					<Text style={styles.sectionContent}>
            While there is no direct benefit to participating in this study,
            your involvement could significantly contribute to future
            advancements in myositis knowledge and potentially benefit future
            patients. Participation, including allowing researchers access to
            your medical records, is entirely voluntary, and you have the right
            to withdraw from the study at any time.
					</Text>
				</View>
			</ScrollView>
			{Platform.OS === "web" ? (
				<CheckBox
					checked={checked}
					onPress={toggleCheckbox}
					title="I accept that I have read the consent summary"
					uncheckedIcon={<Feather name="square" size={25} color="gray" />}
					checkedIcon={<Feather name="check-square" size={25} color="green" />}
				/>
			) : (
				<CheckBox
					checked={checked}
					onPress={toggleCheckbox}
					iconType="material-community"
					uncheckedIcon="checkbox-blank-outline"
					checkedIcon="checkbox-marked-outline"
					size={32}
					title="I accept that I have read the consent summary"
					checkedColor="green"
					containerStyle={styles.checkBox}
				/>
			)}
			<Button
				testID="submit"
				title="Submit"
				onPress={handleConsentSummarySubmit}
			>
        Submit
			</Button>
		</SafeAreaView>
	)
}
