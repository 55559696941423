import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit"
import axios from "axios"
import * as _ from "lodash"
import { ErrorResponseData } from "../../@types/axios/error"
import { AnswerType } from "../../@types/redux/Answer"
import { 
	AddExtraAssessmentQuestionaireQuestionPayload,
	Assessment,
	AssessmentState,
	QuestionaireIsDirtyPayload,
	QuestionaireSubmissionStatusPayload,
	RemoveExtraAssessmentQuestionaireQuestionPayload,
	SyncAssessmentQuestionaireReturn
} from "../../@types/redux/assessment"
import { FollowUpQuestion } from "../../@types/redux/question"
import {  QuestionaireType, SyncQuestionaireParams } from "../../@types/redux/questionaire"
import { backendAPIClient } from "../axios/axios"
import { newQuestionaireAnswers } from "./answerState"
import { AppState } from "./store"
import { 
	checkForInternetAccess,
	defaultInitialErrorState,
	sendErrorsToState,
	verifyQuestionsExistForAllQuestionsInQuestionaire
} from "./util"


const internalInitialState: AssessmentState = {
	assessments: [],
	isLoading: false,
	errors: defaultInitialErrorState
}


export const assessmentSlice = createSlice({
	name: "assessmentState",
	initialState: internalInitialState,
	reducers: {
		newAssessmentState: (state, action: PayloadAction<Assessment[]>) => {
			console.log("trying to set these assessments: ", action.payload)
			state.assessments.forEach((assessment, i) => {
				assessment.questionaires.forEach((questionaire, j) => {
					try {
						action.payload[i].questionaires[j]["isDirty"] = questionaire.isDirty
						action.payload[i].questionaires[j]["isValid"] = questionaire.isValid
						action.payload[i].questionaires[j]["submitted"] = questionaire.submitted
					} catch (err) {
						console.log("err merging asessment trees: ", err)
					}
				})
			})
			state.assessments = action.payload
		},
		resetAssessments: () => internalInitialState,
		setSelectedAssessment: (state, action: PayloadAction<Assessment>) => {
			state.selected = action.payload
		},
		setQuestionaireSubmissionStatus: (state, action: PayloadAction<QuestionaireSubmissionStatusPayload>) => {
			const { assessmentIndex, questionaireIndex, submitted } = action.payload
			state.assessments[assessmentIndex].questionaires[questionaireIndex].submitted = submitted
		},
		setQuestionaireIsDirty: (state, action: PayloadAction<QuestionaireIsDirtyPayload>) => {
			const { assessmentIndex, questionaireIndex, isDirty } = action.payload
			state.assessments[assessmentIndex].questionaires[questionaireIndex].isDirty = isDirty
		},
		appendAssessment: (state, action: PayloadAction<Assessment>) => {
			state.assessments.push(action.payload)
		},
		addExtraAssessmentQuestionaireQuestion: (state, action: PayloadAction<AddExtraAssessmentQuestionaireQuestionPayload>) => {
			const {newExtraQuestions, pathToSet, assessmentIndex, questionaireIndex} = action.payload
			const newState = JSON.parse(JSON.stringify(state.assessments))
			const path = `[${assessmentIndex}].questionaires[${questionaireIndex}].questions${pathToSet}`.replaceAll("answers", "questions")
			console.log("here is the path i am trying to set: ", path)
			const extraQuestions: FollowUpQuestion[][] = _.get(newState, path) || []
			extraQuestions.push(newExtraQuestions)
			_.set(newState, path, extraQuestions)
			console.log("new state after adding: ", newState)
			
			state.assessments = newState
		},
		removeExtraAssessmentQuestionaireQuestion: (state, action: PayloadAction<RemoveExtraAssessmentQuestionaireQuestionPayload>) => {
			const { pathToRemove, assessmentIndex, questionaireIndex, indexToRemove } = action.payload
			const newState = JSON.parse(JSON.stringify(state.assessments))
			const path = `[${assessmentIndex}].questionaires[${questionaireIndex}].questions${pathToRemove}`.replaceAll("answers", "questions")
			console.log("here is the path i am trying to remove: ", path)
			const extraQuestions = _.get(newState, path) || []
			extraQuestions.splice(indexToRemove, 1)
			_.set(newState, path, extraQuestions)
			state.assessments = newState
		},
		resetErrors: (state, action: PayloadAction<undefined>) => {
			state.errors = defaultInitialErrorState
		}
	},
	extraReducers: (builder) => {
		builder.addCase(getAssessments.pending, (state, action) => {
			state.isLoading = true
		}),
		builder.addCase(getAssessments.fulfilled, (state, action) => {
			state.isLoading = false
			state.assessments = action.payload
		}),
		builder.addCase(getAssessments.rejected, (state, action) => {
			state.isLoading = false
			sendErrorsToState(state, action)
		}),
		builder.addCase(getQuestionairesForAssessments.pending, (state, action) => {
			state.isLoading = true
		}),
		builder.addCase(getQuestionairesForAssessments.fulfilled, (state, action) => {
			state.isLoading = false
			const questionaires = action.payload
			state.assessments.forEach(assessment => {
				assessment.questionaires = JSON.parse(JSON.stringify(questionaires))
			})
		}),
		builder.addCase(getQuestionairesForAssessments.rejected, (state, action) => {
			state.isLoading = false
		}),
		builder.addCase(syncAssessments.pending, (state, action) => {
			state.isLoading = true
		}),
		builder.addCase(syncAssessments.fulfilled, (state, action) => {
			state.isLoading = false
		}),
		builder.addCase(syncAssessments.rejected, (state, action) => {
			state.isLoading = false
		}),
		builder.addCase(syncAssessmentQuestionaire.pending, (state, action) => {
			state.isLoading = true
		}),
		builder.addCase(syncAssessmentQuestionaire.fulfilled, (state, action) => {
			state.isLoading = false
			// const assessment = state.assessments.find(assessment => assessment.id === action.payload.assessmentId)
			// const foundQuestionaire = assessment?.questionaires.find(questionaire => questionaire.id === action.payload.id)
			// return foundQuestionaire
		}),
		builder.addCase(syncAssessmentQuestionaire.rejected, (state, action) => {
			state.isLoading = false
		})
	}
})

export const getAssessments = createAsyncThunk<
Assessment[],
number,
{
	state: AppState,
	rejectValue: ErrorResponseData
}
>(
	"getAssessments",
	async (diseaseId, { rejectWithValue, dispatch }): Promise<Array<Assessment>> => {

		await checkForInternetAccess()
		dispatch(resetErrors())

		try {
			const assessmentsUrl = `api/v1/assessments/?filter_by_user=true&disease_pk=${diseaseId}`
			const response = await backendAPIClient.get(assessmentsUrl)
			console.log("get assessments response: ", response)
			return response.data
		} catch (error) {
			if (axios.isAxiosError(error)) {
				throw rejectWithValue(error.response?.data)
			} else {
				throw error
			}
		}
	}
)

export const getQuestionairesForAssessments = createAsyncThunk<
QuestionaireType[],
number,
{
	state: AppState,
	rejectValue: ErrorResponseData
}
>(
	"getquestionairesForAssessments",
	async (disease_pk, { rejectWithValue, dispatch }): Promise<Array<QuestionaireType>> => {

		await checkForInternetAccess()
		dispatch(resetErrors())

		try {
			const questionairesUrl = `api/v1/questionaires/?disease_pk=${disease_pk}&for_assessments=true`
			const response = await backendAPIClient.get(questionairesUrl)
			return response.data
		} catch (error) {
			if (axios.isAxiosError(error)) {
				throw rejectWithValue(error.response?.data)
			} else {
				throw error
			}
		}
	}
)

export const syncAssessments = createAsyncThunk<
Assessment[],
number,
{
	state: AppState,
	rejectValue: ErrorResponseData
}
>(
	"syncAssessments",
	async (diseaseId, { rejectWithValue, dispatch, getState }): Promise<Array<Assessment>> => {

		const answers = getState().answerState.assessmentAnswerState

		console.log("doing sync assessments")
		await checkForInternetAccess()
		dispatch(resetErrors())

		try {
			const assessmentsUrl = `api/v1/assessments/?filter_by_user=true&disease_pk=${diseaseId}`
			const response = await backendAPIClient.get(assessmentsUrl)
			console.log("sync assessments response: ", response)
			response.data.forEach((assessment: Assessment, i: number) => {
				assessment.questionaires.forEach((questionaire, j) => {
					let questionaireAnswers: AnswerType[] = []
					if (typeof answers.length !== "undefined") {
						if (typeof answers[i] !== "undefined") {
							if (typeof answers[i][j] !== "undefined") {
								questionaireAnswers = _.cloneDeep(answers[i][j])
								const pathsToDelete = verifyQuestionsExistForAllQuestionsInQuestionaire(questionaireAnswers, questionaire, i, j, false)
								console.log("paths to delete: ", pathsToDelete)
								pathsToDelete.forEach(path => _.unset(questionaireAnswers, path))
								const newAnswers = _.compact(questionaireAnswers)
								console.log("questionaireAnswers: ", questionaireAnswers)
								console.log("new answers: ", newAnswers)
								dispatch(newQuestionaireAnswers({ assessmentIndex: i, questionaireIndex: j, answers: newAnswers }))
							}
						}
					}
				})
			})
			dispatch(newAssessmentState(response.data))
			return response.data
		} catch (error) {
			console.log("err in sync assessments: ", error)
			if (axios.isAxiosError(error)) {
				throw rejectWithValue(error.response?.data)
			} else {
				throw error
			}
		}
	}
)

export const syncAssessmentQuestionaire = createAsyncThunk<
	Promise<void>, 
	SyncQuestionaireParams, 
	{
		state: AppState,
		rejectValue: ErrorResponseData | undefined
	}>(
		"syncAssessmentQuestionaire",
		async ({ assessmentIndex, questionaireIndex }, { rejectWithValue, dispatch, getState }) => {

			await checkForInternetAccess()
			dispatch(resetErrors())

			const answerState = getState().answerState
			const assessmentState = getState().assessmentState
			const answers = answerState.assessmentAnswerState

			const questionaire= assessmentState.assessments[assessmentIndex || 0].questionaires[questionaireIndex]

			try {

				if (assessmentIndex) {
					let questionaireAnswers: AnswerType[] = []
					if (typeof answers.length !== "undefined") {
						if (typeof answers[assessmentIndex] !== "undefined") {
							if (typeof answers[assessmentIndex][questionaireIndex] !== "undefined") {
								questionaireAnswers = [...answers[assessmentIndex][questionaireIndex]]
								const pathsToDelete = verifyQuestionsExistForAllQuestionsInQuestionaire(questionaireAnswers, questionaire, assessmentIndex, questionaireIndex)
								console.log("paths to delete: ", pathsToDelete)
								pathsToDelete.forEach(path => _.unset(questionaireAnswers, path))
								dispatch(newQuestionaireAnswers({ assessmentIndex, questionaireIndex, answers: questionaireAnswers }))
							}
						}
					}
				}
				
			} catch (error) {
				if (axios.isAxiosError(error)) {
					throw rejectWithValue(error.response?.data)
				} else {
					throw error
				}
			}
		
		}
	)

// actions are generated by the createSlice function
export const {
	newAssessmentState,
	resetAssessments,
	setSelectedAssessment,
	setQuestionaireSubmissionStatus,
	setQuestionaireIsDirty,
	resetErrors,
	appendAssessment,
	addExtraAssessmentQuestionaireQuestion,
	removeExtraAssessmentQuestionaireQuestion
} = assessmentSlice.actions

export default assessmentSlice.reducer
