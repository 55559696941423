import { Card, Text, useTheme } from "@rneui/themed"
import { Image, ImageLoadEventData } from "expo-image"
import { useState } from "react"
import { Pressable, View } from "react-native"
import Icon from "react-native-vector-icons/Ionicons"
import { PictureComponentProps } from "../../../@types/components/picture"
import { styles } from "./styles"



export const Picture = (props: PictureComponentProps) => {
	const { picture } = props
	const {theme} = useTheme()
	const [imageSource, setImageSource] = useState<ImageLoadEventData>()
	const [isOpen, setIsOpen] = useState(false)

	const handleImageLoad = (props: ImageLoadEventData) => {
		setImageSource(props)
	}

	const handlePress = () => {
		setIsOpen(!isOpen)
	}

	return (
		<Card containerStyle={{width: "100%", paddingHorizontal: 5}}>
			<Card.Title style={{}}>
				<Pressable style={{flex:1, width: "100%", flexDirection: "row", justifyContent: "space-between", alignItems: "center"}} onPress={handlePress}>
					<Text>
						{picture.title}
					</Text>
					<Icon name={isOpen ? "chevron-up-outline" : "chevron-down-outline"} color={theme.colors.purple}/>
				</Pressable>
			</Card.Title>
			
			{
				isOpen ? (
					<>
						<Card.Divider />
						<View
							style={{
								display: "flex",
								alignItems: "center",
								justifyContent: "center"
							}}
						>
							<Image 
								style={{
									width: imageSource?.source.width, 
									height: imageSource?.source.height, 
									maxWidth: 250, 
									maxHeight: 250
								}}
								source={picture.picture}
								alt={picture.description}
								onLoad={handleImageLoad}
								contentFit="fill"
								transition={1000}
							/>
							<Text>{picture.description}</Text>
						</View>
					</>
				)
					: null
			}
		</Card>
	)
}
