import { StyleSheet } from "react-native"

export const styles = StyleSheet.create({
	container: {
		flex: 1,
	},
	documentsContainer: {
		marginVertical: 20,
	},
	card: {
		marginVertical: 10,
		backgroundColor: "white",
		shadowColor: "#000",
		shadowOffset: { width: 0, height: 1 },
		shadowOpacity: 0.2,
		shadowRadius: 1,
		elevation: 3,
		borderRadius: 8,
		padding: 10,
	},
	cardContent: {
		backgroundColor: "#f0f0f0",
		borderRadius: 8,
		padding: 10,
	},
})
