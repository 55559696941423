import { DiseaseIntroductionComponent } from "../../components/DiseaseIntroduction/DiseaseIntroduction"
import { BasePage } from "../../components/BasePage/BasePage"

export const InstructionsScreen = () => {
	return (
		<BasePage>
			<DiseaseIntroductionComponent/>
		</BasePage>
			
	)
}
