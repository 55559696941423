import { View } from "react-native"
import * as ExpoDocumentPicker from "expo-document-picker"
import { Button, LinearProgress } from "@rneui/themed"
import { useAppDispatch, useAppSelector } from "../../../redux/hooks"
import { AppState } from "../../../redux/store"
import { setSelectedDocument, uploadDocument } from "../../../redux/documentState"
import { Paragraph } from "react-native-paper"
import { useTheme } from "@rneui/themed"
import { styles } from "./styles"


export const DocumentPicker = () => {
	const {theme} = useTheme()
	const documentState = useAppSelector((state: AppState) => state.documentState)
	const dispatch = useAppDispatch()

	const handleSelectDocument = async () => {
		const doc = await ExpoDocumentPicker.getDocumentAsync()
		if (doc.type === "success") dispatch(setSelectedDocument(doc))
	}

	const handleUploadDocument = async () => {
		if (documentState.selectedDocumentToUpload?.name) {
			await dispatch(uploadDocument({ filename: documentState.selectedDocumentToUpload.name }))
		}   
	}
	const progress = (documentState.uploadState[documentState.selectedDocumentToUpload?.name || ""]?.uploadProgress.percentComplete) / 100 || 0

	return (
		<View style={styles.container}>
			<Button 
				title="Select Document"
				onPress={handleSelectDocument}/>

			<Paragraph style={{marginVertical: 20}}>Selected document: {documentState.selectedDocumentToUpload?.name}</Paragraph>
			<LinearProgress
				style={{marginVertical: 10, width: "90%"}}
				value={documentState.uploadState[documentState.selectedDocumentToUpload?.name || ""]?.uploadProgress.percentComplete}
				color={theme.colors.primary}
			/>
			<Button
				title="Upload Document"
				onPress={handleUploadDocument}/>
		</View>
	)
}