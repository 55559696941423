import {
	createDrawerNavigator,
	DrawerContentComponentProps,
} from "@react-navigation/drawer"
import { HomeScreen } from "../screens/Home/Home"
import { SettingsScreen } from "../screens/Settings/Settings"
import { HomeDrawerParamList } from "../../@types/navigation/HomeNavigator"
import { AssessmentsScreen } from "../screens/Assessments/AssessmentsScreen"
import { InstructionsScreen } from "../screens/Instructions/InstructionsScreen"
import { FormsScreen } from "../screens/Forms/FormsScreen"
import { OtherScreen } from "../screens/Other/OtherScreen"
import { QuestionaireScreen } from "../screens/Questionaires/QuestionaireScreen"
import Icon from "react-native-vector-icons/Ionicons"
import {
	DrawerContentScrollView,
	DrawerItemList,
	DrawerItem,
} from "@react-navigation/drawer"
import { useAppDispatch, useAppSelector } from "../redux/hooks"
import { resetAuthState } from "../redux/authState"
import { AppState } from "../redux/store"
import {
	findAssessmentIndexById,
	findAssessmentQuestionaireIndexById,
} from "./util"
import { useToast } from "react-native-toast-notifications"
import { useTheme } from "@rneui/themed"
import { ProfileScreen } from "../screens/Profile/Profile"

export const HomeDrawer = createDrawerNavigator<HomeDrawerParamList>()

const HomeDrawerNavigator = () => {
	const { theme } = useTheme()
	const headerOptions = {
		headerStyle: {
			backgroundColor: theme.colors.primary,
		},
		headerTintColor: theme.colors.white,
	}
	const toast = useToast()
	const dispatch = useAppDispatch()
	const questionaireState = useAppSelector(
		(state: AppState) => state.questionaireState
	)
	const assessmentState = useAppSelector(
		(state: AppState) => state.assessmentState
	)

	const handleLogout = () => {
		dispatch(resetAuthState())
		toast.show("Successfully logged out!", {
			type: "success",
			placement: "bottom",
			duration: 5000,
			animationType: "slide-in",
		})
	}

	const CustomDrawerContent = (props: DrawerContentComponentProps) => {
		return (
			<DrawerContentScrollView {...props}>
				<DrawerItemList {...props} />
				<DrawerItem
					label="Log Out"
					onPress={handleLogout}
					icon={({ focused, size }) => (
						<Icon name="log-out" size={size} color={theme.colors.primary} />
					)}
				/>
			</DrawerContentScrollView>
		)
	}

	return (
		<HomeDrawer.Navigator
			drawerContent={(props) => <CustomDrawerContent {...props} />}
		>
			<HomeDrawer.Screen
				name="Home"
				component={HomeScreen}
				options={{
					drawerIcon: ({ focused, size }) => (
						<Icon name="home" size={size} color={theme.colors.primary} />
					),
					...headerOptions,
				}}
			/>
			<HomeDrawer.Screen
				name="Settings"
				component={SettingsScreen}
				options={{
					drawerIcon: ({ focused, size }) => (
						<Icon name="settings" size={size} color={theme.colors.primary} />
					),
					...headerOptions,
				}}
			/>
			<HomeDrawer.Screen
				name="Instructions"
				component={InstructionsScreen}
				options={{
					drawerIcon: ({ focused, size }) => (
						<Icon
							name="information-circle"
							size={size}
							color={theme.colors.primary}
						/>
					),
					...headerOptions,
				}}
			/>
			<HomeDrawer.Screen
				name="Assessments"
				component={AssessmentsScreen}
				options={{
					drawerIcon: ({ focused, size }) => (
						<Icon name="clipboard" size={size} color={theme.colors.primary} />
					),
					...headerOptions,
				}}
			/>
			<HomeDrawer.Screen
				name="Questionaire"
				component={QuestionaireScreen}
				initialParams={{
					questionaireIndex: findAssessmentQuestionaireIndexById(
						assessmentState.selected?.id,
						questionaireState.selected?.id
					) || 0,
					assessmentIndex: findAssessmentIndexById(
						assessmentState.selected?.id
					),
				}}
				options={({ navigation }) => ({
					drawerIcon: ({ focused, size }) => (
						<Icon
							name="document-text"
							size={size}
							color={theme.colors.primary}
						/>
					),
					drawerItemStyle: {
						display:
              assessmentState.selected && questionaireState.selected
              	? "flex"
              	: "none",
					},
					headerLeft: () => (
						<Icon
							name="arrow-back"
							size={25}
							onPress={() => navigation.navigate("Assessments")}
							color={theme.colors.white}
							style={{ marginLeft: 8 }}
						/>
					),
					...headerOptions,
				})}
			/>

			<HomeDrawer.Screen
				name="Forms"
				component={FormsScreen}
				options={{
					drawerIcon: ({ focused, size }) => (
						<Icon
							name="file-tray-full"
							size={size}
							color={theme.colors.primary}
						/>
					),
					...headerOptions,
				}}
			/>
			<HomeDrawer.Screen
				name="Profile"
				component={ProfileScreen}
				options={{
					drawerIcon: ({ focused, size }) => (
						<Icon name="person" size={size} color={theme.colors.primary} />
					),
					...headerOptions,
				}}
			/>
			<HomeDrawer.Screen
				name="Other"
				component={OtherScreen}
				options={{
					drawerIcon: ({ focused, size }) => (
						<Icon
							name="ellipsis-horizontal-circle-sharp"
							size={size}
							color={theme.colors.primary}
						/>
					),
					...headerOptions,
				}}
			/>
		</HomeDrawer.Navigator>
	)
}

export default HomeDrawerNavigator
