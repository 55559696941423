import { Card } from "@rneui/themed"


export const ExtraQuestionsDivider = () => {
	return (
		<>
			<Card.Divider 
				subHeader="Additional Answers"
				subHeaderStyle={{
					alignSelf: "center",
					fontSize: 20,
					fontWeight: "bold"
				}}
				style={{
					height: 5, 
					backgroundColor: "black",  
					marginTop: 30
				}} />
			<Card.Divider style={{height: 5, backgroundColor: "black", marginTop: 15}} />
		</>
	)
}