import { View, ActivityIndicator, StyleSheet, Text } from "react-native"

interface LoadingSpinnerProps {
  shouldShowSpinner: boolean
}

const WebSpinnerComponent = ({ shouldShowSpinner }: LoadingSpinnerProps) => {
	return shouldShowSpinner ? (
		<View style={[StyleSheet.absoluteFill, styles.spinnerContainer]}>
			<ActivityIndicator size='large' color='#ffffff' />
			<Text style={styles.loadingText}>Loading...</Text>
		</View>
	) : null
}

const styles = StyleSheet.create({
	spinnerContainer: {
		backgroundColor: "rgba(0, 0, 0, 0.5)",
		justifyContent: "center",
		alignItems: "center",
		zIndex: 1
	},
	loadingText: {
		color: "#ffffff",
		marginTop: 10
	},
})

export default WebSpinnerComponent