import { StyleSheet } from "react-native"

export const styles = StyleSheet.create({
	container: {
		padding: 24,
		flex: 1,
		alignItems: "center",
		backgroundColor: "white",
	},
	buttonsContainer: {
		flexDirection: "row",
		justifyContent: "space-between",
		width: "90%",
		marginTop: 10,
		paddingHorizontal: 24,
		// marginBottom: 16
	},
	buttonContainer: {
		flex: 1,
		// marginHorizontal: 8,
		// marginBottom: 250,
	},
	button: {
		// borderRadius: 24
	},
	signatureScreenContainer: {
		flex: 1,
	},
	consentFormText: {
		marginBottom: 48,
	},
	signBelowText: {
		marginBottom: 8,
	},
})
