import { View } from "react-native"
import * as _ from "lodash"
import { QuestionSubcomponentProps } from "../../../../../@types/components/question"
import { ChoiceComponent } from "../../../Choice/Choice"
import { styles } from "../../styles"



export const RadioComponent = (props: QuestionSubcomponentProps) => {

	const {
		id,
		choices,
	} = props

	
	return (
		<View style={styles.questionContainer}>
			<View>
				{
					choices?.map((choice) => {
						return <ChoiceComponent
							key={choice.id}
							text={choice.text}
							parent_question={id}
							{...props}
							id={choice.id}
							canSelectMultiple={false} />
					})
				}
			</View>
		</View>
	)
}