import { FilterCriteria, RecursiveObj } from "./@types/general"


export const filterObject = <T>(obj: RecursiveObj<T>, fc: FilterCriteria): RecursiveObj<T> => {
	return Object.entries(obj).reduce((acc: RecursiveObj<T>, [key,value]) => {
		if (Array.isArray(value)) {
			acc[key] = value.map(ob => filterObject(ob, fc))
		} else {
			if (fc([key, value]))
				acc[key] = value
		}
		return acc
	}, {})
}


export const removeFalseyValues = ([key, value]: Iterable<any>) => {
	if (typeof value === "undefined") return false
	if (((value === "0") || (value === 0)) && !(key == "outer_index_of_extra") &&  !(key == "inner_index_of_extra") && !(key == "integer_content")) return false
	if (value === " ") return false
	if (value === "") return false
	if (!value && !(key == "outer_index_of_extra") &&  !(key == "inner_index_of_extra") && !(key == "integer_content")) return false

	if (typeof value === "object") {
		const isEmptyObject = Object.keys(value).length === 0
		if (isEmptyObject) return false
	}
	return true
}

export const formatDate = (date: Date) => {
	if (!(date instanceof Date)) {
		date = new Date(date)
	}
	const year = date.getFullYear()
	const month = String(date.getMonth() + 1).padStart(2, "0")
	const day = String(date.getDate()).padStart(2, "0")

	return `${year}-${month}-${day}`
}
