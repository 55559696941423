import { StyleSheet } from "react-native"

export const styles = StyleSheet.create({
	input: {
		// margin: 15,
		// height: 50,
		// borderColor: "#d3d3d3",
		// borderBottomWidth: 1,
	},
	text: {
		fontSize: 16,
		lineHeight: 24,
		marginBottom: 10,
	},
	title: {
		fontSize: 20,
		fontWeight: "bold",
		marginBottom: 15,
	},
	subTitle: {
		fontSize: 18,
		marginBottom: 10,
	},
	button: {
		// backgroundColor: "#6C65FF",
		// padding: 10,
		// borderRadius: 5,
		// alignItems: "center",
		// marginVertical: 20,
	},
	buttonText: {
		// color: "#fff",
		// fontSize: 16,
	},
})
