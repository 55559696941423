import { createTheme } from "@rneui/themed"
import { color } from "react-native-reanimated"

declare module "@rneui/themed" {
  export interface Colors {
    // purple is our main theme for the app
    // white is our background color
    // gray is used for instruction text
    // lightpurple is the background for instruction text
    // if a change is ever needed for the main color theme purple/white, just change the light colors/dark colors purple and white values
    purple: string
    gray: string
    lightPurple: string
    header: string
    link: string
  }
}
const colors = {
	blue: "#1a1561",
	tan: "#cca87b",
	white: "white",
	green: "#18bc9c",
	gray: "#2c3e50",
}

export const upittTheme = createTheme({
	components: {
		Text: (props) => ({
			style: {
				fontWeight: props.bold ? "bold" : "normal",
				color: props.hyperlink ? colors.tan : "black",
				textDecorationLine: "none",
				fontFamily: "Verdana",
			},
		}),
		SafeAreaView: () => ({
			style: {
				backgroundColor: "white",
			},
		}),
		Button: () => ({
			color: colors.blue,
			style: {
				borderRadius: 5,
			},
		}),
	},
	lightColors: {
		purple: "#6C65FF",
		gray: "#4A4A4A",
		lightPurple: "#ECF1FF",
		primary: colors.blue,
		secondary: colors.tan,
		header: colors.gray,
		link: colors.green,
		white: colors.white,
	},
	darkColors: {
		purple: "#6C65FF",
		gray: "#4A4A4A",
		lightPurple: "#ECF1FF",
		primary: colors.blue,
		secondary: colors.tan,
		header: colors.gray,
		link: colors.green,
		white: colors.white,
	},
})
