import { useFocusEffect } from "@react-navigation/native"
import { useCallback, useState } from "react"
import { ImageBackground, Platform, ScrollView, View } from "react-native"
import { AssessmentComponent } from "../../components/Assessment/Assessment"
import { LoadingSpinner } from "../../components/Loading/LoadingSpinner"
import { syncAssessments } from "../../redux/assessmentState"
import { useAppDispatch, useAppSelector } from "../../redux/hooks"
import { AppState } from "../../redux/store"
import { styles } from "./styles"
import { useTheme } from "@rneui/themed"
import { Assessment } from "../../../@types/redux/assessment"
import { BasePage } from "../../components/BasePage/BasePage"

export const AssessmentsScreen = () => {
	const { theme } = useTheme()

	const dispatch = useAppDispatch()

	const assessmentState = useAppSelector((state: AppState) => state.assessmentState)
	const diseaseState = useAppSelector((state: AppState) => state.diseaseState)

	useFocusEffect(
		useCallback(() => {
			let isActive = true
      
			const fetchAssessments = async () => {
				if (diseaseState.selected) {
					await dispatch(syncAssessments(diseaseState.selected?.id || 0))
				}
			}
			if (isActive) {
				fetchAssessments()
			}

			return () => {
				isActive = false
			}
		}, [])
	)
	const isCurrentAssessment = (assessment: Assessment) => {
		const currentDate = new Date()
		const startDate = new Date(assessment.start_date)
		const endDate = new Date(assessment.end_date)
		return currentDate >= startDate && currentDate <= endDate
	}

	const currentAssessments = assessmentState.assessments.filter(isCurrentAssessment)

	const nextAssessment = assessmentState.assessments.filter((assessment) => {
		const currentDate = new Date()
		const copyOfCurrentDate = new Date(currentDate)
		const expectedStart = new Date(copyOfCurrentDate.setMonth(copyOfCurrentDate.getMonth()+6))
		const expectedEnd = new Date(copyOfCurrentDate.setMonth(copyOfCurrentDate.getMonth()+7))
		const endDate = new Date(assessment.end_date)
		const startDate = new Date(assessment.start_date)
		return startDate <= expectedStart && endDate <= expectedEnd && startDate > currentDate
	})?.[0]

	const NoAssessment = () => {
		return (
			<p style={{
				fontSize: 16,
				padding: 8,
				textAlign: "center",
				color: theme.colors.gray,
				backgroundColor: theme.colors.lightPurple,
			}}>
				{`You have no curent assessments. You will recieve an email when your next assessment is open. Next assessment opens on: ${nextAssessment.start_date}`}
			</p>
		)
	}

	return (
		<BasePage>
			<LoadingSpinner isLoading={assessmentState.isLoading} message="Loading..." />
			<ScrollView contentContainerStyle={{flex:1, flexGrow: 1}}>
				{currentAssessments.length ?
					currentAssessments.map((assessment, index) => (
						<AssessmentComponent key={assessment.id} assessment={assessment} assessmentIndex={index}/>
					))
					: <NoAssessment />
				}
			</ScrollView>
		</BasePage> 
		
				
	)

}
