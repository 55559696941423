import { useFocusEffect, useNavigation } from "@react-navigation/native"
import { Button } from "@rneui/themed"
import { View, SafeAreaView, Text } from "react-native"
import { DiseaseIntroductionScreenNavigationProp } from "../../../../@types/navigation/AuthNavigator"
import { DiseaseIntroductionComponent } from "../../../components/DiseaseIntroduction/DiseaseIntroduction"
import { AuthFooterComponent } from "../../../components/AuthFooter/AuthFooter"
import { useAppDispatch, useAppSelector } from "../../../redux/hooks"
import { getQuestionaire } from "../../../redux/questionaireState"
import { styles } from "./styles"
import { useTheme } from "@rneui/themed"
import { useCallback, useState } from "react"
import { getDiseases, setSelectedDisease, updateSelectedDiseaseIntroduction } from "../../../redux/diseaseState"
import { LoadingSpinner } from "../../../components/Loading/LoadingSpinner"
import { AppState } from "../../../redux/store"


export const DiseaseIntroductionScreen = () => {
	const { theme } = useTheme()

	const navigation = useNavigation<DiseaseIntroductionScreenNavigationProp>()
	const dispatch = useAppDispatch()

	const diseaseLoading = useAppSelector((state: AppState) => state.diseaseState.isLoading)
	const selectedDisease = useAppSelector((state: AppState) => state.diseaseState.selected)

	const [diseaseFetchErr, setDiseaseFetchErr] = useState(false)

	useFocusEffect(
		useCallback(() => {
			let isActive = true

			const fetchDiseaseOptions = async () => {
				try {
					const options = await dispatch(getDiseases()).unwrap()
					if (!selectedDisease) {
						dispatch(setSelectedDisease(options[0]))
					} else {
						console.log("ABOUT TO DISPATCH UPDATE SELECTED")
						const newSelectedDisease = options.find((disease) => disease.id === selectedDisease.id)
						console.log(newSelectedDisease)
						dispatch(updateSelectedDiseaseIntroduction(newSelectedDisease?.introduction))
					}
				} catch (error) {
					setDiseaseFetchErr(true)
				}
			}

			if (isActive) fetchDiseaseOptions()

			return () => {
				isActive = false
			}
		}, [])
	)

	const handleJoinStudy = () => {
		dispatch(getQuestionaire("eligibility"))
		navigation.navigate("Eligibility")
	}

	if (diseaseFetchErr) {
		return (
			<SafeAreaView style={[styles.diseaseIntroScreenContainer, {backgroundColor: theme.colors.white}]}>
				<Text>Something went wrong, please refresh the app</Text>
			</SafeAreaView>
		)
	}

	return (
		<SafeAreaView style={[styles.diseaseIntroScreenContainer, {backgroundColor: theme.colors.white}]}>
			<LoadingSpinner isLoading={diseaseLoading} message="Loading"/>
			<DiseaseIntroductionComponent/>
			<Button
				title="Join Study"
				onPress={handleJoinStudy}
				testID="join"
			/>
			<AuthFooterComponent/>
		</SafeAreaView>
	)
}
