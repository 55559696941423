import React from "react"
import { View, Text, Image, StyleSheet } from "react-native"
import { useTheme } from "@rneui/themed"

export const CustomHeaderComponent = () => {
	const { theme } = useTheme()
	return (
		<View
			style={[
				styles.headerContainer,
				{ backgroundColor: theme.colors.primary },
			]}
		>
			<Image
				source={require("../../../assets/images/mypacerlogo200.png")}
				style={styles.logo}
			/>
			<Text style={[styles.title, { color: theme.colors.white }]}>
        My Pacer
			</Text>
		</View>
	)
}

const styles = StyleSheet.create({
	headerContainer: {
		flexDirection: "row",
		alignItems: "center",
		justifyContent: "space-between",
		padding: 10,
		borderBottomWidth: 1,
		borderBottomColor: "#ddd",
	},
	logo: {
		width: 60,
		height: 60,
	},
	title: {
		fontSize: 20,
		fontWeight: "bold",
	},
})
