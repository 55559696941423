import { useRef, useState } from "react"
import {
	ScrollView,
	SafeAreaView,
	Dimensions,
	NativeSyntheticEvent,
	NativeScrollEvent,
	Image,
	View,
	Text,
} from "react-native"
import { useAppSelector } from "../../redux/hooks"
import { AppState } from "../../redux/store"
import { makeNativeElement, createPageSections } from "./util"
import PaginationDots from "./PaginationDots"
import { styles } from "./styles"
import { useTheme } from "@rneui/themed"

export const DiseaseIntroductionComponent = () => {
	const [currentPage, setSliderState] = useState<number>(0)
	const [pageIndex, setPageIndex] = useState<number>(0)
	const { width } = Dimensions.get("window")
	const { theme } = useTheme()

	// Create a reference to the ScrollView
	const scrollViewRef = useRef<ScrollView>(null)

	const setSliderPage = (event: NativeSyntheticEvent<NativeScrollEvent>) => {
		const { x } = event.nativeEvent.contentOffset
		const indexOfNextScreen = Math.floor(x / width)
		if (indexOfNextScreen !== currentPage) {
			setSliderState(indexOfNextScreen)
		}
	}

	const navigateToPage = (index: number) => {
		console.log("index", index)
		// Scroll to the page at the given index
		scrollViewRef.current?.scrollTo({ x: index * width, animated: true })
		setPageIndex(index)
	}

	const diseaseState = useAppSelector((state: AppState) => state.diseaseState)

	const introElements = diseaseState.selected?.introduction?.disease_introduction_texts.map((textObj) => makeNativeElement(textObj))

	// Create new static section
	const newLogoSection = (
		<View style={{alignItems: "center"}}>
			<Image
				source={require("../../../assets/images/mypacerlogo200.png")}
				style={{width: 150, height: 150}}
			/>
		</View>

	)

	const pageSections = createPageSections(introElements)
	if (pageSections.length > 0) {
		pageSections[0]?.unshift(newLogoSection)
	}

	return (
		<SafeAreaView
			style={[styles.container, { backgroundColor: theme.colors.white }]}
		>
			<ScrollView
				horizontal={true}
				scrollEventThrottle={16}
				pagingEnabled={true}
				showsHorizontalScrollIndicator={false}
				onScroll={(event: NativeSyntheticEvent<NativeScrollEvent>) => {
					setSliderPage(event)
				}}
				ref={scrollViewRef} // Set the reference to the ScrollView
			>
				{pageSections.map((section, index) => {
					return (
						<ScrollView
							testID="diseaseIntroComponent"
							style={{ width, flex: 1 }}
							key={index}
						>
							{section}
						</ScrollView>
					)
				})}
			</ScrollView>
			<Text style={{textAlign: "center", color: theme.colors.primary}}>Swipe for more information</Text>
			<PaginationDots
				pageSections={pageSections}
				pageIndex={currentPage}
				onDotClick={navigateToPage}
			/>
		</SafeAreaView>
	)
}
