import { Input, Text, Slider } from "@rneui/themed"
import { useEffect, useRef, useState } from "react"
import { View } from "react-native"
import { FontAwesome } from "@expo/vector-icons"
import { QuestionSubcomponentProps } from "../../../../../@types/components/question"
import { SetAnswerOfflineParams } from "../../../../../@types/redux/Answer"
import { setAnswerOffline } from "../../../../redux/answerState"
import { setQuestionaireIsDirty } from "../../../../redux/assessmentState"
import { useAppDispatch } from "../../../../redux/hooks"
import { styles } from "../../styles"
import { useTheme } from "@rneui/themed"

type InputWrapperProps = {
  is_slider: boolean
  sliderValue: React.MutableRefObject<number>
  handleSliderComplete: (input: number) => void
  upper_limit?: number
  lower_limit?: number
  theme: any
  integerAnswer: string
  validateIntegerAnswer: (input: string) => void
  integerInputError?: string
  handleSetAnswerContent: () => void
  unitType?: string
}

const InputWrapper: React.FC<InputWrapperProps> = ({
	is_slider,
	sliderValue,
	handleSliderComplete,
	upper_limit,
	lower_limit,
	theme,
	integerAnswer,
	validateIntegerAnswer,
	integerInputError,
	handleSetAnswerContent,
	unitType,
}) => {
	if (is_slider) {
		return (
			<View>
				<Text>Value: {integerAnswer}</Text>
				<Slider
					value={sliderValue.current}
					onValueChange={(input) => (sliderValue.current = input)}
					onSlidingComplete={handleSliderComplete}
					maximumValue={upper_limit}
					minimumValue={lower_limit}
					step={1}
					animateTransitions={true}
					animationType="spring"
					allowTouchTrack
					trackStyle={{ height: 5, backgroundColor: "transparent" }}
					thumbStyle={{
						height: 20,
						width: 20,
						backgroundColor: "transparent",
					}}
					thumbProps={{
						children: (
							<FontAwesome
								name="circle"
								type="font-awesome"
								size={20}
								color={theme.colors.purple}
							/>
						),
					}}
					minimumTrackTintColor={theme.colors.purple}
					maximumTrackTintColor={theme.colors.lightPurple}
				/>
			</View>
		)
	} else {
		return (
			<View style={{ flexDirection: "row", alignItems: "center" }}>
				<Input
					placeholder="Input Response"
					style={styles.inputInnerContainer}
					value={integerAnswer}
					onChangeText={(input) => validateIntegerAnswer(input)}
					onBlur={() => !integerInputError && handleSetAnswerContent()}
					keyboardType="number-pad"
					errorMessage={integerInputError}
				/>
				{unitType ? <Text>{unitType}</Text> : null}
			</View>
		)
	}
}

export const IntegerComponent = (props: QuestionSubcomponentProps) => {
	const {
		assessmentId,
		assessmentIndex,
		questionaireId,
		questionaireIndex,
		questionaireIsDirty,
		id,
		savedAnswer,
		is_slider,
		lower_limit,
		upper_limit,
		isExtra,
		outerIndexOfExtra,
		innerIndexOfExtra,
		questionPath,
		unit_type,
	} = props

	const { theme } = useTheme()

	const dispatch = useAppDispatch()

	const [integerAnswer, setIntegerAnswer] = useState<string>("")
	const sliderValue = useRef<number>(0)
	const [integerInputError, setIntegerInputError] = useState<
    string | undefined
  >()

	useEffect(() => {
		const saved = Number(savedAnswer?.integer_content)
		if (typeof saved === "number")
			setIntegerAnswer(savedAnswer?.integer_content?.toString() || "0")
	}, [savedAnswer])

	useEffect(() => {
		if (is_slider) {
			handleSliderComplete(sliderValue.current)
		}
	}, [assessmentId, questionaireId])

	const validateIntegerAnswer = (inputString: string) => {
		const inputNum: number | typeof NaN = Number(inputString)

		// Handle empty input
		if (!inputString) {
			setIntegerAnswer("")
			setIntegerInputError("Input is required")
			return
		}

		// Handle invalid numbers
		if (isNaN(inputNum)) {
			setIntegerInputError("Please submit a valid number")
			return
		}

		// Handle out-of-range numbers
		if (
			typeof lower_limit !== "undefined" &&
      typeof upper_limit !== "undefined"
		) {
			if (
				(lower_limit && lower_limit !== 0) ||
        (upper_limit && upper_limit !== 0)
			) {
				if (inputNum < lower_limit || inputNum > upper_limit) {
					setIntegerInputError(
						`Please enter a number between ${lower_limit} and ${upper_limit}`
					)
					return
				}
			}
		}

		// If we made it this far, the input is valid
		setIntegerAnswer(inputString)
		setIntegerInputError(undefined)
	}

	const handleSetAnswerContent = () => {
		const answerData: SetAnswerOfflineParams = {
			answer: {
				question: id,
				assessment: assessmentId,
				questionaire: questionaireId,
				integer_content: is_slider
					? sliderValue.current
					: parseInt(integerAnswer),
			},
			questionPath,
			assessmentIndex,
			questionaireIndex,
		}

		if (
			isExtra &&
      !(typeof outerIndexOfExtra === "undefined") &&
      !(typeof innerIndexOfExtra === "undefined")
		) {
			answerData.answer["outer_index_of_extra"] = outerIndexOfExtra
			answerData.answer["inner_index_of_extra"] = innerIndexOfExtra
		}

		dispatch(setAnswerOffline(answerData))

		if (!questionaireIsDirty && !(typeof assessmentIndex === "undefined")) {
			dispatch(
				setQuestionaireIsDirty({
					assessmentIndex,
					questionaireIndex,
					isDirty: true,
				})
			)
		}
	}

	const handleSliderComplete = (input: number) => {
		setIntegerAnswer(input.toString())
		handleSetAnswerContent()
	}

	return (
		<View style={styles.questionContainer}>
			<InputWrapper
				is_slider={is_slider}
				sliderValue={sliderValue}
				handleSliderComplete={handleSliderComplete}
				upper_limit={upper_limit}
				lower_limit={lower_limit}
				theme={theme}
				integerAnswer={integerAnswer}
				validateIntegerAnswer={validateIntegerAnswer}
				integerInputError={integerInputError}
				handleSetAnswerContent={handleSetAnswerContent}
				unitType={unit_type}
			/>
		</View>
	)
}
