import store from "../redux/store"

export const findAssessmentIndexById = (assessmentId: number | undefined): number | undefined => {
	const assessmentState = store.getState().assessmentState
	return assessmentState.assessments.findIndex(assessment => assessment.id == assessmentId)
}

export const findAssessmentQuestionaireIndexById = (assessmentId: number | undefined, questionaireId: number | undefined): number | undefined => {
	const assessmentState = store.getState().assessmentState
	const assessment = assessmentState.assessments.find(assessment => assessment.id == assessmentId)
	const questionaireIndex = assessment?.questionaires.findIndex(questionaire => questionaire.id == questionaireId)
	return questionaireIndex == -1 ? undefined : questionaireIndex
}