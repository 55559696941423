import { Text } from "@rneui/themed"
import _ from "lodash"
import { GetSavedAnswerParams, QuestionProps } from "../../../@types/components/question"
import { AnswerType } from "../../../@types/redux/Answer"
import { QuestionTypeChoices } from "../../../@types/redux/question"
import { makeRootPath } from "../util"
import { DateComponent } from "./Subcomponents/Date/Date"
import { FloatComponent } from "./Subcomponents/Float/Float"
import { IntegerComponent } from "./Subcomponents/Integer/Integer"
import { RadioComponent } from "./Subcomponents/Radio/Radio"
import { SelectMultipleComponent } from "./Subcomponents/SelectMultiple/SelectMultiple"
import { TextComponent } from "./Subcomponents/Text/Text"


export const getSubcomponent = (question_type: QuestionTypeChoices, savedAnswer: AnswerType | undefined, props: QuestionProps) => {
	switch (question_type) {
	case "select_multiple":
		return <SelectMultipleComponent savedAnswer={savedAnswer} {...props}/>
	case "radio":
		return <RadioComponent savedAnswer={savedAnswer} {...props}/>
    
	case "text":
		return <TextComponent savedAnswer={savedAnswer} {...props}/>
    
	case "float":
		return <FloatComponent savedAnswer={savedAnswer} {...props}/>
    
	case "integer":
		return <IntegerComponent savedAnswer={savedAnswer} {...props}/>
    
	case "date":
		return <DateComponent savedAnswer={savedAnswer} {...props}/>
	default:
		return <Text>you forgot to handle a case</Text>
	}
}



export const getSavedAnswer = (params: GetSavedAnswerParams): AnswerType | undefined => {
	const {
		assessmentIndex, 
		questionaireIndex,
		questionPath,
		answerState,
		shouldFallbackToPreviousAnswer
	} = params

	const getAnswerPath = (assessmentIndex: number | undefined, removeFirstQuestion=false): string => {
		const root = makeRootPath(assessmentIndex, questionaireIndex).replaceAll(".child_question", "")
		const pathExtension = removeFirstQuestion ? questionPath.replace(/\[\d+\]/, "") : questionPath
		const answerPath = `${root}${pathExtension}`
			.replaceAll("questions", "answers")
			.replaceAll(".child_question", "")
		return answerPath
	}

	const answerPath = getAnswerPath(assessmentIndex)
	
	const recursivelyFindSavedAnswer = (assessmentIndex: number, questionPath: string): AnswerType | undefined => {
		const answer = _.get(existingAnswers, questionPath)
		const shouldRecurse = !answer && shouldFallbackToPreviousAnswer && assessmentIndex && (assessmentIndex > 0)
		if (shouldRecurse) {
			return recursivelyFindSavedAnswer(assessmentIndex-1, questionPath)
		}
		return answer
	}

	const existingAnswers = typeof assessmentIndex === "number" ? answerState.assessmentAnswerState : answerState.questionaireAnswerState
	const answer = _.get(existingAnswers, answerPath)

	if (!answer && shouldFallbackToPreviousAnswer && assessmentIndex && (assessmentIndex > 0)) {
		const initialAnswer = _.get(answerState, getAnswerPath(0, true))
		const modifiedAnswer = recursivelyFindSavedAnswer(assessmentIndex-1, answerPath)
		return modifiedAnswer ? modifiedAnswer : initialAnswer
	}
	return answer
}

