import React from "react"
import { View } from "react-native"
import { Ionicons } from "@expo/vector-icons"
import { NativeStackNavigationProp } from "@react-navigation/native-stack"
import { AuthDrawerParamList } from "../../../@types/navigation/AuthNavigator"

type NavigationProp = NativeStackNavigationProp<AuthDrawerParamList>

interface BackButtonProps {
  navigation: NavigationProp
}

export const BackButton: React.FC<BackButtonProps> = ({ navigation }) => {
	return (
		<View style={{ padding: 10 }}>
			<Ionicons
				name="arrow-back"
				size={24}
				color="black"
				onPress={() => navigation.goBack()}
			/>
		</View>
	)
}
