import { ToastParams } from "./@types/general"
import { TOAST_DEFAULT_CONFIG } from "./constants/general"

const kindToToastInternalMap = {
	success: "success",
	failure: "danger",
	information: "normal",
}

export const showToast = ({ message, kind }: ToastParams) => {
	/**
	 * toast is defined in index.d.ts as a global. docs for toast reccommend this.
	 * this wrapper is for more control
	 * and to also have an explicit import to try and reduce confusion
	 */

	const toastInternalKind = kindToToastInternalMap[kind]

	toast.show(message, { type: toastInternalKind, ...TOAST_DEFAULT_CONFIG })
}
