import { createNativeStackNavigator } from "@react-navigation/native-stack"

import { SignUpScreen } from "../screens/preScreeningAndSignUp/SignUp/SignUp"
import { LoginScreen } from "../screens/Login/Login"
import type { AuthDrawerParamList } from "../../@types/navigation/AuthNavigator"
import { DiseaseIntroductionScreen } from "../screens/preScreeningAndSignUp/DiseaseIntroduction/DiseaseIntroduction"
import { EligibilityScreen } from "../screens/preScreeningAndSignUp/Eligibility/Eligibility"
import { RegistrationScreen } from "../screens/preScreeningAndSignUp/Registration/Registration"
import { ConsentSummaryScreen } from "../screens/preScreeningAndSignUp/ConsentSummary/ConsentSummary"
import { ComprehensionQuestionScreen } from "../screens/preScreeningAndSignUp/ComprehensionQuestions/ComprehensionQuestions"
import { ConsentScreen } from "../screens/preScreeningAndSignUp/Consent/Consent"
import { AuthorizeMedicalReleaseScreen } from "../screens/preScreeningAndSignUp/AuthorizeMedicalRelease/AuthorizeMedicalRelease"
import { ChooseDiseaseScreen } from "../screens/preScreeningAndSignUp/chooseDisease/ChooseDiseaseScreen"
import { useTranslation } from "react-i18next"
import { MedicalProfileScreen } from "../screens/preScreeningAndSignUp/medicalProfile/MedicalProfile"
import { CustomHeaderComponent } from "../components/Header/Header"
import { BackButton } from "../components/BackButton/BackButton"

export const AuthStack = createNativeStackNavigator<AuthDrawerParamList>()

const AuthDrawerNavigator = () => {
	const { t } = useTranslation(["translations", "screens"])

	return (
		<AuthStack.Navigator initialRouteName="DiseaseIntroduction">
			<AuthStack.Screen 
				name="SignUp" 
				component={SignUpScreen}
				options={{
					header: (_) => <CustomHeaderComponent />,
				}}
			/>
			<AuthStack.Screen
				name="Login"
				component={LoginScreen}
				options={{
					header: (_) => <CustomHeaderComponent />,
				}}
			/>
			<AuthStack.Screen
				name="ChooseDisease"
				component={ChooseDiseaseScreen}
				options={{
					header: (_) => <CustomHeaderComponent />,
				}}
			/>
			<AuthStack.Screen
				name="DiseaseIntroduction"
				component={DiseaseIntroductionScreen}
				options={{
					// title: "Introduction",
					header: (_) => <CustomHeaderComponent />,
				}}
			/>
			<AuthStack.Screen
				name="Eligibility"
				component={EligibilityScreen}
				// options={{
				//   header: (_) => <CustomHeaderComponent />,
				// }}
				options={({ navigation }) => ({
					header: (_) => <CustomHeaderComponent />,
					headerLeft: () => <BackButton navigation={navigation} />,
				})}
			/>
			<AuthStack.Screen
				name="ConsentSummary"
				component={ConsentSummaryScreen}
				options={{
					// title: "Consent Summary",
					header: (_) => <CustomHeaderComponent />,
				}}
			/>
			<AuthStack.Screen
				name="ComprehensionQuestion"
				component={ComprehensionQuestionScreen}
				options={{
					// title: "Comprehension Questions",
					header: (_) => <CustomHeaderComponent />,
				}}
			/>
			<AuthStack.Screen
				name="Consent"
				component={ConsentScreen}
				options={{
					header: (_) => <CustomHeaderComponent />,
				}}
			/>
			<AuthStack.Screen
				name="Registration"
				component={RegistrationScreen}
				options={{
					headerBackVisible: false,
					header: (_) => <CustomHeaderComponent />,
				}}
			/>
			<AuthStack.Screen
				name="AuthorizeMedicalRelease"
				component={AuthorizeMedicalReleaseScreen}
				options={{
					// title: "Authorize Medical Release",
					headerBackVisible: false,
					header: (_) => <CustomHeaderComponent />,
				}}
			/>
			<AuthStack.Screen
				name="MedicalProfile"
				component={MedicalProfileScreen}
				options={{
					// title: t("medicalProfile") || undefined,
					headerBackVisible: false,
					header: (_) => <CustomHeaderComponent />,
				}}
			/>
		</AuthStack.Navigator>
	)
}

export default AuthDrawerNavigator
