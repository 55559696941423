import { useNavigation } from "@react-navigation/native"
import * as _ from "lodash"
import { Text } from "@rneui/themed"
import { useState } from "react"
import { Pressable, View } from "react-native"
import { AssessmentComponentProps } from "../../../@types/components/assessment"
import { AssessmentsScreenNavigationProp } from "../../../@types/navigation/HomeNavigator"
import { Assessment } from "../../../@types/redux/assessment"
import { QuestionaireType } from "../../../@types/redux/questionaire"
import {
	setQuestionaireSubmissionStatus,
	setSelectedAssessment,
} from "../../redux/assessmentState"
import { useAppDispatch, useAppSelector } from "../../redux/hooks"
import {
	setSelectedQuestionaire,
} from "../../redux/questionaireState"
import { AppState } from "../../redux/store"
import {
	getAssessmentCompletionStatus,
	getQuestionaireCompletionStatus,
} from "../util"
import { styles } from "./styles"
import { AnswerType } from "../../../@types/redux/Answer"
import { Dropdown } from "./Dropdown"
import { SubmissionStatus } from "./SubmissionStatus"
import { useTheme } from "@rneui/themed"
import { Card } from "@rneui/themed"


export const AssessmentComponent = (props: AssessmentComponentProps) => {
	const { assessment, assessmentIndex } = props

	const { theme } = useTheme()

	const navigation = useNavigation<AssessmentsScreenNavigationProp>()

	const dispatch = useAppDispatch()
	const answerState = useAppSelector((state: AppState) => state.answerState)
	const assessmentAnswers: AnswerType[][] | undefined =
    answerState.assessmentAnswerState[assessmentIndex]

	const [isHidden, setHidden] = useState(true)

	const handleAssessmentPress = async () => {
		setHidden(!isHidden)
	}

	const handleQuestionairePress = async (
		questionaire: QuestionaireType,
		questionaireIndex: number,
		assessment: Assessment
	) => {
		dispatch(setSelectedAssessment(assessment))
		dispatch(setSelectedQuestionaire(questionaire))
		dispatch(
			setQuestionaireSubmissionStatus({
				assessmentIndex,
				questionaireIndex,
				submitted: false,
			})
		)
		navigation.navigate("Questionaire", {
			questionaireIndex: questionaireIndex,
			assessmentIndex: assessmentIndex,
		})
	}

	const formatQuestionaireCompletionStatus = (
		questionaire: QuestionaireType,
		index: number
	) => {
		const [totalComplete, totalAvailable] = getQuestionaireCompletionStatus(
			questionaire,
			assessmentAnswers?.[index]
		)
		return `${totalComplete} / ${totalAvailable} Complete`
	}

	const formatAssessmentCompletionStatus = (
		assessment: Assessment,
		answers: AnswerType[][]
	) => {
		const [totalComplete, totalAvailable] = getAssessmentCompletionStatus(
			assessment,
			answers
		)
		return `${totalComplete} / ${totalAvailable} Complete`
	}

	const getDaysLeft = (endDate: any) => {
		const currentDate = new Date()
		const end = new Date(endDate)
		const differenceInTime = end.getTime() - currentDate.getTime()
		const differenceInDays = Math.ceil(differenceInTime / (1000 * 3600 * 24))
		return Math.max(differenceInDays, 0) // Return 0 if the date has passed
	}

	const daysLeft = getDaysLeft(assessment.end_date)

	return (
		<View>
			

			<View style={styles.assessmentContainer}>
				{isHidden ? (
					<View>
						<Dropdown
							label={`${assessment.name} - ${assessment.start_date} -> ${assessment.end_date}`}
							assessmentStatus={formatAssessmentCompletionStatus(
								assessment,
								assessmentAnswers
							)}
							handleAssessmentPress={handleAssessmentPress}
							visible={isHidden}
						/>
						<Text
							style={{
								fontSize: 16,
								padding: 8,
								textAlign: "center",
								color: theme.colors.gray,
								backgroundColor: theme.colors.lightPurple,
							}}
						>
							{daysLeft > 0
								? `These forms are available for you to complete. You have ${daysLeft} day(s) left to complete the forms (final date: ${assessment.end_date}). After that, these forms will no longer be available for completion.`
								: "The deadline for this assessment has passed."}
						</Text>
					</View>
				) : (
					<View>
						<Dropdown
							label={`${assessment.name} - ${assessment.start_date} -> ${assessment.end_date}`}
							assessmentStatus={formatAssessmentCompletionStatus(
								assessment,
								assessmentAnswers
							)}
							handleAssessmentPress={handleAssessmentPress}
							visible={isHidden}
						/>
						<Text
							style={{
								fontSize: 16,
								padding: 8,
								textAlign: "center",
								color: theme.colors.gray,
								backgroundColor: theme.colors.lightPurple,
							}}
						>
							{daysLeft > 0
								? `These forms are available for you to complete. You have ${daysLeft} day(s) left to complete the forms (final date: ${assessment.end_date}). After that, these forms will no longer be available for completion.`
								: "The deadline for this assessment has passed."}
						</Text>
						<View style={styles.questionnaireList}>
							{assessment.questionaires.map((questionaire, index) => (
								<Card key={questionaire.id}>
									<View style={styles.container}>
										<Pressable
											onPress={() =>
												handleQuestionairePress(questionaire, index, assessment)
											}
											style={({ pressed }) => [
												{
													backgroundColor: pressed
														? "rgb(210, 230, 255)"
														: "white",
													color: "white",
													maxWidth: "50%",
													flex: 1,
													flexGrow: 1,
													flexDirection: "column"
												},
												styles.wrapperCustom,
											]}
										>
											{({ pressed }) => (
												<Text
													style={
														pressed
															? { color: "black" }
															: {
																...styles.questionnaireTitle,
																flex: 1,
																flexGrow: 1,
																justifyContent: "flex-start"
															}
													}
													key={questionaire.id}
												>
													{questionaire.name}:{" "}
                          
												</Text>
											)}
										</Pressable>
										<Text style={styles.completionStatus}>
											<Text>
												{
													<SubmissionStatus
														submitted={questionaire.submitted}
														answeredAndAvailable={getQuestionaireCompletionStatus(
															questionaire,
															assessmentAnswers?.[index]
														)}
														isDirty={questionaire.isDirty}
													/>
												}
											</Text>
										</Text>
									</View>
									<Text>
										{questionaire.description
											? questionaire.description
											: ""}
									</Text>
								</Card>
							))}
						</View>
					</View>
				)}
			</View>
		</View>
	)
}
