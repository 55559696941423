import { CheckBox } from "@rneui/themed"
import { ChoiceProps } from "../../../@types/components/choice"
import { useEffect, useState } from "react"
import { Feather } from "@expo/vector-icons"
import store, { AppState } from "../../redux/store"
import { useAppDispatch, useAppSelector } from "../../redux/hooks"
import { setAnswerOffline } from "../../redux/answerState"
import {
	AnswerType,
	QuestionaireAnswerState,
	SetAnswerOfflineParams,
} from "../../../@types/redux/Answer"
import * as _ from "lodash"
import { setQuestionaireIsDirty } from "../../redux/assessmentState"

export const ChoiceComponent = (props: ChoiceProps) => {
	const {
		parent_question,
		id,
		text,
		questionaireId,
		questionaireIndex,
		questionaireIsDirty,
		assessmentId,
		assessmentIndex,
		canSelectMultiple,
		savedAnswer,
		questionPath,
		isExtra,
		outerIndexOfExtra,
		innerIndexOfExtra,
	} = props

	const dispatch = useAppDispatch()
	const answerState = useAppSelector((state: AppState) => state.answerState)

	const [isChecked, setIsChecked] = useState(false)

	useEffect(() => {
		const isCurrentSingleQuestion = savedAnswer?.select_one_content === id
		const isSingleMultiQuestion =
			savedAnswer?.select_multiple_content?.includes(id)

		if (
			(isCurrentSingleQuestion || isSingleMultiQuestion) &&
			!(typeof savedAnswer === "undefined")
		) {
			setIsChecked(true)
		} else {
			setIsChecked(false)
		}
	}, [answerState, savedAnswer])

	const handleChoicePress = () => {
		// figure out if this answer is a follow up answer

		const answerPath = questionPath
			.replaceAll("questions", "answers")
			.replaceAll(".child_question", "")
		console.log("answer path: ", answerPath)
		const data: SetAnswerOfflineParams = {
			answer: {
				question: parent_question,
				questionaire: questionaireId,
			},
			questionPath,
			assessmentIndex,
			questionaireIndex,
		}

		if (assessmentId) {
			data.answer["assessment"] = assessmentId
		}

		if (
			isExtra &&
			!(typeof outerIndexOfExtra === "undefined") &&
			!(typeof innerIndexOfExtra === "undefined")
		) {
			data.answer["outer_index_of_extra"] = outerIndexOfExtra
			data.answer["inner_index_of_extra"] = innerIndexOfExtra
		}

		if (canSelectMultiple) {
			if (isChecked) {
				// if choice was selected and is being unselected, remove choice from selected choices
				data.answer["select_multiple_content"] = _.filter(
					savedAnswer?.select_multiple_content || [],
					(item) => item !== id
				)
			} else {
				data.answer["select_multiple_content"] = [
					...(savedAnswer?.select_multiple_content || []),
					id,
				]
			}
			dispatch(setAnswerOffline(data))
		} else {
			data.answer["select_one_content"] = id
			dispatch(setAnswerOffline(data))
		}

		if (!questionaireIsDirty && !(typeof assessmentIndex === "undefined")) {
			dispatch(
				setQuestionaireIsDirty({
					assessmentIndex,
					questionaireIndex,
					isDirty: true,
				})
			)
		}
	}

	return (
		<CheckBox
			style={{ flex: 1, flexDirection: "column" }}
			key={id}
			title={text}
			checked={isChecked}
			checkedIcon={
				<Feather
					name={canSelectMultiple ? "check-square" : "check-circle"}
					size={25}
					color="green"
					testID="checked"
				/>
			}
			uncheckedIcon={
				<Feather
					name={canSelectMultiple ? "square" : "circle"}
					size={25}
					color="grey"
					testID="unchecked"
				/>
			}
			onPress={handleChoicePress}
			testID="answer"
		/>
	)
}
