import React from "react"
import { Platform, TouchableOpacity, View } from "react-native"
import { styles } from "./styles"
import { Props } from "../../../@types/components/paginationDots"
import { useTheme } from "@rneui/themed"

const PaginationDots: React.FC<Props> = (props) => {
	const { theme } = useTheme()

	const handleDotClick = (index: number) => {
		props.onDotClick(index)
	}

	// Function to render a dot
	const renderDot = (index: number) => (
		<View
			key={index}
			style={[
				styles.paginationDots,
				{
					opacity: props.pageIndex === index ? 1 : 0.2,
					backgroundColor: theme.colors.primary,
				},
			]}
		/>
	)

	return (
		<View style={styles.paginationWrapper}>
			{props.pageSections.map((section, index) =>
				Platform.OS === "web" ? (
					<TouchableOpacity key={index} onPress={() => handleDotClick(index)}>
						{renderDot(index)}
					</TouchableOpacity>
				) : (
					renderDot(index)
				)
			)}
		</View>
	)
}

export default PaginationDots
