import { Input } from "@rneui/themed"
import { useTranslation } from "react-i18next"
import { AddressComponentProps } from "../../../@types/components/address"
import { useAppDispatch, useAppSelector } from "../../redux/hooks"
import { setPhysicianOfficeAddressLine1, setPhysicianOfficeAddressLine2, setPhysicianOfficeAddressPostalCode, setPhysicianOfficeCity, setPhysicianOfficeCountry, setPhysicianOfficeStateProvince } from "../../redux/medicalProfileState"
import { AppState } from "../../redux/store"
import { styles } from "./styles"


export const Address = (props: AddressComponentProps) => {

	const { office_address, index, addressErrors } = props

	const dispatch = useAppDispatch()

	const { t } = useTranslation(["translation", "screens"])

	return (
		<>
			<Input
				style={styles.input}
				label={t("officeAddressLine1")}
				labelStyle={{ marginBottom: 8, marginTop: 6}}
				value={office_address.address_line1}
				onChangeText={(address) => dispatch(setPhysicianOfficeAddressLine1({index, address}))}
				errorMessage={addressErrors?.address_line1?.join("\n")}/>
			<Input
				style={styles.input}
				label={t("officeAddressLine2")}
				labelStyle={{ marginBottom: 8, marginTop: 6}}
				value={office_address.address_line2}
				onChangeText={(address) => dispatch(setPhysicianOfficeAddressLine2({index, address}))}
				errorMessage={addressErrors?.address_line2?.join("\n")}/>

			<Input
				style={styles.input}
				label={t("city")}
				labelStyle={{ marginBottom: 8, marginTop: 6}}
				value={office_address.city}
				onChangeText={(city) => dispatch(setPhysicianOfficeCity({index, city}))}
				errorMessage={addressErrors?.city?.join("\n")}/>
			<Input
				style={styles.input}
				label={t("state")}
				labelStyle={{ marginBottom: 8, marginTop: 6}}
				value={office_address.state_province}
				onChangeText={(state_province) => dispatch(setPhysicianOfficeStateProvince({state_province, index}))}
				errorMessage={addressErrors?.state_province.join("\n")}/>
			<Input
				style={styles.input}
				label={t("zipCode")}
				labelStyle={{ marginBottom: 8, marginTop: 6}}
				value={office_address.postal_code}
				onChangeText={(code) => dispatch(setPhysicianOfficeAddressPostalCode({index, code}))}
				errorMessage={addressErrors?.zip_code?.join("\n")}/>
			<Input
				style={styles.input}
				label={t("country")}
				labelStyle={{ marginBottom: 8, marginTop: 6}}
				value={office_address.country}
				onChangeText={(country) => dispatch(setPhysicianOfficeCountry({index, country}))}
				errorMessage={addressErrors?.country?.join("\n")}/>
		</>
	)
}
