// import * as SecureStore from "expo-secure-store"


// export const createSecureStorage = () => {
// 	const replaceCharacter = "_"
// 	const replacer = defaultReplacer

// 	return {
// 		getItem: (key: string) =>
// 			SecureStore.getItemAsync(replacer(key, replaceCharacter)),
// 		setItem: (key: string, value: string) =>
// 			SecureStore.setItemAsync(replacer(key, replaceCharacter), value),
// 		removeItem: (key: string) =>
// 			SecureStore.deleteItemAsync(replacer(key, replaceCharacter))
// 	}
// }

// const defaultReplacer = (key: string, replaceCharacter: string) => {
// 	return key.replace(/[^a-z0-9.\-_]/gi, replaceCharacter)
// }

import * as Storage from "./storage"
import * as DefaultOptions from "./options"

const createSecureStorage = (options = {}) => {
	const replacementCharacter = DefaultOptions.replacementCharacter
	const replacer = DefaultOptions.replacer

	Storage.createDirectory()

	return {
		setItem: (key: string, value: string) =>
			Storage.setAsync(replacer(key, replacementCharacter), value, options),

		getItem: (key: string) =>
			Storage.getAsync(replacer(key, replacementCharacter), options),

		removeItem: (key: string) =>
			Storage.removeAsync(replacer(key, replacementCharacter), options),
	}
}

export default createSecureStorage