import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit"
import { AuthState, SignUpData, LoginData, UserLoginResponse, ResendEmailVerificationParams, CreateMedicalProfileParams, MedicalProfileData } from "../../@types/redux/auth"
import axios from "axios"

import { checkForInternetAccess, defaultInitialErrorState, sendErrorsToState } from "./util"
import { AppState } from "./store"
import { ErrorResponseData } from "../../@types/axios/error"
import { backendAPIClient } from "../axios/axios"
import i18n from "../../i18n.config"
import { TOAST_DEFAULT_CONFIG } from "../../constants/general"
import { showToast } from "../../toast"
import { getQuestionairesForAssessments, resetAssessments, syncAssessments } from "./assessmentState"
import { getMedicalProfile, setMedicalProfile } from "./medicalProfileState"


const backendBaseUrl = process.env.BACKEND_BASE_URL

const axiosConfig = {
	withCredentials: true,
	baseURL: backendBaseUrl
}

const authRefreshClient = axios.create(axiosConfig)

const { t } = i18n

const internalInitialState: AuthState = {
	authState: {
		id: 0,
		name: "",
		isAuthenticated: false,
		isSuperuser: false,
		isStaff: false,
		isVerified: false,
		eligibilityStatuses: []
	},
	isLoading: false,
	errors: defaultInitialErrorState
}


export const authSlice = createSlice({
	name: "authState",
	initialState: internalInitialState,
	reducers: {
		newAuthState: (state, action: PayloadAction<AuthState>) => {
			return action.payload
		},
		resetAuthState: () => internalInitialState,
		resetErrors: (state, action: PayloadAction<undefined>) => {
			state.isLoading = false
			state.errors = defaultInitialErrorState
		}
	},
	extraReducers: (builder) => {
		builder.addCase(signUpNewUser.pending, (state, action) => {
			state.isLoading = true
			const message = t("signUpNewUserPending", {ns: ["translations"]})
			showToast({ message, kind: "information" })
		}),
		builder.addCase(signUpNewUser.fulfilled, (state, action) => {
			state.isLoading = false
			const message = t("signUpNewUserFulfilled", {ns: ["translations"]})
			showToast({ message, kind: "success" })
		}),
		builder.addCase(signUpNewUser.rejected, (state, action) => {
			state.isLoading = false
			sendErrorsToState(state, action)
			const message = t("signUpNewUserRejected", {ns: ["translations"]})
			showToast({ message, kind: "failure" })
		}),
		builder.addCase(logUserIn.pending, (state, action) => {
			state.isLoading = true
		}),
		builder.addCase(logUserIn.fulfilled, (state, action) => {
			state.isLoading = false
			if (action.payload) {
				state.authState.id = action.payload.user.id
				state.authState.name = action.payload.user.name,
				state.authState.isAuthenticated = true,
				state.authState.isSuperuser = action.payload.user.is_superuser
				state.authState.isStaff = action.payload.user.is_staff
				state.authState.isVerified = action.payload.user.verified_emails.length === 0 ? false : true
				state.authState.eligibilityStatuses = action.payload.user.eligibility_statuses
			}
		}),
		builder.addCase(logUserIn.rejected, (state, action) => {
			state.isLoading = false
			sendErrorsToState(state, action)
		}),
		builder.addCase(resendVerificationEmail.pending, (state, action) => {
			state.isLoading = true
		}),
		builder.addCase(resendVerificationEmail.fulfilled, (state, action) => {
			state.isLoading = false
		}),
		builder.addCase(resendVerificationEmail.rejected, (state, action) => {
			state.isLoading = false
			sendErrorsToState(state, action)
		})	
	}
})



export const signUpNewUser = createAsyncThunk<any, SignUpData, {state: AppState, rejectValue: ErrorResponseData}>(
	"signUpNewUser",
	async (data: SignUpData, { dispatch, rejectWithValue }) => {
			
		await checkForInternetAccess()
		dispatch(resetErrors())

		try {
			const response = await authRefreshClient.post("api/v1/auth/registration/", data)
			console.log("response from create user in redux: ", response)
			return response
		} catch (error) {
			if (axios.isAxiosError(error)) {
				throw rejectWithValue(error.response?.data)
			} else {
				throw error
			}
		}
	})

export const logUserIn = createAsyncThunk<UserLoginResponse, LoginData, {state: AppState, rejectValue: ErrorResponseData}>(
	"logUserIn",
	async (data: LoginData, { dispatch, rejectWithValue, getState }) => {

		await checkForInternetAccess()
		dispatch(resetErrors())

		const diseaseState = getState().diseaseState

		try {
			const response = await authRefreshClient.post("api/v1/auth/login/", data)

			// dispatch(setMedicalProfile({ medical_profile: response.data.medical_profiles[0] }))
			console.log("response: ", response)
      
			if (diseaseState.selected) dispatch(syncAssessments(diseaseState.selected.id))
			// if (diseaseState.selected) dispatch(getQuestionairesForAssessments(diseaseState.selected.id))
			return response.data
		} catch (error) {
			if (axios.isAxiosError(error)) {
				throw rejectWithValue(error.response?.data)
			} else {
				throw error
			}
		}
	})


export const signUserOut = createAsyncThunk<void, undefined, {state: AppState, rejectValue: ErrorResponseData}>(
	"signUserOut",
	async (_, { dispatch }) => {

		await dispatch(resetErrors())
		await dispatch(resetAuthState())
		await dispatch(resetAssessments())
	})

export const resendVerificationEmail = createAsyncThunk<any, ResendEmailVerificationParams, {state: AppState, rejectValue: ErrorResponseData}>(
	"resendVerificationEmail",
	async (emailData, { dispatch, rejectWithValue }) => {

		await checkForInternetAccess()
		dispatch(resetErrors())

		try {

			const response = await backendAPIClient.post("api/v1/auth/registration/resend-email/", emailData)
			console.log(response)

		} catch (error) {
			console.log("error sending request to resend email: ", error)
			if (axios.isAxiosError(error)) {
				throw rejectWithValue(error.response?.data)
			}
			else {
				throw error
			}
		}

	}
)




// actions are generated by the createSlice function
export const { newAuthState, resetAuthState, resetErrors } = authSlice.actions

export default authSlice.reducer
