import React from "react"
import { StyleSheet, Text, TouchableOpacity } from "react-native"
import Icon from "react-native-vector-icons/Ionicons"
import { useTheme } from "@rneui/themed"


interface Props {
    label: string
    assessmentStatus: string
    handleAssessmentPress: () => void
    visible: boolean
}

export const Dropdown = ({ label, assessmentStatus, handleAssessmentPress, visible }: Props) => {
	const { theme } = useTheme()

	return (
		<TouchableOpacity 
			style={[styles.button, styles.shadowProp, {backgroundColor: theme.colors.primary}]}
			onPress={handleAssessmentPress}
		>
			<Text style={[styles.buttonText, {color: theme.colors.white}]}>{label}</Text>
			<Icon name={visible ? "chevron-up-outline" : "chevron-down-outline"} color={theme.colors.white}/>
		</TouchableOpacity>
	)
}

const styles = StyleSheet.create({
	button: {
		flexDirection: "row",
		alignItems: "center",
		height: 50,
		paddingHorizontal: 10,
		zIndex: 1,
	},
	buttonText: {
		flex: 1,
		textAlign: "center",
	},
	shadowProp: {
		shadowColor: "#171717",
		shadowOffset: {width: -2, height: 4},
		shadowOpacity: 0.2,
		shadowRadius: 3,
	},
})