import { ToastOptions } from "react-native-toast-notifications/lib/typescript/toast"
import { EmploymentStatusOptions, EthnicityOptions, GenderOptions, RaceOptions } from "../@types/components/medicalProfile"

export const ELIGIBILITY_QUESTIONAIRE_INDEX = 0
export const COMPREHENSION_QUESTIONAIRE_INDEX = 1
export const REGISTRATION_QUESTIONAIRE_INDEX = 2

export const GENDER_OPTIONS: Array<GenderOptions> = [
	"male",
	"female"
]
export const RACE_OPTIONS: Array<RaceOptions> = [
	"white_or_caucasian",
	"black_or_african_american",
	"asian",
	"native_hawaiian_or_other_pacific_islander",
	"american_indian_or_alaska_native",
	"other"
]
export const ETHNICITY_OPTIONS: Array<EthnicityOptions> = [
	"hispanic",
	"non_hispanic"
]
export const EMPLOYMENT_STATUS_OPTIONS: Array<EmploymentStatusOptions> = [
	"working",
	"retired",
	"homemaker",
	"student",
	"disabled",
	"unemployed",
	"other"
]

export const TOAST_DEFAULT_CONFIG: ToastOptions = {
	placement: "bottom",
	duration: 5000,
	animationType: "slide-in",
}