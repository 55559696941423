import { Button, Text } from "@rneui/themed"
import { View, ScrollView, SafeAreaView, StyleSheet } from "react-native"
import { QuestionaireProps } from "../../../@types/components/questionaire"
import { QuestionComponent } from "../Question/Question"
import { styles } from "./styles"
import { TimerComponent } from "../Question/Subcomponents/Timer/Timer"
import { useTheme } from "@rneui/themed"
import { AppState } from "../../redux/store"
import { useAppSelector } from "../../redux/hooks"
import { useToast } from "react-native-toast-notifications"
import { useNavigation } from "@react-navigation/native"
import { AssessmentsScreenNavigationProp } from "../../../@types/navigation/HomeNavigator"
import { useEffect, useRef } from "react"
import { Picture } from "../Picture/Picture"
import { Pictures } from "../Pictures/Pictures"

export const QuestionaireComponent = (props: QuestionaireProps) => {
	const { theme } = useTheme()
	const toast = useToast()
	const navigation = useNavigation<AssessmentsScreenNavigationProp>()
	const authState = useAppSelector(
		(state: AppState) => state.authState.authState
	)
	const scrollViewRef = useRef<ScrollView | null>(null)

	useEffect(() => {
		const unsubscribe = navigation.addListener("focus", () => {
			// Reset scroll position here
			scrollViewRef.current?.scrollTo({ x: 0, y: 0, animated: false })
		})

		return unsubscribe
	}, [navigation])

	const {
		questionaire,
		questionaireIndex,
		assessmentIndex,
		assessmentId,
		isDirty,
		onSubmitCallback,
		unansweredQuestions,
		removeUnansweredQuestion,
	} = props

	if (!questionaire) {
		return (
			<View>
				<Text>Oops, something went wrong!</Text>
			</View>
		)
	}

	const onSaveAnswers = () => {
		navigation.navigate("Assessments")
		toast.show("Successfully saved answers!", {
			type: "success",
			placement: "bottom",
			duration: 3000,
			animationType: "slide-in",
		})
	}
	console.log("INSIDE THE QUESTIONAIRE COMPONENT")

	return (
		<SafeAreaView
			style={styles.container}
		>
			<ScrollView ref={scrollViewRef} stickyHeaderIndices={[0]}>
				<View style={{ maxHeight: 170 }}>
					<ScrollView showsVerticalScrollIndicator={true}>
						<Text
							style={[
								styles.intructionText,
								{
									color: theme.colors.gray,
									backgroundColor: theme.colors.lightPurple,
								},
							]}
							testID="instructionText"
						>
							{questionaire.instructions}
						</Text>
					</ScrollView>
				</View>
				{
					questionaire.pictures?.length ? <Pictures pictures={questionaire.pictures}/> : null
				}

				<Text>{questionaire.time_to_complete}</Text>

				{questionaire.questionaire_type === "timer" ? <TimerComponent /> : null}

				{questionaire.questions.map((question, i) => {
					return (
						<QuestionComponent
							key={question.id}
							assessmentId={assessmentId}
							assessmentIndex={assessmentIndex}
							questionaireId={questionaire.id}
							questionaireIndex={questionaireIndex}
							questionaireIsDirty={isDirty}
							should_show_all_questions={questionaire.should_show_all_questions}
							should_use_previous_answers={questionaire.should_use_previous_answers}
							questionPath={`[${i}]`}
							isExtra={false}
							{...question}
							unansweredQuestions={unansweredQuestions}
							removeUnansweredQuestion={removeUnansweredQuestion}
							pictures={question.pictures}
						/>
					)
				})}

			</ScrollView>
			{authState.isAuthenticated ? (
				<View style={styles1.container}>
					<Button
						title="Submit Questionnaire"
						onPress={onSubmitCallback}
						testID="questionnaireSubmit"
						buttonStyle={styles1.button}
					/>
					<Button
						title="Save Answers"
						buttonStyle={styles1.button}
						onPress={onSaveAnswers}
					/>
				</View>
			) : (
				<Button
					title="Submit"
					onPress={onSubmitCallback}
					testID="questionnaireSubmit"
				/>
			)}
		</SafeAreaView>
			
	)
}

const styles1 = StyleSheet.create({
	container: {
		padding: 20,
		flexDirection: "row",
		justifyContent: "space-between",
	},
	button: {
		borderRadius: 8,
	},
	unansweredQuestion: {
		borderWidth: 2,
		borderColor: "red",
	},
})
