export default {
	screens: {
		home: "Home",
		settings: "Settings",
		registration: "Registration",
		authorizeMedicalRelease: "Authorize Medical Release",
		selectDisease: "Select Disease",
		comprehensionQuestions: "Comprehension Questions",
		consent: "Consent",
		consentSummary: "Consent Summary",
		diseaseIntroduction: "Introduction",
		eligibility: "Eligibility",
		signUp: "Sign Up",
		logIn: "Log In",
		assessments: "Assessments",
	}
}
