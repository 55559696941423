import { useNavigation } from "@react-navigation/native"
import { View } from "react-native"
import { AuthorizeMedicalReleaseScreenNavigationProp } from "../../../../@types/navigation/AuthNavigator"
import { ConsentForm } from "../../../components/Consent/Consent"


export const AuthorizeMedicalReleaseScreen = () => {

	const navigation = useNavigation<AuthorizeMedicalReleaseScreenNavigationProp>()

	const handleAuthorizeMedicalReleaseSubmit = () => {
		navigation.navigate("SignUp")
	}

	return (
		<View style={{flex: 1}}>
			<ConsentForm handleSignatureConfirmCallback={handleAuthorizeMedicalReleaseSubmit}/>
		</View>
	)
}