import { getLocales } from "expo-localization"
import { Input, Text } from "@rneui/themed"
import { useState } from "react"
import { View } from "react-native"
import { setDefaultLocale } from "react-datepicker"
import { QuestionSubcomponentProps } from "../../../../../@types/components/question"
import { useAppDispatch } from "../../../../redux/hooks"
import { styles } from "../../styles"
import { AnswerType, SetAnswerOfflineParams } from "../../../../../@types/redux/Answer"
import { setAnswerOffline } from "../../../../redux/answerState"
import { setQuestionaireIsDirty } from "../../../../redux/assessmentState"



export const TextComponent = (props: QuestionSubcomponentProps) => {

	const {
		assessmentId,
		assessmentIndex,
		questionaireId,
		questionaireIndex,
		questionaireIsDirty,
		id,
		savedAnswer,
		prompt,
		isExtra,
		outerIndexOfExtra,
		innerIndexOfExtra,
		questionPath
	} = props

	const locales = getLocales()
	const preferredLanguageCode = locales[0].languageCode
	try {
		setDefaultLocale(preferredLanguageCode) // defaults to english
	} catch (error) {
		console.error("error setting date picker preferred language: ", error)
	}

	const dispatch = useAppDispatch()

	const [textAnswer, setTextAnswer] = useState<string>()

	const handleSetAnswerContent = () => {
		// here is where we update redux state with the response text
		const answerData: SetAnswerOfflineParams = {
			answer: {
				question: id,
				assessment: assessmentId,
				questionaire: questionaireId
			},
			questionPath,
			assessmentIndex,
			questionaireIndex
		}

		if (isExtra && !(typeof outerIndexOfExtra === "undefined") && !(typeof innerIndexOfExtra === "undefined")) {
			answerData.answer["outer_index_of_extra"] = outerIndexOfExtra
			answerData.answer["inner_index_of_extra"] = innerIndexOfExtra
		}

		if (textAnswer) answerData.answer.translations = { [preferredLanguageCode]: { text_content: textAnswer } }
		
		dispatch(setAnswerOffline(answerData))

		if ((!questionaireIsDirty) && !(typeof assessmentIndex === "undefined")) {
			dispatch(setQuestionaireIsDirty({ assessmentIndex, questionaireIndex, isDirty: true }))
		}
	}

	return (
		<View style={styles.questionContainer}>
			<Text>{prompt}</Text>
			<Input
				placeholder="input Response"
				style={styles.inputInnerContainer}
				value={savedAnswer?.translations?.[preferredLanguageCode].text_content}
				onChangeText={value => setTextAnswer(value)}
				onBlur={handleSetAnswerContent}
			/>
		</View>
	)
}