import { useNavigation } from "@react-navigation/native"
import { useState } from "react"
import { View } from "react-native"
import { ConsentScreenNavigationProp } from "../../../../@types/navigation/AuthNavigator"
import { DownloadDocumentArgs } from "../../../../@types/redux/document"
import { ConsentForm } from "../../../components/Consent/Consent"
import { LoadingSpinner } from "../../../components/Loading/LoadingSpinner"
import { downloadDocument, getDiseaseDocuments } from "../../../redux/documentState"
import { useAppDispatch, useAppSelector } from "../../../redux/hooks"
import { getQuestionaire } from "../../../redux/questionaireState"
import { AppState } from "../../../redux/store"



export const ConsentScreen = () => {

	const navigation = useNavigation<ConsentScreenNavigationProp>()
	const dispatch = useAppDispatch()

	const disease = useAppSelector((state: AppState) => state.diseaseState.selected?.id)?.toString()
	const documentsLoading = useAppSelector((state: AppState) => state.documentState.isLoading)

	const [hasViewedConsent, setHasViewedConsent] = useState(false)

	const handleConsent = async () => {
		console.log("this is me handling consent from the consent screen")
		await dispatch(getQuestionaire("registration"))
		navigation.navigate("Registration")
	}

	const handleDownloadConsentForm = async () => {
		console.log("starting to download the consent form")

		try {
			const res = await dispatch(getDiseaseDocuments({ kind: "consent_form", disease })).unwrap()
			console.log(res)
			// TODO might need to handle downloading multiple documents in the future
			const { file, filename } = res[0]
			await dispatch(downloadDocument({ filename, fileUrl: file }))
			setHasViewedConsent(true)
		} catch (error) {
			console.log("error downloading document: ", error)
		}

		console.log("downloading consent form")
	}

	return (
		<View style={{flex: 1}}>
			<LoadingSpinner isLoading={documentsLoading} message={"Downloading consent form"}/>
			<ConsentForm 
				handleSignatureConfirmCallback={handleConsent} 
				handleDownloadDocument={handleDownloadConsentForm}
				hasViewedConsentDocument={hasViewedConsent}/>
		</View>
	)
}
