import { Icon, ListItem, Text } from "@rneui/themed"
import { Pressable, View } from "react-native"
import { DocumentComponentProps } from "../../../@types/components/document"
import { DownloadDocumentArgs } from "../../../@types/redux/document"
import { downloadDocument } from "../../redux/documentState"
import { useAppDispatch } from "../../redux/hooks"
import { handleDownloadDocument } from "../../redux/util"


export const DocumentComponent = (props: DocumentComponentProps) => {

	const { serverDocument, documentType } = props

	const dispatch = useAppDispatch()

	const handleDocumentPress = async () => {
		const requestArgs: DownloadDocumentArgs = {
			documentId: serverDocument.id,
			documentType,
			filename: serverDocument.filename
		}
		// const result = await dispatch(downloadDocument(requestArgs))
		const res = await handleDownloadDocument(serverDocument.file, serverDocument.filename)
	}

	return (
		<ListItem>
			<Icon name="file-download" type="material-icons" />
			<ListItem.Content>
				<Pressable onPress={handleDocumentPress}>
					<ListItem.Title>
						{serverDocument.filename}
					</ListItem.Title>
				</Pressable>
			</ListItem.Content>
		</ListItem>
	)
}