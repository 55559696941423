import { Text } from "@rneui/themed"
import { BasePage } from "../../components/BasePage/BasePage"

export const FormsScreen = () => {
	return (
		<BasePage>
			<Text>
                This is the forms screen
			</Text>
		</BasePage>
			
	)
}
