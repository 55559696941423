import { createNavigationContainerRef, NavigationContainer } from "@react-navigation/native"
import { AppState } from "../redux/store"
import HomeDrawerNavigator from "./HomeNavigator"
import AuthDrawerNavigator from "./AuthNavigator"
import { useAppSelector } from "../redux/hooks"
import { DrawerNavigationProp } from "@react-navigation/drawer"
import { AuthDrawerParamList } from "../../@types/navigation/AuthNavigator"
import type { HomeDrawerParamList } from "../../@types/navigation/HomeNavigator"

export type RootNavigationScreenParams = AuthDrawerParamList | HomeDrawerParamList

export const RootNavigatorRef = createNavigationContainerRef<RootNavigationScreenParams>()


const RootNavigator = () => {
 
	return (
		<NavigationContainer  ref={RootNavigatorRef}>
			<AuthenticationProtectedNavigation/>
		</NavigationContainer> 
	)
}


const AuthenticationProtectedNavigation = () => {

	const authState = useAppSelector((state: AppState) => state.authState.authState)

	return authState.isAuthenticated ? <HomeDrawerNavigator/> : <AuthDrawerNavigator/>
}


export default RootNavigator
