import { CheckBox, Text } from "@rneui/themed"
import { useEffect } from "react"
import { View } from "react-native"
import { Feather } from "@expo/vector-icons"
import { getDiseases, setSelectedDisease } from "../../redux/diseaseState"
import { useAppDispatch, useAppSelector } from "../../redux/hooks"
import { AppState } from "../../redux/store"
import { styles } from "./styles"
import { useTheme } from "@rneui/themed"
import { useTranslation } from "react-i18next"


export const DiseasePicker = () => {

	const { theme } = useTheme()
	const { t } = useTranslation()

	const dispatch = useAppDispatch()
	const availableDiseases = useAppSelector((state: AppState) => state.diseaseState.available)
	const selectedDisease = useAppSelector((state: AppState) => state.diseaseState.selected)

	useEffect(() => {
		dispatch(getDiseases())
	}, [])

	return (
		<View style={[styles.diseasePickerContainer, { backgroundColor: theme.colors.white }]}>
			<Text style={{ textAlign: "center", padding: 5 }} >{t("selectDiseaseMessage")}</Text>
			<View style={styles.checkboxContainer}>
				{
					availableDiseases.map(disease => {
						return (
							<CheckBox key={disease.id}
								center
								title={disease.name}
								checked={disease.id == selectedDisease?.id}
								checkedIcon={
									<Feather name='check-circle' size={25} color='green' />
								}
								uncheckedIcon={
									<Feather
										name="circle"
										color="grey"
										size={25}
									/>
								}
								onPress={() => dispatch(setSelectedDisease(disease))}
							/>
						)
					})
				}
			</View>
		</View>
	)
}
