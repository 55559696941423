import { StyleSheet } from "react-native"

export const styles = StyleSheet.create({
	consentSummaryContainer: {
		flex: 1,
	},
	intructionText: {
		fontSize: 16,
		padding: 8,
	},
	contentContainer: {
		alignItems: "center",
		justifyContent: "center",
		marginHorizontal: 12,
	},
	checkBox: {
		width: "75%",
	},
	sectionHeader: {
		marginTop: 8,
		fontSize: 16,
	},
	sectionContent: {
		marginBottom: 18,
		lineHeight: 20,
	},
	sectionContentBold: {
		fontWeight: "bold",
	},
})
