import axios from "axios"
import createAuthRefreshInterceptor from "axios-auth-refresh"
import { AxiosAuthRefreshOptions } from "axios-auth-refresh"
import store from "../redux/store"
import { resetAuthState } from "../redux/authState"
import { RootNavigatorRef } from "../navigation/RootNavigator"
import * as Network from "expo-network"
import { showAlert } from "../screens/util"


const backendBaseUrl = process.env.BACKEND_BASE_URL



const axiosConfig = {
	withCredentials: true,
	baseURL: backendBaseUrl,
	// headers: {"Content-Type": "application/json"}
}

export const backendAPIClient = axios.create(axiosConfig)
const authRefresher = axios.create(axiosConfig)


const authRefreshLogic = async () => {
	try {
		// must send the empty body in the request so that cookies can be put into the body serverside
		await authRefresher.post("api/v1/auth/token/refresh/", {})
		return Promise.resolve()
	} catch (error) {
		const { isConnected, isInternetReachable } = await Network.getNetworkStateAsync()

		if (isConnected && isInternetReachable) {
			showAlert("Your session has expired. You will be directed to the login page to renew your session.")
			store.dispatch(resetAuthState())
		}
	}
}

const authConfig: AxiosAuthRefreshOptions = {
  
}

createAuthRefreshInterceptor(backendAPIClient, authRefreshLogic, authConfig)

