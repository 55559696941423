import { StyleSheet } from "react-native"

export const styles = StyleSheet.create({
	container: {
		flex: 1,
	},
	video: {
		width: "100%",
		height: 400,
		alignItems: "center",
	},
	header: {
		textAlign: "center",
		marginVertical: 16,
		fontSize: 8,
		color: "#1a1561"
	},
	subheader: {
		paddingHorizontal: 16,
		marginVertical: 8,
		textAlign: "center"
	},
	bodyText: {
		marginBottom: 8,
		paddingHorizontal: 16,
		lineHeight: 20,
		marginTop: 16
	},
	link: {
		paddingHorizontal: 16,
		paddingVertical: 8,
	},
	paginationWrapper: {
		left: 0,
		right: 0,
		justifyContent: "center",
		alignItems: "center",
		flexDirection: "row",
		margin: 20,
	},
	paginationDots: {
		height: 10,
		width: 10,
		borderRadius: 10 / 2,
		marginLeft: 10,
	},
})
