import { useNavigation } from "@react-navigation/native"
import { Text } from "@rneui/themed"
import { View, StyleSheet } from "react-native"
import { DiseaseIntroductionScreenNavigationProp } from "../../../@types/navigation/AuthNavigator"
import { resetErrors as clearAuthErrors } from "../../redux/authState"
import { useAppDispatch } from "../../redux/hooks"
import { styles } from "./styles"


export const AuthFooterComponent = () => {

	const navigation = useNavigation<DiseaseIntroductionScreenNavigationProp>()

	const dispatch = useAppDispatch()

	const handleNavigateToLogin = () => {
		navigation.navigate("Login")
		dispatch(clearAuthErrors())
	}

	return (
		<View style={styles.footerContainer} >
			<Text>Already enrolled? </Text>
			<Text hyperlink onPress={handleNavigateToLogin}>Login</Text>
		</View>
	)
}