import { ScrollView } from "react-native"
import { LoadingSpinner } from "../../components/Loading/LoadingSpinner"
import { MedicalProfile } from "../../components/MedicalProfile/MedicalProfile"
import { useAppDispatch, useAppSelector } from "../../redux/hooks"
import { updateMedicalProfile } from "../../redux/medicalProfileState"
import { AppState } from "../../redux/store"


export const ProfileScreen = () => {

	const dispatch = useAppDispatch()

	const userState = useAppSelector((state: AppState) => state.authState.authState)  
	const medProfile = useAppSelector((state: AppState) => state.medicalProfileState.profile)
	const medProfileLoading = useAppSelector((state: AppState) => state.medicalProfileState.isLoading)

	const handleMedProfileUpdate = async () => {
		console.log("updating medical profile")
		await dispatch(updateMedicalProfile())
	}

	return (
		<ScrollView>
			<LoadingSpinner isLoading={medProfileLoading} message="Updating medical profile..."/>
			<MedicalProfile handleSubmit={handleMedProfileUpdate} userId={userState.id} shouldShowSubmitButton={true} />
		</ScrollView>
	)
}
