import { StyleSheet } from "react-native"

export const styles = StyleSheet.create({
	container: {
		flex: 1,
		alignItems: "center",
	},
	isValidText: {
		marginBottom: 16,
		fontSize: 16,
	},
	emoji: {
		marginTop: 48,
		marginBottom: 32,
	},
})
