import { useTheme } from "@rneui/themed"
import { ReactNode } from "react"
import { ImageBackground, Platform, View } from "react-native"
import { styles } from "./styles"

export const BasePage = ({children}: {children: ReactNode}) => {

	const { theme } = useTheme()

	return (
		<View style={[styles.container, {backgroundColor: theme.colors.white}]}>
			<ImageBackground
				source={require("../../../assets/images/intro_image1.jpg")}
				resizeMode="cover"
				style={Platform.OS === "web" ? [styles.imageStylingWeb, styles.container] : [styles.imageStylingMobile, styles.container]}
				imageStyle={styles.imageOpacity}
			>
				{children}
			</ImageBackground>
		</View>
	)
}
