import { useEffect } from "react"
import { View } from "react-native"
import { getDiseaseDocuments, getUserDocuments } from "../../../redux/documentState"
import { useAppDispatch, useAppSelector } from "../../../redux/hooks"
import { AppState } from "../../../redux/store"
import { DocumentComponent } from "../DocumentComponent"
import { Title, Card } from "react-native-paper"
import { styles } from "./styles"


export const DocumentList = () => {

	const documentState = useAppSelector((state: AppState) => state.documentState)
	const disease = useAppSelector((state: AppState) => state.diseaseState.selected?.id)?.toString()
	const dispatch = useAppDispatch()

	useEffect(() => { 
             
		const fetchAllDocuments = async () => {
			await dispatch(getUserDocuments({ disease }))
			await dispatch(getDiseaseDocuments({ disease }))
		}
		fetchAllDocuments()

	}, []
	)

	return (
		<View style={styles.container}>
			<View style={styles.documentsContainer}>
				<Title>Disease Documents:</Title>
				{
					documentState.diseaseDocuments.map(doc => {
						return (
							<Card style={styles.card} key={doc.id}>
								<Card.Content style={styles.cardContent}>
									<DocumentComponent
										serverDocument={doc} 
										documentType="disease_documents"
									/>
								</Card.Content>
							</Card>
						)
					})
				}
			</View>
			<View style={styles.documentsContainer}>
				<Title>User Uploaded Documents:</Title>
				{
					documentState.userUploadedDocuments.map(doc => {
						return (
							<Card style={styles.card} key={doc.id}>
								<Card.Content style={styles.cardContent}>
									<DocumentComponent
										serverDocument={doc}
										documentType="user_uploaded_documents"
									/>
								</Card.Content>
							</Card>
						)
					})
				}
			</View>
		</View>
	)
}
