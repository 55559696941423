import { StyleSheet } from "react-native"

export const styles = StyleSheet.create({
	container: {
		flex: 1,
	},
	cardOuterContainer: {
		marginTop: 56,
		marginBottom: 24,
	},
	cardInnerContainer: {
		paddingVertical: 16,
	},
	button: {
		marginHorizontal: 24,
	},
})
