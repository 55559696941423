import { View } from "react-native"
import { Text, Button, Input, Card } from "@rneui/themed"
import type { LoginScreenNavigationProp } from "../../../@types/navigation/AuthNavigator"
import { useNavigation } from "@react-navigation/native"
import { useState } from "react"
import { logUserIn, resendVerificationEmail, resetErrors as clearAuthErrors } from "../../redux/authState"
import { useAppDispatch, useAppSelector } from "../../redux/hooks"
import { AppState } from "../../redux/store"
import { styles } from "./styles"
import { useToast } from "react-native-toast-notifications"
import { useTheme } from "@rneui/themed"
import { getMedicalProfile } from "../../redux/medicalProfileState"



export const LoginScreen = () => {
	const { theme } = useTheme()
	const toast = useToast()

	const navigation = useNavigation<LoginScreenNavigationProp>()

	const dispatch = useAppDispatch()

	const authState = useAppSelector((state: AppState) => state.authState)

	const [username, setUsername] = useState("")
	const [password, setPassword] = useState("")
	const [email, setEmail] = useState("")

	const handleLogin = async () => {
		const loginData = { username, password, email }
		await dispatch(logUserIn(loginData))
	}

	const dismissAuthErrors = () => {
		dispatch(clearAuthErrors())
	}

	const handleNavigateToSignUp = () => {
		navigation.navigate("SignUp")
		dispatch(clearAuthErrors())
	}

	const handleResendVerificationEmail = async () => {
		await dispatch(resendVerificationEmail({email}))
	}

	return (
		<View style={[styles.container, {backgroundColor: theme.colors.white}]}>
			<Card containerStyle={styles.cardOuterContainer} wrapperStyle={styles.cardInnerContainer}>
				<Card.Title h3>Log In</Card.Title>
				<Card.FeaturedSubtitle style={styles.cardSubtitle}>
					Please use either Email or Username to log in. If you are using email, please verify email before continuing.
				</Card.FeaturedSubtitle>
				<Card.Divider />
				<Input
					style={styles.inputInnerContainer}
					placeholder="Username"
					value={username}
					onChangeText={setUsername}
					autoCorrect={false}
					autoCapitalize="none"
					errorMessage={authState.errors.username?.join("\n")}
					testID="enterUsername"
				/>
				<Input
					style={styles.inputInnerContainer}
					placeholder="Password"
					value={password}
					onChangeText={setPassword}
					secureTextEntry={true}
					errorMessage={authState.errors.password?.join("\n")}
					testID="enterPassword"
				/>
				<Input
					style={styles.inputInnerContainer}
					placeholder="Email"
					value={email}
					onChangeText={setEmail}
					autoCapitalize="none"
					textContentType="emailAddress"
					autoCorrect={false}
					keyboardType="email-address"
					autoComplete="email"
					errorMessage={authState.errors.email?.join("\n")}
					testID="enterEmail"
				/>
			</Card>
			<Button
				style={styles.button}
				title="Log In"
				onPress={handleLogin} 
				testID="loginSubmit"	
			/>
			<Text hyperlink onPress={handleResendVerificationEmail} style={styles.emailVerificationText}>Forgot My Username/Password</Text>
			{/* <Button title="Resend Verification Email" 
				onPress={handleResendVerificationEmail}/> */}

		</View>
	)
}
