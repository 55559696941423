import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit"
import axios, { AxiosProgressEvent } from "axios"
import * as _ from "lodash"
import { ErrorResponseData } from "../../@types/axios/error"
import { DiseaseDocsQueryParams, DocumentState, DocumentTypes, DownloadDocumentArgs, FileSelection, GetDocumentsArgs, GetDocumentsReturn, GetUserUploadedDocumentsArgs, ServerDocument, UploadDocumentParams } from "../../@types/redux/document"
import { backendAPIClient } from "../axios/axios"
import { AppState } from "./store"
import { checkForInternetAccess, defaultInitialErrorState, handleDownloadDocument, sendErrorsToState } from "./util"
import { showToast } from "../../toast"
import i18n from "../../i18n.config"
import { filterObject, removeFalseyValues } from "../../util"
import { RecursiveObj } from "../../@types/general"


const internalInitialState: DocumentState = {
	userUploadedDocuments: [],
	diseaseDocuments: [],
	isLoading: false,
	errors: defaultInitialErrorState,
	uploadState: {}
}

const { t } = i18n

export const documentSlice = createSlice({
	name: "documentState",
	initialState: internalInitialState,
	reducers: {
		setSelectedDocument: (state, action: PayloadAction<FileSelection>) => {
			state.selectedDocumentToUpload = action.payload
		},
		resetUploadState: (state, action: PayloadAction<undefined>) => {
			state.uploadState = {}
		},
		setUploadPercentProgress: (state, action: PayloadAction<{ filename: string, progressPercent: number, uploadFinished: boolean}>) => {
			const { filename, progressPercent, uploadFinished } = action.payload
			if (!state.uploadState[filename]) {
				state.uploadState[filename] = {uploadProgress: {percentComplete: progressPercent}, isUploading: true, uploadFinished}
			} else {
				state.uploadState[filename].uploadProgress.percentComplete = progressPercent
				state.uploadState[filename].isUploading = true
				state.uploadState[filename].uploadFinished = uploadFinished
			}
		},
		resetDocuments: () => internalInitialState,
		resetErrors: (state, action: PayloadAction<undefined>) => {
			state.errors = defaultInitialErrorState
		}
	},
	extraReducers: (builder) => {
		builder.addCase(getUserDocuments.pending, (state, action) => {
			state.isLoading = true
		}),
		builder.addCase(getUserDocuments.fulfilled, (state, action) => {
			const userDocs = action.payload
			console.log("get documents fulfilled: ", action.payload)
			state.isLoading = false
			state.userUploadedDocuments = userDocs
		}),
		builder.addCase(getUserDocuments.rejected, (state, action) => {
			state.isLoading = false
			sendErrorsToState(state, action)
		}),
		builder.addCase(getDiseaseDocuments.pending, (state, action) => {
			state.isLoading = true
		}),
		builder.addCase(getDiseaseDocuments.fulfilled, (state, action) => {
			const diseaseDocs = action.payload
			console.log("get documents fulfilled: ", action.payload)
			state.isLoading = false
			state.diseaseDocuments = diseaseDocs
		}),
		builder.addCase(getDiseaseDocuments.rejected, (state, action) => {
			state.isLoading = false
			sendErrorsToState(state, action)
		}),
		builder.addCase(uploadDocument.pending, (state, action) => {
			state.isLoading = true
		}),
		builder.addCase(uploadDocument.fulfilled, (state, action) => {
			state.isLoading = false
			const message = t("DocumentUploadFulfilled", {ns: ["translations"]})
			showToast({ message, kind: "success" })
		}),
		builder.addCase(uploadDocument.rejected, (state, action) => {
			state.isLoading = false
			sendErrorsToState(state, action)
			const message = t("DocumentUploadRejected", {ns: ["translations"]})
			showToast({ message, kind: "failure" })
		})
		builder.addCase(downloadDocument.pending, (state, action) => {
			state.isLoading = true
		})
		builder.addCase(downloadDocument.fulfilled, (state, action) => {
			state.isLoading = false
		})
		builder.addCase(downloadDocument.rejected, (state, action) => {
			state.isLoading = false
			sendErrorsToState(state, action)
			const message = t("documentDownloadRejected", {ns: ["translations"]})
			showToast({message, kind: "failure"})
		})
	},
})

export const uploadDocument = createAsyncThunk<
undefined,
UploadDocumentParams,
{
	state: AppState,
	rejectValue: ErrorResponseData
}
>(
	"uploadDocument",
	async ({ filename }, { dispatch, rejectWithValue, getState }) => {

		await checkForInternetAccess()
		dispatch(resetErrors())

		const documentState = getState().documentState
		const diseaseState = getState().diseaseState

		try {
			const documentsUrl = "api/v1/documents/user_uploaded_documents/"
			const data = new FormData()
			if (documentState.selectedDocumentToUpload?.file) {
				data.append("file", documentState.selectedDocumentToUpload.file)
				data.append("disease", diseaseState.selected?.id.toString() || "")
			}

			const uploadConfig = {
				onUploadProgress: (progress: AxiosProgressEvent) => {
					const { loaded, total } = progress
					const progressPercent = loaded/(total || 1)
					dispatch(setUploadPercentProgress({filename, progressPercent, uploadFinished: progressPercent >= 1}))
				}  
			}
            
			const response = await backendAPIClient.post(documentsUrl, data, uploadConfig)
			console.log("response from backend: ", response)
			return response.data
		} catch (error) {
			if (axios.isAxiosError(error)) {
				throw rejectWithValue(error.response?.data)
			} else {
				throw error
			}
		}
	}
)

export const downloadDocument = createAsyncThunk<
void,
DownloadDocumentArgs,
{
    state: AppState,
    rejectValue: ErrorResponseData
}
>(
	"downloadDocument",
	async ({ filename, fileUrl }, { dispatch, rejectWithValue }) => {

		await checkForInternetAccess()
		dispatch(resetErrors())

		try {
			await handleDownloadDocument(fileUrl, filename)
		} catch (error) {
			if (axios.isAxiosError(error)) {
				throw rejectWithValue(error.response?.data)
			} else {
				throw error
			}
		}
	}
)

export const getUserDocuments = createAsyncThunk<
ServerDocument[],
GetUserUploadedDocumentsArgs | undefined,
{
	state: AppState,
	rejectValue: ErrorResponseData
}
>(
	"getUserDocuments",
	async (queryParams, { dispatch, rejectWithValue }) => {

		await checkForInternetAccess()
		dispatch(resetErrors())


		const filteredObj = queryParams ? filterObject(queryParams, removeFalseyValues) : {}

		const searchParams = new URLSearchParams(filteredObj).toString()
		const userDocsQueryParams = searchParams ? `?${searchParams}` : ""

		try {
			const response = await backendAPIClient.get(`api/v1/documents/user_uploaded_documents/${userDocsQueryParams}`)
			return response.data
		} catch (error) {
			if (axios.isAxiosError(error)) {
				throw rejectWithValue(error.response?.data)
			} else {
				throw error
			}
		}
	}
)

export const getDiseaseDocuments = createAsyncThunk<
ServerDocument[],
DiseaseDocsQueryParams | undefined,
{
	state: AppState,
	rejectValue: ErrorResponseData
}
>(
	"getDiseaseDocuments",
	async (queryParams, { dispatch, rejectWithValue, getState }) => {

		await checkForInternetAccess()
		dispatch(resetErrors())


		const filteredObj = queryParams ? filterObject(queryParams, removeFalseyValues) : {}

		const searchParams = new URLSearchParams(filteredObj).toString()
		const diseaseDocsExtension = searchParams ? `?${searchParams}` : ""

		try {
			const response = await backendAPIClient.get(`api/v1/documents/disease_documents/${diseaseDocsExtension}`)
			return response.data
		} catch (error) {
			if (axios.isAxiosError(error)) {
				throw rejectWithValue(error.response?.data)
			} else {
				throw error
			}
		}
	}
)

// actions are generated by the createSlice function
export const { setSelectedDocument, resetDocuments, resetErrors, resetUploadState, setUploadPercentProgress } = documentSlice.actions

export default documentSlice.reducer
