import { Text } from "@rneui/themed"
import { View } from "react-native"
import { PicturesComponentProps } from "../../../@types/components/pictures"
import { Picture } from "../Picture/Picture"


export const Pictures = (props: PicturesComponentProps) => {
	const { pictures } = props
	return (
		<View
			style={{
				display: "flex",
				alignItems: "center",
				justifyContent: "center",
			}}
		>
			{pictures.map(picture => <Picture key={picture.picture} picture={picture}/>)}
			<br/>
		</View>
	)
}
