import { StyleSheet } from "react-native"

export const styles = StyleSheet.create({
	footerContainer: {
		flexDirection: "row",
		justifyContent: "center",
		paddingBottom: 16,
		paddingTop: 8,
	},
})
