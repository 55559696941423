import { StyleSheet } from "react-native"
import { upittTheme } from "../../../theme/rootTheme"

export const styles = StyleSheet.create({
	container: {
		flex: 1,
		flexDirection: "row",
		justifyContent: "space-between",
		alignItems: "center",
		marginBottom: 10,
		flexGrow: 1
	},
	assessmentContainer: {
		paddingHorizontal: 24,
		marginTop: 24,
	},
	assessmentTitle: {
		fontSize: 20,
	},
	questionnaireList: {
		marginTop: 8,
	},
	questionnaireTitle: {
		color: upittTheme.darkColors?.primary,
	},
	completionStatus: {
		marginBottom: 6,
	},
	wrapperCustom: {
		borderRadius: 8,
		padding: 6,
	},
})
