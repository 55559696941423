import { StyleSheet } from "react-native"

export const styles = StyleSheet.create({
	container: {
		flex: 1,
		// justifyContent: "center",
		// alignItems: "center"
	},
	imageStylingMobile: {
		flex: 1,
		justifyContent: "center"
	},
	imageOpacity: {
		opacity: 0.5
	},
	imageStylingWeb: {
		flex: 1,
		justifyContent: "center",
		height: "100%",
		width: "100%",
	}
})
