import AsyncStorage from "@react-native-async-storage/async-storage"
import { configureStore, ThunkAction, Action, combineReducers } from "@reduxjs/toolkit"
import type { PreloadedState } from "@reduxjs/toolkit"
import {
	persistStore,
	persistReducer,
	FLUSH,
	REHYDRATE,
	PAUSE,
	PERSIST,
	PURGE,
	REGISTER,
} from "redux-persist"

import assessmentReducer from "./assessmentState"
import questionaireReducer from "./questionaireState"
import authReducer from "./authState"
import diseaseReducer from "./diseaseState"
import answerReducer from "./answerState"
import documentReducer from "./documentState"
import medicalProfileReducer from "./medicalProfileState"
import { Platform } from "react-native"
import createSecureStorage from "../secureStore/index"


const storage = Platform.OS == "web" ? AsyncStorage : createSecureStorage()

export const rootReducer = combineReducers({
	assessmentState: persistReducer({key: "assessmentState", storage}, assessmentReducer),
	diseaseState: persistReducer({key: "diseaseState", storage}, diseaseReducer),
	documentState: persistReducer({key: "documentState", storage}, documentReducer),
	questionaireState: persistReducer({key: "questionaireState", storage}, questionaireReducer),
	answerState: persistReducer({key: "answerState", storage}, answerReducer),
	authState: persistReducer({key: "authState", storage}, authReducer),
	medicalProfileState: persistReducer({key: "medicalProfileState", storage}, medicalProfileReducer)
})

export const makeStore = (preloadedState?: PreloadedState<RootState>) => {
	return configureStore({
		reducer: rootReducer,
		preloadedState,
		middleware: (getDefaultMiddleware) =>
			getDefaultMiddleware({
				serializableCheck: {
					ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
				},
			}),
	})
}

const store = makeStore()

export const appStorePersistor = persistStore(store)

export type RootState = ReturnType<typeof rootReducer>
export type AppStore = ReturnType<typeof makeStore>
export type AppState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  AppState,
  unknown,
  Action<string>
>
export type Store = typeof store
export default store

