import * as Localization from "expo-localization"
import i18n from "i18next"
import { initReactI18next } from "react-i18next"
import { LANGUAGES } from "./constants/IMLocalize";

//empty for now
// const resources = LANGUAGES

// const resources = {
//     en: {
//         translation: {
//             "hello": "Welcome to React and react-i18next"
//         }
//     }
//   }

i18n.use(initReactI18next).init({
    resources: LANGUAGES,
    debug: true,
    lng: Localization.getLocales()[0]?.languageCode || "en",
    cleanCode: true,
    //language to use if translations in user language are not available
    fallbackLng: "en",
    interpolation: {
        escapeValue: false, // not needed for react!!
    },
    returnObjects: true
})

export default i18n;