import { useNavigation } from "@react-navigation/native"
import { CheckBox, Text } from "@rneui/themed"
import { Feather } from "@expo/vector-icons"
import { useEffect } from "react"
import { View } from "react-native"
import { LoginScreenNavigationProp } from "../../../@types/navigation/AuthNavigator"
import { getDiseases, setSelectedDisease } from "../../redux/diseaseState"
import { AppState } from "../../redux/store"
import { useAppDispatch, useAppSelector } from "../../redux/hooks"
import { styles } from "./styles"
import { useTheme } from "@rneui/themed"
import { BasePage } from "../../components/BasePage/BasePage"



export const SettingsScreen = () => {
	const { theme } = useTheme()

	const navigation = useNavigation<LoginScreenNavigationProp>()
	const dispatch = useAppDispatch()
	const diseaseState = useAppSelector((state: AppState) => state.diseaseState)

	useEffect(() => {
		dispatch(getDiseases())
	}, [])

	return (
		<BasePage>
			<Text style={{textAlign: "center", marginTop: 10, marginBottom: 18, fontSize: 16, color: "#575857"}}>If you diagnosis changes after you join the study, please change it here accordingly:</Text>
			{
				diseaseState.available.map(disease => {
					return (
						<CheckBox key={disease.id}
							title={disease.name}
							checked={disease.id == diseaseState.selected?.id}
							checkedIcon={
								<Feather name='check-circle' size={25} color='green' />
							}
							uncheckedIcon={
								<Feather
									name="circle"
									color="grey"
									size={25}
								/>
							}
							onPress={() => dispatch(setSelectedDisease(disease))}
						/>
					)
				})
			}
		</BasePage>
	)
}
