import { useNavigation } from "@react-navigation/native"
import { AppState } from "../../../redux/store"
import { QuestionaireComponent } from "../../../components/Questionare/Questionaire"
import { QuestionaireResult } from "../../../components/QuestionaireResult/QuestionaireResult"
import { useAppDispatch, useAppSelector } from "../../../redux/hooks"
import { useState } from "react"
import { EligibilityScreenNavigationProp } from "../../../../@types/navigation/AuthNavigator"
import { validateQuestionaire } from "../../../redux/questionaireState"
import {
	checkAllQuestionsHaveAnswers,
	alertForUnansweredQuestions,
	submitEligibilityAnswer,
} from "../../util"
import { LoadingSpinner } from "../../../components/Loading/LoadingSpinner"
import { ELIGIBILITY_QUESTIONAIRE_INDEX } from "../../../../constants/general"
import { filterObject, removeFalseyValues } from "../../../../util"
import { SubmitEligibilityAnswerProps } from "../../../../@types/screens/util"
import { showToast } from "../../../../toast"

export const EligibilityScreen = () => {
	const navigation = useNavigation<EligibilityScreenNavigationProp>()

	const dispatch = useAppDispatch()

	const questionaireState = useAppSelector(
		(state: AppState) => state.questionaireState
	)
	const answerState = useAppSelector((state: AppState) => state.answerState)

	const [showUserNotEligible, setShowUserNotEligible] = useState(false)
	const [unansweredQuestions, setUnansweredQuestions] = useState<string[]>([])

	const handleEligibilitySubmit = async () => {
		if (questionaireState.eligibility) {
			const questionaire = questionaireState.eligibility

			const { allAnswered, unansweredQuestionPaths } = checkAllQuestionsHaveAnswers({
				questionaire,
				answerState,
				questionaireIndex: ELIGIBILITY_QUESTIONAIRE_INDEX,
			})
			setUnansweredQuestions(unansweredQuestionPaths)

			if (!allAnswered) {
				alertForUnansweredQuestions()
				return
			}
			await dispatch(
				validateQuestionaire({
					questionaireId: questionaire.id,
					questionaireIndex: ELIGIBILITY_QUESTIONAIRE_INDEX,
				})
			)
			const answers =
        answerState.questionaireAnswerState[ELIGIBILITY_QUESTIONAIRE_INDEX]

			const answerSubmissions = answers?.map(async (answer, i) => {
				const filteredAnswer = filterObject(answer, removeFalseyValues)

				const data: SubmitEligibilityAnswerProps = {
					answer: filteredAnswer,
					parentAnswerId: filteredAnswer.parent_answer,
					questionPath: `[${i}]`,
					questionaireIndex: ELIGIBILITY_QUESTIONAIRE_INDEX,
					questionState: questionaire.questions,
				}
				return await submitEligibilityAnswer(data)
			})
			const results = await Promise.allSettled(answerSubmissions)
			const wasSubmittedSuccessfully = results.every(
				(result) => result.status === "fulfilled"
			)
			if (wasSubmittedSuccessfully) {
				showToast({
					message: "Questionnaire successfuly submitted!",
					kind: "success",
				})
			}

			switch (questionaireState.selected?.isValid) {
			case false:
			case undefined:
				setShowUserNotEligible(true)
				return

			case true:
				setShowUserNotEligible(false)
				break

			default:
				return
			}
		}
	}

	const removeUnansweredQuestion = (answeredQuestionPath: string) => {
		setUnansweredQuestions(prevQuestions => prevQuestions.filter(questionPath => questionPath !== answeredQuestionPath))
	}

	const handleQuestionaireResult = () => {
		if (questionaireState.selected) {
			questionaireState.selected?.isValid
				? navigation.navigate("ConsentSummary")
				: navigation.navigate("DiseaseIntroduction")
		}
	}

	if (showUserNotEligible) {
		return (
			<>
				<QuestionaireResult
					onPressCallback={handleQuestionaireResult}
					isValid={questionaireState.selected?.isValid || false}
					whyFailed={questionaireState.selected?.whyFailed}
					shouldShowSelectDisease={questionaireState.selected?.isValid ? true : false}
				/>
			</>
		)
	}

	return (
		<>
			<LoadingSpinner
				isLoading={questionaireState.isLoading}
				message="Loading..."
			/>
			{questionaireState.eligibility ? (
				<QuestionaireComponent
					questionaire={questionaireState.eligibility}
					onSubmitCallback={handleEligibilitySubmit}
					isDirty={false}
					questionaireIndex={ELIGIBILITY_QUESTIONAIRE_INDEX}
					assessmentIndex={undefined}
					unansweredQuestions={unansweredQuestions}
					removeUnansweredQuestion={removeUnansweredQuestion}
				/>
			) : null}
		</>
	)
}
