import { useNavigation } from "@react-navigation/native"
import { View } from "react-native"
import { useAppDispatch, useAppSelector } from "../../../redux/hooks"
import { AppState } from "../../../redux/store"
import { RegistrationScreenNavigationProp } from "../../../../@types/navigation/AuthNavigator"
import { QuestionaireComponent } from "../../../components/Questionare/Questionaire"
import { alertForUnansweredQuestions, checkAllQuestionsHaveAnswers } from "../../util"
import { LoadingSpinner } from "../../../components/Loading/LoadingSpinner"
import { resetErrors as clearQuestionaireErrors } from "../../../redux/questionaireState"
import { REGISTRATION_QUESTIONAIRE_INDEX } from "../../../../constants/general"
import { useEffect } from "react"


export const RegistrationScreen = () => {

	const navigation = useNavigation<RegistrationScreenNavigationProp>()
	const dispatch = useAppDispatch()

	const questionaireState = useAppSelector((state: AppState) => state.questionaireState)
	const answerState = useAppSelector((state: AppState) => state.answerState)

	useEffect(() => {
		if (!questionaireState.registration) {
			navigation.navigate("SignUp")
		}
	}, [])

	const handleRegistrationSubmit = async () => {

		if (questionaireState.registration) {
			const questionaire = questionaireState.registration
			const isFilledOut = checkAllQuestionsHaveAnswers({ questionaire, answerState, questionaireIndex: REGISTRATION_QUESTIONAIRE_INDEX })

			isFilledOut ? navigation.navigate("AuthorizeMedicalRelease") : alertForUnansweredQuestions()
		}
	}

	const handleDismissErrors = () => {
		dispatch(clearQuestionaireErrors())
	}

	return (
		<View style={{flex: 1}}>
			<LoadingSpinner message="Loading..." isLoading={questionaireState.isLoading}/>
			{
				questionaireState.registration ?
					<QuestionaireComponent
						questionaire={questionaireState.registration}
						onSubmitCallback={handleRegistrationSubmit}
						isDirty={false}
						questionaireIndex={REGISTRATION_QUESTIONAIRE_INDEX}
						assessmentIndex={undefined}/>
					: null
			}
		</View>	
	)
}
